import {createContext, Dispatch, SetStateAction} from 'react';

import {IWallet} from '../../types/api/Wallet';
import {IReward} from '../../types/api/Reward';

export const UserContext = createContext<{
    userId: string,
    setUserId: Dispatch<SetStateAction<string>>,
    saveUserId: (id: string) => Promise<void>
    currentAvatar: string,
    setCurrentAvatar: Dispatch<SetStateAction<string>>,
    currentExperience: number,
    setCurrentExperience: Dispatch<SetStateAction<number>>,
    maxExperience: number,
    setMaxExperience: Dispatch<SetStateAction<number>>,
    currentLevel: number,
    setCurrentLevel: Dispatch<SetStateAction<number>>,
    currentUsername: string,
    setCurrentUsername: Dispatch<SetStateAction<string>>,
    currentEmail: string,
    setCurrentEmail: Dispatch<SetStateAction<string>>,
    levelRewards: IReward[],
    setLevelRewards: Dispatch<SetStateAction<IReward[]>>,
    levelRewardsVisible: boolean,
    setLevelRewardsVisible: Dispatch<SetStateAction<boolean>>,
    getLevelRewards: () => Promise<void>,
    emailVerified: boolean,
    setEmailVerified: Dispatch<SetStateAction<boolean>>,
    userDataLoaded: boolean,
    setUserDataLoaded: Dispatch<SetStateAction<boolean>>,
    acceptCookies: boolean,
    setAcceptCookies: Dispatch<SetStateAction<boolean>>,
    isAdmin: boolean,
    setIsAdmin: Dispatch<SetStateAction<boolean>>,
    refreshOpenlootConnection: boolean,
    setRefreshOpenlootConnection: Dispatch<SetStateAction<boolean>>,
    userWallets: IWallet[] | [],
    setUserWallets: Dispatch<SetStateAction<IWallet[] | []>>,
    userFirstWallet: IWallet | null,
    setUserFirstWallet: Dispatch<SetStateAction<IWallet | null>>,
    getUserInfo: () => Promise<void>,
    passwordResetRequired: boolean,
    setPasswordResetRequired: Dispatch<SetStateAction<boolean>>,
    updatePasswordModalVisible: boolean,
    setUpdatePasswordModalVisible: Dispatch<SetStateAction<boolean>>,
    userInfoModalVisible: boolean,
    setUserInfoModalVisible: Dispatch<SetStateAction<boolean>>,
    selectedModalUserId: string,
    setSelectedModalUserId: Dispatch<SetStateAction<string>>,
    twoFAEnabled: boolean,
    setTwoFAEnabled: Dispatch<SetStateAction<boolean>>,
      }>({
        userId: '',
        setUserId: () => {
        },
        saveUserId: async () => {
        },
        currentAvatar: '',
        setCurrentAvatar: () => {
        },
        currentExperience: 0,
        setCurrentExperience: () => {
        },
        maxExperience: 0,
        setMaxExperience: () => {
        },
        currentLevel: 0,
        setCurrentLevel: () => {
        },
        currentUsername: '',
        setCurrentUsername: () => {
        },
        currentEmail: '',
        setCurrentEmail: () => {
        },
        levelRewards: [],
        setLevelRewards: () => {
        },
        levelRewardsVisible: false,
        setLevelRewardsVisible: () => {
        },
        getLevelRewards: async () => {
        },
        emailVerified: false,
        setEmailVerified: () => {
        },
        userDataLoaded: false,
        setUserDataLoaded: () => {
        },
        acceptCookies: false,
        setAcceptCookies: () => {
        },
        isAdmin: false,
        setIsAdmin: () => {
        },
        refreshOpenlootConnection: false,
        setRefreshOpenlootConnection: () => {
        },
        userWallets: [],
        setUserWallets: () => {
        },
        userFirstWallet: {} as IWallet,
        setUserFirstWallet: () => {
        },
        getUserInfo: async () => {
        },
        passwordResetRequired: false,
        setPasswordResetRequired: () => {
        },
        updatePasswordModalVisible: false,
        setUpdatePasswordModalVisible: () => {
        },
        userInfoModalVisible: false,
        setUserInfoModalVisible: () => {
        },
        selectedModalUserId: '',
        setSelectedModalUserId: () => {
        },
        twoFAEnabled: false,
        setTwoFAEnabled: () => {
        },
      });