import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import Feature from '../../../types/api/Admin/Feature/Feature';

export async function getFeatures(): Promise<AxiosResponse<Feature>> {
  return await makeAdminRequest('GET', '/api/admin/game/features');
}

export async function setFeatures(features: Feature) {
  return await makeAdminRequest('POST', '/api/admin/game/features', features);
}

export async function deleteFeature(name: string) {
  return makeAdminRequest('DELETE', '/api/admin/game/features', {name});
}
