import axios, {AxiosError, AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';
import error from '../../errors';

import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import CharacterAttribute, {
  CharacterAttributeValues, EditCharacterAttributeState
} from '../../../types/api/Admin/CharacterAttribute/CharacterAttribute';
import MessageResponse from '../../../types/api/MessageResponse';
import IDropdownResultsByName from '../../../types/api/Admin/ReferralRewards/DropdownResults';

import config from '../../config';
import {AUTH_SESSION_NAME} from '../../../config';

export async function getCharacterAttributes(
  page: number = 1, perPage: number = 20, {name, type}: CharacterAttributeValues
): Promise<AxiosResponse<PaginatedResponse<CharacterAttribute>>> {
  return makeAdminRequest('POST', '/api/admin/game/character-attributes/filter', {
    page, perPage, name, type: type?.id
  })
}

export async function getCharacterTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/character-attributes/types');
}

export async function deleteAttribute(attributeId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/character-attributes/${attributeId}`);
}

export async function setIcon(attributeId: string, formData: FormData) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/game/character-attributes/${attributeId}/icon`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function createCharacterAttribute({
  type, balanceParameter, name, description,
  paramA, paramADecimals, paramB, paramBDecimals,
  paramC, paramCDecimals, precision,
}: EditCharacterAttributeState
): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/character-attributes', {
    type: type.value, balanceParameterId: balanceParameter?.value,
    name, description, paramA, paramADecimals, paramB,
    paramBDecimals, paramC, paramCDecimals, precision
  });
}

export async function updateCharacterAttribute(attributeId: string, {
  type, balanceParameter, name, description,
  paramA, paramADecimals, paramB, paramBDecimals,
  paramC, paramCDecimals, precision,
}: EditCharacterAttributeState
): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/game/character-attributes/${attributeId}`, {
    type: type.value, balanceParameterId: balanceParameter?.value,
    name, description, paramA, paramADecimals, paramB,
    paramBDecimals, paramC, paramCDecimals, precision
  });
}

export async function searchCharacterAttributesByName(name: string): Promise<AxiosResponse<IDropdownResultsByName[]>> {
  return await makeAdminRequest('GET', `/api/admin/game/character-attributes/search?name=${name}`);
}