import {ReactNode, useEffect, useState} from 'react';

import {UnsavedChangesContext} from './UnsavedChangesContext';

type UnsavedChangesContextProps = {
    children: ReactNode
}

export const UnsavedChangesProvider = ({children}: UnsavedChangesContextProps) => {
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDataChanged) {
        const message = 'У вас есть несохранённые изменения. Вы уверены, что хотите покинуть страницу?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [isDataChanged]);

  // useEffect(() => {
  //   const handleGlobalClick = (event: any) => {
  //     if (event.target.tagName === 'A' || event.target.closest('a')) {
  //       if (isDataChanged) {
  //         const confirmation = window.confirm('У вас есть несохранённые изменения. Вы уверены, что хотите покинуть страницу?');
  //         if (!confirmation) {
  //           event.preventDefault();
  //         }
  //       }
  //     }
  //   };
  //
  //   document.addEventListener('click', handleGlobalClick);
  //   return () => document.removeEventListener('click', handleGlobalClick);
  // }, [isDataChanged]);

  return (
    <UnsavedChangesContext.Provider value={{isDataChanged, setIsDataChanged,}}>
      {children}
    </UnsavedChangesContext.Provider>
  );
};