import React, {useEffect, useState} from 'react';

import Timer from './Timer';

import API from '../../../../../api';

import {Wrapper} from './styles';

let requestIntervalId: NodeJS.Timeout;
const REFRESH_TIMEOUT_VALUE = 60 * 1000; // 60 sec

const TimeLeft = () => {
  const [gameSessionsEnabled, setGameSessionsEnabled] = useState<boolean>(false);
  const [nextPlaytime, setNextPlaytime] = useState<number>();

  const sessionsAvailability = async () => {
    const response = await API.inventories.sessionsAvailability().then(res => res.data);
    if (!response) {
      return;
    }

    setGameSessionsEnabled(response.gameSessionsEnabled);
    setNextPlaytime(response.nextPlaytime);
  };

  useEffect(() => {
    sessionsAvailability().catch(console.error);
    requestIntervalId = setInterval(sessionsAvailability, REFRESH_TIMEOUT_VALUE);

    return () => {
      clearInterval(requestIntervalId);
    };
  }, []);

  return (
    <Wrapper className="text-light text-align-center">
      <Timer gameSessionsEnabled={gameSessionsEnabled} nextPlaytime={nextPlaytime}/>
    </Wrapper>
  );
};

export default TimeLeft;