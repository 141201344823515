import {useContext} from 'react';
import useAutoLoginToken from '../useAutoLoginToken/useAutoLoginToken';

import CONTEXT from '../../context';

import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';
import * as localStorage from '../../localStorage';

const ACTIONS = {
  CONNECT_OPEN_LOOT_1: 'connect-openloot',
  CONNECT_OPEN_LOOT_2: 'connect-open-loot',
};

const useInitialLoad = () => {
  let hashUrl = window.location.hash;
  if (!hashUrl) {
    window.history.replaceState(null, '', `/#${window.location.pathname}`);
    hashUrl = `#${window.location.pathname}`;
  }

  const {setCurrentAvatar, setUserId} = useContext(CONTEXT.GLOBAL.USER);

  const DEFAULT_REDIRECT_EXCEPTIONS = ['#/', '#/login', '#/register', '#/forgot-password', '#/tournament-leaderboards', '#/twitch-linking-failed', '#/twitch-linking-succeeded'];
  const SPECIAL_REDIRECT_EXCEPTIONS = ['#/update-email', '#/verify-email', '#/regenerate-password-request'];
  const PAGES_FOR_TRIGGER_ID = ['#/login', '#/dashboard'];

  const specialUrl = `#/${hashUrl.split('#/')[1].split('/')[0]}`;

  const initialLoad = async () => {
    if (!hashUrl) {
      return;
    }
    const convertedUrl = hashUrl.split('?')[0];
    let autoLoginToken;
    let triggerId;
    let invite;
    let link;
    let action;

    if (!hashUrl.includes('?') && !DEFAULT_REDIRECT_EXCEPTIONS.includes(hashUrl)) {
      if (!SPECIAL_REDIRECT_EXCEPTIONS.includes(specialUrl)) {
        localStorage.setItem(LOCAL_STORAGE_NAMES.REDIRECT_URL, hashUrl);
      }
    }

    if (hashUrl.includes('?')) {
      if (!DEFAULT_REDIRECT_EXCEPTIONS.includes(convertedUrl)) {
        if (!SPECIAL_REDIRECT_EXCEPTIONS.includes(specialUrl)) {
          localStorage.setItem(LOCAL_STORAGE_NAMES.REDIRECT_URL, convertedUrl);
        }
      }

      let queryParams = hashUrl.split('?')[1];
      if (!queryParams) {
        return;
      }

      const params = queryParams.split('&');
      for (const p of params) {
        const [key, value] = p.split('=');
        if (key === 'triggerId') {
          triggerId = value;
          continue;
        }
        if (key === 'autoLoginToken') {
          autoLoginToken = value;
          continue;
        }
        if (key === 'invite') {
          invite = value;
          continue;
        }
        if (key === 'link') {
          link = value;
          continue;
        }
        if (key === 'action') {
          action = value;
        }
      }
    }

    if (autoLoginToken) {
      const autoLogin = useAutoLoginToken();
      await autoLogin(setUserId, setCurrentAvatar, autoLoginToken, convertedUrl);
    }

    if (triggerId && PAGES_FOR_TRIGGER_ID.includes(convertedUrl)) {
      localStorage.setItem(LOCAL_STORAGE_NAMES.TRIGGER_ID, triggerId);
    }

    if (invite) {
      localStorage.setItem(LOCAL_STORAGE_NAMES.INVITE, invite);
    }

    if (link) {
      window.addToast('success', '', `${link === 'open-loot' ? 'Open Loot' : link.charAt(0).toUpperCase() + link.slice(1)} account connection was successful`, 2000, '#1FBF42');
    }

    if (action) {
      switch (action) {
      case ACTIONS.CONNECT_OPEN_LOOT_1:
      case ACTIONS.CONNECT_OPEN_LOOT_2:
        localStorage.setItem(LOCAL_STORAGE_NAMES.CONNECT_OPENLOOT, true);
        break;
      default:
        return;
      }
    }
  };

  return initialLoad;
};

export default useInitialLoad;