import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';

import {
  requireAdmin,
  requireAuth,
  requireSupportOrAdmin,
  requireAuthUpdateEmailStep1,
  requireAuthUpdateEmailStep2
} from '../routes/routes';

import Loader from './common/Loader/Loader';
import RequireAuth from '../routes/RequireAuth';
import RequireRole from '../routes/RequireRole';
import RequireAuthWithQueryParameter from '../routes/RequireAuthWithQueryParameter';

import {ROLE_NAMES} from '../api/constants';

const AuthAppContent = () => {
  const loader = (
    <div className="loader-container">
      <Loader size={64} borderWidth={6}/>
    </div>
  );

  return (
    <>
      <Suspense fallback={loader}>
        <Routes>
          {requireAuth.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <RequireAuth>
                      <route.element/>
                    </RequireAuth>
                  }
                />
              ),
          )}
          {requireAuthUpdateEmailStep1.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <RequireAuthWithQueryParameter path={'/login?update-email-first-step=true'}>
                      <route.element/>
                    </RequireAuthWithQueryParameter>
                  }
                />
              ),
          )}
          {requireAuthUpdateEmailStep2.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <RequireAuthWithQueryParameter path={'/login?update-email-second-step=true'}>
                      <route.element/>
                    </RequireAuthWithQueryParameter>
                  }
                />
              ),
          )}
          {requireSupportOrAdmin.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <RequireAuth>
                      <RequireRole
                        rolesRequired={[ROLE_NAMES.SUPPORT, ROLE_NAMES.ADMIN]}
                      >
                        <route.element/>
                      </RequireRole>
                    </RequireAuth>
                  }
                />
              ),
          )}
          {requireAdmin.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <RequireAuth>
                      <RequireRole rolesRequired={ROLE_NAMES.ADMIN}>
                        <route.element/>
                      </RequireRole>
                    </RequireAuth>
                  }
                />
              ),
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default React.memo(AuthAppContent);
