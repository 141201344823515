import React, {useContext, useEffect, useState} from 'react';

import CONTEXT from '../../../../../context';

import Field from '../../../ModalUtils/Field/Field';
import TextOrNumberInput from '../../../ModalUtils/TextOrNumberInput/TextOrNumberInput';

import Loader from '../../../../../components/common/Loader/ContainerLoader'
import {Button} from '../../../../common/Buttons/Button';

import API from '../../../../../api';
import ITwoFA from '../../../../../types/api/Admin/Users/TwoFA';
import {BadRequest} from '../../../../../api/errors/errors';

import {ModalWrapper, ModalTitle} from '../../../ModalUtils/styles';

interface I2FAModal {
    onClose: () => void;
}

const TwoFA = ({onClose}: I2FAModal) => {
  const {check2FASession} = useContext(CONTEXT.GLOBAL.TWO_F_A);
  const {twoFAEnabled, getUserInfo} = useContext(CONTEXT.GLOBAL.USER);

  const [binding2FAResponse, setBinding2FAResponse] = useState<ITwoFA | null>(null);
  const [auth2FAToken, setAuth2FAToken] = useState<number | null>(null);
  const [codeExpired, setCodeExpired] = useState<boolean>(false);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [waitingResponse, setWaitingResponse] = useState<boolean>(true);

  const startBinding2FA = async () => {
    setWaitingResponse(false);
    try {
      const response = await API.admin.users.startBinding2FA();
      if (response) {
        setBinding2FAResponse(response.data);
        setCodeExpired(false);
        setLoaded(true);
      }
    } catch (e: any) {
      if (e instanceof BadRequest) {
        return;
      }
      window.addToast('danger', 'Error', `${e ? e.message : 'Something went wrong'}`, 1500);
    } finally {
      setWaitingResponse(true);
    }
  }

  const confirmBinding2FA = async () => {
    setWaitingResponse(false);
    try {
      await API.admin.users.confirmBinding2FA(String(auth2FAToken) || '', binding2FAResponse?.bindingToken || '');
      await getUserInfo();
      onClose();
    } catch (e: any) {
      if (e instanceof BadRequest) {
        return;
      }
      window.addToast('danger', 'Error', `${e ? e.message : 'Something went wrong'}`, 1500);
    } finally {
      setWaitingResponse(true);
    }
  }

  const confirm2FASession = async () => {
    setWaitingResponse(false);
    try {
      await check2FASession(String(auth2FAToken) || '');
      await getUserInfo();
      onClose();
    } catch (e: any) {
      if (e instanceof BadRequest) {
        return;
      }
      window.addToast('danger', 'Error', `${e ? e.message : 'Something went wrong'}`, 1500);
    } finally {
      setWaitingResponse(true);
    }
  }

  useEffect(() => {
    if (twoFAEnabled) {
      setLoaded(true);
    } else {
      startBinding2FA().catch(console.error);
    }
  }, [twoFAEnabled]);

  useEffect(() => {
    if (binding2FAResponse) {
      const expiresAtTime = binding2FAResponse?.expiresAt || 0;

      if (expiresAtTime > Date.now()) {
        const intervalId = setInterval(() => {
          const currentTime = Date.now();
          const remainingTime = expiresAtTime - currentTime;

          if (remainingTime <= 0) {
            clearInterval(intervalId);
            setCodeExpired(true);
            setAuth2FAToken(null);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      } else {
        setCodeExpired(true);
        setAuth2FAToken(null);
      }
    }
  }, [binding2FAResponse]);


  return (
    <ModalWrapper>
      <ModalTitle className="title-bold">2FA</ModalTitle>
      <div style={{display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center', justifyContent: 'center'}}>
        {loaded ? <>
          {twoFAEnabled ? (
            <>
              <Field title="Enter token:" style={{margin: '8px 0'}}>
                <TextOrNumberInput
                  value={auth2FAToken ?? undefined}
                  onChange={(event) => setAuth2FAToken(event.target.value)}
                  type='number'
                  placeholder="Enter token ..."
                />
              </Field>
              <Button
                buttonText="Confirm"
                onClick={confirm2FASession}
                activeLoader={!waitingResponse}
                isDisabledButton={!auth2FAToken}
              />
            </>
          ) : (
            <>
              {codeExpired ? (
                <>
                  <div>QR Code expired, please try again</div>
                  <Button
                    buttonText="Refresh"
                    onClick={startBinding2FA}
                    activeLoader={!waitingResponse}
                  />
                </>
              ) : (
                <>
                  <div>
                    {binding2FAResponse?.qrCode && <img src={binding2FAResponse?.qrCode} alt="QR Code"/>}
                  </div>
                  <Field title="Enter token:" style={{margin: '8px 0'}}>
                    <TextOrNumberInput
                      value={auth2FAToken ?? undefined}
                      onChange={(event) => setAuth2FAToken(event.target.value)}
                      type='number'
                      placeholder="Enter token ..."
                    />
                  </Field>
                  <Button
                    buttonText="Bind"
                    onClick={confirmBinding2FA}
                    activeLoader={!waitingResponse}
                    isDisabledButton={!auth2FAToken}
                  />
                </>
              )}
            </>
          )}
        </> : <Loader/>}
      </div>
    </ModalWrapper>
  );
};

export default TwoFA;
