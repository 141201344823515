export interface IRewardType {
    id: REWARD_TYPES;
    name: string;
}

export enum REWARD_TYPES {
    INVENTORY,
    AVATAR,
    DISCORD_ROLE,
    FAKE
}