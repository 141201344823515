import {useEffect} from 'react';

export const useOutsideClick = (elementRef: any, handler: () => void, attached: boolean) => {
  useEffect(() => {
    if (!attached) {
      return;
    }

    const handleClick = (event: any) => {
      event.preventDefault();

      if (!elementRef.current) {
        return;
      }
      if (!elementRef.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [elementRef, handler, attached]);
};

