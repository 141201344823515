import {initializeApp} from 'firebase/app';
import {deleteToken, getMessaging, getToken, onMessage} from 'firebase/messaging';

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from '../../config';
import firebase from 'firebase/compat';
import MessagePayload = firebase.messaging.MessagePayload;

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

let permissionGranted = false;

export const requestFCMToken = async (onMessageFunc: (message: MessagePayload) => void): Promise<string> => {
  const token = await getToken(messaging);
  if (token) {
    permissionGranted = true;
    onMessage(messaging, onMessageFunc);
    return token;
  }
  console.log('No registration token available. Request permission to generate one.');
  throw new Error('No registration token available. Request permission to generate one.');
};

export const deleteFCMToken = async () => {
  if (permissionGranted) {
    await deleteToken(messaging);
  }
};