import styled, {css, keyframes} from 'styled-components';

import {RewardStatus} from '../../../../../../types/api/Reward';

const RewardContainerStyles = (type?: RewardStatus) => {
  switch (type) {
  case 'READY_TO_CLAIM':
    return css`
              box-shadow: 0 -1px 16px 4px #FFFFFF;
              cursor: pointer;
            `;
  default:
    return css``;
  }
};

const RewardImageStyles = (type: RewardStatus) => {
  switch (type) {
  case 'UNREACHED':
    return css`
              width: 20px;
              height: 28.84px;

              @media (max-width: 950px) {
                width: 16px;
                height: 22px;
              }
            `;
  case 'CLAIMED':
    return css`
              width: 20px;
              height: 17.5px;

              @media (max-width: 950px) {
                width: 16px;
                height: 14px;
              }
            `;
  }
};

const RewardPathStyles = (rewardIndex: number, styleRowIndex: number, count: number) => {
  switch (styleRowIndex) {
  case 1:
    if (rewardIndex % (count - 1) === 0 && rewardIndex !== 0) {
      return css`
                  bottom: -52px;
                  left: 50%;

                  transform: translate(-50%, -50%) rotate(90deg);

                  @media (max-width: 950px) {
                    bottom: -49px;
                  }
                `;
    } else {
      return css`
                  right: -62px;
                  top: 50%;
                  transform: translate(-50%, -50%);

                  @media (max-width: 950px) {
                    right: -49px;
                  };
                `;
    }
  case 2:
    if (rewardIndex % (count - 1) === 0 && rewardIndex === 0) {
      return css`
                  bottom: -52px;
                  left: 50%;
                  transform: translate(-50%, -50%) rotate(90deg);

                  @media (max-width: 950px) {
                    bottom: -49px;
                  };
                `;
    } else {
      return css`
                  left: -21px;
                  top: 50%;
                  transform: translate(-50%, -50%) rotate(180deg);;

                  @media (max-width: 950px) {
                    left: -17px;
                  }
                `;
    }
  }
};

export const RewardContainer = styled.div<{
    count: number,
    background?: string,
    type?: RewardStatus,
    isAvailable?: boolean
}>`
  position: relative;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({count}) => `calc((100% - (${count - 1}) * 36px) / ${count})`};
  height: ${({count}) => `calc((100% - (${count - 1}) * 36px) / ${count})`};

  background: url("${(props) => props.background}") no-repeat center;

  border: ${({isAvailable}) => isAvailable ? '4px solid #FFA500' : '4px solid #D9D9D9'};
  border-radius: 4px;

  transition: all 400ms ease-in-out;

  ${({type}) => RewardContainerStyles(type)};

  @media (max-width: 950px) {
    width: ${({count}) => `calc((100% - (${count - 1}) * 25px) / ${count})`};
    height: ${({count}) => `calc((100% - (${count - 1}) * 25px) / ${count})`};
  };
`;

const backgroundAnimation = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const BackgroundAnimation = styled.div<{ active: boolean }>`
  display: block;

  width: 100%;
  height: 100%;

  overflow: hidden;

  opacity: ${props => props.active ? 0 : 1};

  animation: ${props => props.active ? backgroundAnimation : ''} 1s ease-in-out forwards;
`;

export const RewardBackground = styled.img<{ scale: number }>`
  display: block;

  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  object-position: top;

  border-radius: 4px;

  transform: scale(${({scale}) => scale});
`;

export const RewardLevel = styled.div`
  position: absolute;
  top: 4px;
  left: 2px;

  opacity: 0.5;
`;

export const RewardImage = styled.img<{ type: RewardStatus }>`
  position: absolute;
  left: 10px;
  bottom: 10px;

  transition: all 400ms ease-in-out;

  ${({type}) => RewardImageStyles(type)};

  @media (max-width: 950px) {
    left: 5px;
    bottom: 5px;
  };
`;

export const RewardButton = styled.div`
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);

  display: flex;
  align-items: center;

  background: #FFA500;

  border: 0.8px solid #FFFFFF;
  border-radius: 4px;

  padding: 4px 16px;
  outline: 0;

  transition: all 400ms ease-in-out;

  @media (max-width: 950px) {
    padding: 4px 8px;
  }
`;

export const RewardProgress = styled.progress<{ rewardIndex: number, styleRowIndex: number, count: number }>`
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
  appearance: none;

  width: 41px;
  height: 20%;

  @media (max-width: 950px) {
    width: 33px;
    height: 33px;
  }

  &::-webkit-progress-bar {
    background-color: #D9D9D9;
  }

  &::-moz-progress-bar {
    background-color: #D9D9D9;
  }

  &::-webkit-progress-value {
    background-color: #FFA500;
  }

  ${({rewardIndex, styleRowIndex, count}) => RewardPathStyles(rewardIndex, styleRowIndex, count)}
`;

export const DefaultRewardsContent = styled(RewardContainer)<{ visible?: boolean }>`
  padding-bottom: ${({count}) => `calc((100% - (${count - 1}) * 26px) / ${count})`};

  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  opacity: ${({visible}) => (visible ? 1 : 0)};

  background: #1F2531;
  border: 4px solid #D9D9D9;

  @media (max-width: 950px) {
    padding-bottom: ${({count}) => `calc((100% - (${count - 1}) * 25px) / ${count})`};
  };
`;