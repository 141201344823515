import React, {CSSProperties} from 'react';
import {CPagination, CPaginationItem} from '@coreui/react';

interface IPagination {
    style?: CSSProperties;
    page?: number;
    pageCount?: number;
    getData: (page: number, ...args: any[]) => Promise<void> | void;
    getDataArgs?: any | any[];
    scrollToTop?: boolean;
    isModal?: boolean
}

const Pagination = ({
  getData,
  getDataArgs,
  style = {},
  page = 1,
  pageCount = 1,
  scrollToTop = true,
  isModal = false
}: IPagination) => {
  const refreshList = async (page: number) => {
    let result;
    if (!getDataArgs) {
      result = getData(page);
    } else if (Array.isArray(getDataArgs)) {
      result = getData(page, ...getDataArgs);
    } else {
      result = getData(page, getDataArgs);
    }
    if (result instanceof Promise) {
      result.catch(console.error);
    }
    if (scrollToTop) {
      if (isModal) {
        const modal = document.querySelectorAll('.ReactModal__Overlay')
        if (modal) {
          modal.forEach(item => item.scrollTo({top: 0, behavior: 'smooth'}))
        }
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  };

  return (
    <CPagination style={style}>
      <CPaginationItem
        disabled={page <= 1}
        onClick={() => {
          refreshList(page - 1).catch(console.error);
        }}
      >
                Previous
      </CPaginationItem>

      <CPaginationItem
        hidden={pageCount < 3 || page <= 2}
        onClick={() => {
          refreshList(1).catch(console.error);
        }}
      >
                1
      </CPaginationItem>
      <CPaginationItem
        hidden={pageCount < 3 || page <= 2 || page === 3}
        disabled={true}
      >
                ...
      </CPaginationItem>

      <CPaginationItem
        hidden={page <= 1}
        onClick={() => {
          refreshList(page - 1).catch(console.error);
        }}
      >
        {page - 1}
      </CPaginationItem>
      <CPaginationItem disabled>{page}</CPaginationItem>
      <CPaginationItem
        hidden={page >= pageCount}
        onClick={() => {
          refreshList(page + 1).catch(console.error);
        }}
      >
        {page + 1}
      </CPaginationItem>

      <CPaginationItem
        hidden={pageCount < 3 || page >= pageCount - 2}
        disabled={true}
      >
                ...
      </CPaginationItem>
      <CPaginationItem
        hidden={pageCount < 3 || page > pageCount - 2}
        onClick={() => {
          refreshList(pageCount).catch(console.error);
        }}
      >
        {pageCount}
      </CPaginationItem>

      <CPaginationItem
        disabled={page >= pageCount}
        onClick={() => {
          refreshList(page + 1).catch(console.error);
        }}
      >
                Next
      </CPaginationItem>
    </CPagination>
  );
};

export default Pagination;