import React, {useContext, useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import {Navigate, Route, Routes} from 'react-router-dom';

import {AppHeader, AuthAppContent, PublicAppContent} from '../Unused';
import FloatingButton from '../common/Buttons/FloatingButton/FloatingButton';

import CONTEXT from '../../context';

import useDetectKeyboardOpen from '../../hooks/useDetectKeyboardOpen/useDetectKeyboardOpen';
import useLoadUserId from '../../hooks/useLoadUserId/useLoadUserId';
import {useScreenSizeHook} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

import {IRoute, publicRoutes} from '../../routes/routes';

import {SignedWalletContext} from '../../context/signedWalletContext/signedWalletContext';
// import {
//   initialState,
//   StakingAssetsState,
//   StakingAssetsStatusContext,
// } from '../../context/stakingAssetsStatusContext/stakingAssetsStatusContext';

import {Wrapper} from './styles';
import Modal from '../Modal/Modal';
import TwoFA from '../Modal/Admin/common/TwoFA/TwoFA';

const toastOptions: any = {
  closeOnClick: false,
  closeButton: false,
  autoClose: false,
  hideProgressBar: true,
  draggable: false,
};

const SPECIAL_ROUTES = ['update-email', 'claim-alpha-key'];

const UserLayout = () => {
  const {userId} = useContext(CONTEXT.GLOBAL.USER);
  const {screenType, width = 320} = useScreenSizeHook();
  const loadUserId = useLoadUserId();
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [isSigned, setIsSigned] = useState<boolean>(false);
  // const [
  //   {
  //     refreshStatus,
  //     stakingInProgress,
  //     unstakingInProgress,
  //     claimingInProgress,
  //     actionsDisabled,
  //   },
  //   setStakingFetchStatus,
  // ] = useState<StakingAssetsState>(initialState);

  const [userIdLoaded, setUserIdLoaded] = useState<boolean>(false);
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false);

  const checkVerticalScroll = (): void => {
    const screenHeight = window.innerHeight;
    const pageHeight = document.body.scrollHeight;
    const scrollPercentage = (window.scrollY / pageHeight) * 100;
    const thresholdPercentage = 25;
    const thresholdHeight = screenHeight * 1.25;

    if (screenHeight === null || pageHeight === null) {
      return;
    }

    if (pageHeight > thresholdHeight) {
      if (scrollPercentage >= thresholdPercentage) {
        setActiveAnimation(true);
      } else {
        setActiveAnimation(false);
      }
    }
  };

  useEffect(() => {
    let currentPath = window.location.hash.split('/')[1];
    if (currentPath.includes('?')) {
      currentPath = currentPath.split('?')[0];
    }

    const parsedPublicRoutes = publicRoutes.find((route: IRoute) => {
      if (route.path.split('/')[1].includes(currentPath)) {
        return route;
      }
    });

    if (parsedPublicRoutes || SPECIAL_ROUTES.includes(currentPath)) {
      if (!userId) {
        loadUserId(setUserIdLoaded, true).catch(console.error);
      }
    } else {
      if (!userId) {
        loadUserId(setUserIdLoaded).catch(console.error);
      }
    }

    if (userId) {
      setUserIdLoaded(true);
    }
  }, [window.loggedIn, userId]);

  useEffect(() => {
    checkVerticalScroll();

    window.addEventListener('scroll', checkVerticalScroll);
    return () => window.removeEventListener('scroll', checkVerticalScroll);
  }, [screenType, width]);

  return (
    <>
      <Wrapper className="wrapper d-flex flex-column min-vh-100">
        <ToastContainer {...toastOptions} />
        <SignedWalletContext.Provider value={{isSigned, setIsSigned}}>
          {/*<StakingAssetsStatusContext.Provider*/}
          {/*  value={{*/}
          {/*    refreshStatus,*/}
          {/*    stakingInProgress,*/}
          {/*    unstakingInProgress,*/}
          {/*    claimingInProgress,*/}
          {/*    actionsDisabled,*/}
          {/*    setStakingFetchStatus,*/}
          {/*  }}*/}
          {/*>*/}
          <AppHeader/>
          <div className="layout flex-grow-1">
            <PublicAppContent/>
            {userIdLoaded && <AuthAppContent/>}
            <Routes>
              <Route path="/" element={<Navigate to={`${window.loggedIn ? 'dashboard' : 'login'}`}/>}/>
            </Routes>
            {!isKeyboardOpen && <FloatingButton activeAnimation={activeAnimation}/>}
          </div>
          {/*</StakingAssetsStatusContext.Provider>*/}
        </SignedWalletContext.Provider>
      </Wrapper>

      <Modal
        isOpen={window.twoFAModalVisible}
        onClose={() => window.setTwoFAModalVisible(false)}
      >
        <TwoFA
          onClose={() => window.setTwoFAModalVisible(false)}
        />
      </Modal>
    </>
  );
};
export default UserLayout;
