import {ToastType} from './toastTypes';

import SuccessIcon from '../../../assets/images/icons/success.svg';
import FailIcon from '../../../assets/images/icons/fail.svg';

export const titleText = {
  [ToastType.TransactionInProgress]: 'Transaction in progress',
  [ToastType.TransactionFailed]: 'Transaction failed',
  [ToastType.TransactionCompleted]: 'Transaction completed',
  [ToastType.TransactionConfirmFailed]: 'Transaction confirm failed',
  [ToastType.TransactionConfirmed]: 'Transaction successfully confirmed',
  [ToastType.UndefinedText]: '',
};

export const subtitleText = {
  [ToastType.TransactionInProgress]: 'Waiting for transaction approval...',
  [ToastType.TransactionFailed]: 'Process ends...',
  [ToastType.TransactionCompleted]: 'Transaction in confirmation process...',
  [ToastType.TransactionConfirmFailed]: undefined,
  [ToastType.TransactionConfirmed]: undefined,
  [ToastType.UndefinedText]: undefined,
};

export const iconSrc = {
  [ToastType.TransactionInProgress]: undefined,
  [ToastType.TransactionFailed]: undefined,
  [ToastType.TransactionCompleted]: undefined,
  [ToastType.TransactionConfirmFailed]: FailIcon,
  [ToastType.TransactionConfirmed]: SuccessIcon,
  [ToastType.UndefinedText]: undefined,
};

export const hasLoader = (toastType: ToastType) =>
  [
    ToastType.TransactionInProgress,
    ToastType.TransactionFailed,
    ToastType.TransactionCompleted,
    ToastType.UndefinedText,
  ].some(t => t === toastType);

