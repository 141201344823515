import React, {useState} from 'react';
import {SingleValue} from 'react-select';
import {AxiosResponse} from 'axios';

import Field from '../../../ModalUtils/Field/Field';
import CustomAsyncSelect from '../../../ModalUtils/CustomAsyncSelect/CustomAsyncSelect';
import TextOrNumberInput from '../../../ModalUtils/TextOrNumberInput/TextOrNumberInput';

import {Button} from '../../../../common/Buttons/Button';

import API from '../../../../../api';
import IDropdownResults from '../../../../../types/api/Admin/GameSessions/DropdownResults';
import IDropdownResultsByName from '../../../../../types/api/Admin/ReferralRewards/DropdownResults';
import {IRewardEvent} from '../../../../../types/api/Admin/ActivityEvents/ActivityEvent';

import {ModalWrapper, ModalTitle, ModalFooter} from '../../../ModalUtils/styles';

interface IGrantGiftModalContent {
    onClose: () => void;
    searchFunction?: (value: string) => Promise<AxiosResponse<IDropdownResults[] | IDropdownResultsByName[]>>;
    grantFunction?: (userId: string, itemId: string, amount?: number) => Promise<void>;
    grantUserIdFunction?: (eventId: string, rewardId: string, userId: string) => Promise<void>;
    itemId: string;
    selectedElem?: IRewardEvent;
    modalTitle?: string;
    withAmount?: boolean;
    getData?: (page?: number, ...args: any[]) => Promise<void>;
}

interface IUsers {
    value: string,
    label: string,
}

let inputChangedTimeout: NodeJS.Timeout;
const INPUT_CHANGE_APPLY_MILLIS = 1500; // 1.5 sec

const GrantGiftModalContent = ({
  onClose,
  searchFunction,
  grantFunction,
  grantUserIdFunction,
  itemId,
  modalTitle = 'Grant item',
  getData,
  selectedElem,
  withAmount = false,
}: IGrantGiftModalContent) => {
  const [selectedValue, setSelectedValue] = useState<SingleValue<IUsers>>(null);
  const [amount, setAmount] = useState<number>(1);

  const loadOptions = (inputValue: string, callback: (values: IUsers[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      let response
      if (searchFunction) {
        response = await searchFunction(inputValue);
      } else {
        response = await API.admin.users.searchByEmail(inputValue);
      }
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  const parseData = (data: IDropdownResults[] | IDropdownResultsByName[]) => {
    const value: IUsers[] = [];
    data.forEach(item => {
      value.push({
        value: item.id,
        label: (item as IDropdownResults)?.email ? (item as IDropdownResults).email : (item as IDropdownResultsByName).name,
      });
    });

    return value;
  };

  const handleChange = (newValue: SingleValue<IUsers>) => {
    setSelectedValue(newValue);
  };

  const grantItem = async () => {
    if (!selectedValue) {
      return;
    }

    try {
      if (grantFunction) {
        await grantFunction(selectedValue.value, itemId, withAmount ? amount : undefined);
        if (getData) {
          await getData().catch(console.error);
        }
      }
      if (grantUserIdFunction && selectedElem) {
        await grantUserIdFunction(itemId, selectedElem.id, selectedValue.value);
        if (getData) {
          await getData(1, false, itemId).catch(console.error);
        }
      }
      onClose();
      window.addToast('success', '', 'Item granted', 1500, '#1FBF42');
    } catch (e) {
      console.log(e);
      return window.addToast('danger', 'Error', 'Failed. Please try again later');
    }
  };

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold">{modalTitle}</ModalTitle>

      <CustomAsyncSelect
        loadOptions={loadOptions}
        value={selectedValue}
        onChange={handleChange}
        activeFocus={true}
      />

      {withAmount && (
        <Field title="Amount:">
          <TextOrNumberInput
            value={amount}
            onChange={(event) => setAmount(Math.max(Number(event.target.value), 1))}
            type='number'
            placeholder="Amount ..."
            min={1}
          />
        </Field>
      )}

      <ModalFooter>
        <Button
          buttonText="Cancel"
          onClick={() => onClose()}
          buttonStyle={{marginRight: '20px'}}
        />
        <Button
          buttonText={'Grant'}
          onClick={grantItem}
        />
      </ModalFooter>
    </ModalWrapper>
  );
};

export default GrantGiftModalContent;