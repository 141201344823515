import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

export async function getAllCollections() {
  return makeRequest('GET', '/api/collections/all');
}

export async function get(id: string): Promise<AxiosResponse> {
  return makeRequest('GET', `/api/collections/${id}`);
}
