import styled, {keyframes} from 'styled-components';

const ScrollButtonOn = keyframes`
  0% {
    opacity: 0;
    bottom: -50px;
  }
  100% {
    opacity: 1;
    bottom: 20px;
  }
`;

const ScrollButtonOff = keyframes`
  0% {
    opacity: 1;
    bottom: 20px;
  }
  100% {
    opacity: 0;
    bottom: -50px;
  }
`;

export const ButtonWrapper = styled.button<{ activeAnimation: boolean }>`
  position: fixed;
  right: 20px;
  bottom: -50px;
  z-index: 1000;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #FFA500;
  border-radius: 50%;
  border: none;

  outline: none;

  transition: background-color 0.6s ease-in-out;

  padding: 0;

  animation: ${({activeAnimation}) => activeAnimation ? ScrollButtonOn : ScrollButtonOff} 0.4s ease-in-out forwards;

  :hover {
    transition: background-color 0.6s ease-in-out;

    background-color: #D29002
  }

  @media (max-width: 520px) {
    bottom: 12px;
    right: 12px;

    width: 40px;
    height: 40px;
  }
`;