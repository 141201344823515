import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Article from '../../../types/api/Admin/Articles/Article';

export async function getArticles(
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<Article>>> {
  const response: AxiosResponse<PaginatedResponse<Article>> = await makeAdminRequest(
    'GET',
    `/api/admin/articles?page=${page}&perPage=${perPage}`,
  );
  return response;
}

export async function changePublished(
  articleId: string,
  published: boolean,
) {
  const response = await makeAdminRequest('POST', `/api/admin/articles/${articleId}/publishment`, {
    published,
  });
  if (response.status === 200) {
    return response.data;
  }
}