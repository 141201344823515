import styled from 'styled-components';
import {SCREEN_TYPES} from '../../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';

export const ButtonWrapper = styled.button<{ screenType: SCREEN_TYPES }>`
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '48px' : '40px'};

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #FFA500;
  border-radius: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '4px' : '50%'};

  outline: none;

  padding: 8px;

  transition-duration: 0.6s;

  :hover {
    background: #D29002;
    opacity: 0.8;
    transition-duration: 0.6s;
  }

  :disabled {
    background-color: #D29002;
    opacity: 0.5;
  }
`;

export const ButtonText = styled.span`
  text-transform: uppercase;
  white-space: nowrap;

  padding: 0;
  margin: 0 4px 0 0;
`;

export const ButtonContentIcon = styled.img<{ screenType: SCREEN_TYPES }>`
  position: relative;
  bottom: 2px;

  width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '32px' : '26px'};
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '32px' : '26px'};
`;