import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import MessageResponse from '../../../types/api/MessageResponse';
import InventoryClass from '../../../types/api/Admin/InventoryClasses/InventoryClasses';
import IDropdownResultsByName from '../../../types/api/Admin/ReferralRewards/DropdownResults';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';

export async function getInventoryClasses(page: number = 1, perPage: number = 20, name: string): Promise<AxiosResponse<PaginatedResponse<InventoryClass>>> {
  return makeAdminRequest('POST', '/api/admin/game/inventory-classes/filter', {page, perPage, name})
}

export async function deleteInventoryClass(inventoryClassId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/inventory-classes/${inventoryClassId}`);
}

export async function createInventoryClass(name: string): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/inventory-classes', {name});
}

export async function updateInventoryClass(inventoryClassId: string, name: string): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/game/inventory-classes/${inventoryClassId}`, {name});
}

export async function searchInventoryClassesByName(name: string, type?: number): Promise<AxiosResponse<IDropdownResultsByName[]>> {
  if (Number(type) >= 0) {
    return makeAdminRequest('GET', `/api/admin/game/inventory-classes/search?name=${name}&type=${type}`);
  } else {
    return makeAdminRequest('GET', `/api/admin/game/inventory-classes/search?name=${name}`);
  }
}

export async function deleteItemClass(itemId: string, classId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/items-classes/items/${itemId}`, {classId});
}