import {UserContext} from './userContext/userContext';
import {SignedWalletContext} from './signedWalletContext/signedWalletContext';
import {UnsavedChangesContext} from './unsavedChangesContext/UnsavedChangesContext';
import {TwoFAContext} from './TwoFAProvider';
// import {StakingAssetsStatusContext} from './stakingAssetsStatusContext/stakingAssetsStatusContext';
// import {DashboardAlphaKeyBlockContext} from './dashboardAlphaKeyBlockContext/DashboardAlphaKeyBlockContext';
// import {AlphaKeyFromContext} from './alphaKeyFromContext/AlphaKeyFromContext';

export default {
  GLOBAL: {
    USER: UserContext,
    WALLET: SignedWalletContext,
    UNSAVED: UnsavedChangesContext,
    TWO_F_A: TwoFAContext,
    // STAKING: StakingAssetsStatusContext,
  },

  // COMPONENT: {
  //   DASHBOARD: {
  //     ALPHA_KEY: DashboardAlphaKeyBlockContext,
  //   },
  //   HEADER: {
  //     FORM: AlphaKeyFromContext,
  //   },
  // },
};