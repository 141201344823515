import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import {IRewardType} from '../../../types/api/Admin/ReferralRewards/RewardType';
import {ILevelRewardAdmin} from '../../../types/api/Reward';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IDropdownResults from '../../../types/api/Admin/ReferralRewards/DropdownResults';
import IDropdownResultsByName from '../../../types/api/Admin/ReferralRewards/DropdownResults';

export async function getRewardTypes(): Promise<AxiosResponse<IRewardType[]>> {
  return makeAdminRequest('GET', '/api/admin/level-rewards/types');
}

export async function list(page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<ILevelRewardAdmin>>> {
  return makeAdminRequest('GET', `/api/admin/level-rewards?page=${page}&perPage=${perPage}`);
}

export async function create(enabled: boolean, itemType: number, lvl: number, itemId?: string) {
  return makeAdminRequest('POST', '/api/admin/level-rewards', {enabled, itemType, lvl, itemId});
}

export async function update(id: string, enabled: boolean, itemType: number, lvl: number, itemId: string) {
  return makeAdminRequest('POST', `/api/admin/level-rewards/${id}`, {enabled, itemType, lvl, itemId});
}

export async function destroy(id: string) {
  return makeAdminRequest('DELETE', `/api/admin/level-rewards/${id}`);
}

export async function searchInventoryByName(name: string, rarity?: number): Promise<AxiosResponse<IDropdownResults[]>> {
  if (Number(rarity) >= 0) {
    return makeAdminRequest('GET', `/api/admin/game/inventories/dropdown-results?name=${name}&rarity=${rarity}`);
  } else {
    return makeAdminRequest('GET', `/api/admin/game/inventories/dropdown-results?name=${name}`);
  }
}