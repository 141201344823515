import {Dispatch, SetStateAction} from 'react';

import useLogin from '../useLogin/useLogin';

import API from '../../api/index';

const useAutoLoginToken = () => {
  const domain = `${window.location.protocol}//${window.location.host}/`;

  const autoLogin = async (setUserId: Dispatch<SetStateAction<string>>, setCurrentAvatar: Dispatch<SetStateAction<string>>, autoLoginToken: string, redirectUrl: string) => {
    try {
      const response: any = await API.users.autoLoginToken(autoLoginToken);
      if (response) {
        const loginHook = useLogin();
        await loginHook(response, setUserId, setCurrentAvatar);

        window.setLoggedIn(true);

        window.location.replace(domain + (redirectUrl || '#/digital-assets'));
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return autoLogin;
};

export default useAutoLoginToken;