import React, {useEffect, useState} from 'react';

import ImageByUrl from '../../../../Client/common/ImageByUrl/ImageByUrl';

import './index.css';

const THICKNESS = 7;
const RADIUS = 32;

const percentToOffset = (percent: number) => {
  const maxValue = 350;
  const minValue = 113;
  const valueRange = maxValue - minValue;
  const percentageRange = 100;

  const percentageAsDecimal = percent / percentageRange;
  return maxValue - (valueRange * percentageAsDecimal);
};

const AvatarProgressBar = ({
  percent,
  avatarUrl,
}: { percent: number, avatarUrl: string }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState<number>(percentToOffset(percent));

  useEffect(() => {
    setStrokeDashoffset(percentToOffset(percent));
  }, [percent]);

  const childrenStyle = {
    width: RADIUS * 2 - THICKNESS * 2 - 3,
    height: RADIUS * 2 - THICKNESS * 2 - 3,
    borderRadius: '50%',
    position: 'absolute',
    top: THICKNESS + 3.5,
    left: THICKNESS + 3.5,
  };

  const circlesRadius = RADIUS + THICKNESS * 2 - 8;
  const circlesCXY = '48';

  return (
    <div style={{position: 'relative'}}>
      <div style={{
        width: RADIUS * 2 + 4,
        height: RADIUS * 2 + 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div
          style={{width: RADIUS * 2 + 4, height: RADIUS * 2 + 4, backgroundSize: `${RADIUS}px ${RADIUS}px`}}>
          <svg className="progress-svg" viewBox="0 0 100 100" style={{alignSelf: 'center'}}>
            <defs>
              <filter id="shadow">
                <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="#FFD280" floodOpacity="0.6"/>
              </filter>
            </defs>
            <g transform="translate(2, 2)">
              <circle stroke="#322222" r={circlesRadius} cx={circlesCXY} cy={circlesCXY}
                fill="transparent"
                strokeDasharray="350"
                strokeWidth={THICKNESS + 1}
                strokeDashoffset={0}></circle>
              <circle className="bar" r={circlesRadius} cx={circlesCXY} cy={circlesCXY} fill="transparent"
                strokeDasharray="350"
                strokeWidth={THICKNESS + 1}
                filter="url(#shadow)"
                transform={`rotate(-90, ${circlesCXY}, ${circlesCXY})`}
                strokeLinecap="round"
                strokeDashoffset={strokeDashoffset}></circle>
            </g>
          </svg>
        </div>
      </div>
      <ImageByUrl style={childrenStyle}
        url={avatarUrl}/>
    </div>
  );
};

export default AvatarProgressBar;