import {AxiosResponse, Method} from 'axios';
import PaginatedResponse from '../../../types/api/PaginatedResponse';

import makeAdminRequest from '../../adminRequest';

import ActivityEvent, {IUserInventory} from '../../../types/api/Admin/ActivityEvents/ActivityEvent';
import IDropdownValue, {IGeneralTypes} from '../../../types/api/Admin/IGeneralTypes';
import Feature from '../../../types/api/Admin/Feature/Feature';

export async function getActivityEvents(
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<ActivityEvent>>> {
  return makeAdminRequest('GET', `/api/admin/game/activity-events?page=${page}&perPage=${perPage}`);
}

export async function createActivityEvent(
  name: string,
  start: number,
  end: number,
) {
  return makeAdminRequest('POST', '/api/admin/game/activity-events', {name, start, end});
}

export async function updateActivityEvent(
  eventId: string,
  name: string,
  start: number,
  end: number,
) {
  return makeAdminRequest('POST', `/api/admin/game/activity-events/${eventId}`, {name, start, end});
}

export async function deleteActivityEvent(eventId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/activity-events/${eventId}`);
}

export async function eventsActivityTypes(): Promise<AxiosResponse<IDropdownValue[]>> {
  return makeAdminRequest('GET', '/api/admin/game/activity-events/activity-types');
}

export async function searchActivityTargets(activityType: number, search: string) {
  return makeAdminRequest('POST', '/api/admin/game/activity-events/activity-types/search-targets', {activityType, search});
}

export async function eventsRewardTypes(): Promise<AxiosResponse<IGeneralTypes>> {
  return makeAdminRequest('GET', '/api/admin/game/activity-events/reward-types');
}

export async function searchRewardItem(method: Method, url: string, queryParam: string, search: string) {
  return makeAdminRequest(method, `${url}?${queryParam}=${search}`);
}

export async function createActivityEventReward(
  eventId: string,
  activityType: number,
  targetId: string | number,
  goal: number,
  activityEventRewardType: number,
  rewardId: string,
) {
  return makeAdminRequest('POST', `/api/admin/game/activity-events/${eventId}/rewards`, {
    activityType,
    targetId,
    goal,
    activityEventRewardType,
    rewardId,
  });
}

export async function updateActivityEventReward(
  eventId: string,
  eventRewardId: string,
  activityType: number,
  targetId: string | number,
  goal: number,
  activityEventRewardType: number,
  rewardId: string,
  active: boolean,
) {
  return makeAdminRequest('POST', `/api/admin/game/activity-events/${eventId}/rewards/${eventRewardId}`, {
    activityType,
    targetId,
    goal,
    activityEventRewardType,
    rewardId,
    active,
  });
}

export async function deleteUpdateActivityEventReward(eventId: string, eventRewardId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/activity-events/${eventId}/rewards/${eventRewardId}`);
}

export async function getUserRewardEvent(userId: string, includeNotGranted: boolean = true, page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<IUserInventory>>> {
  return await makeAdminRequest('GET', `/api/admin/game/user-activity-events-rewards/${userId}?includeNotGranted=${includeNotGranted}&page=${page}&perPage=${perPage}`);
}

export async function grantEventReward(eventId: string, rewardId: string, userId: string) {
  return makeAdminRequest('POST', `/api/admin/game/activity-events/${eventId}/rewards/${rewardId}/grant`, {userId});
}

export async function getActivityEvent(eventId: string): Promise<AxiosResponse<ActivityEvent>> {
  return makeAdminRequest('GET', `/api/admin/game/activity-events/${eventId}`);
}
