import React, {CSSProperties} from 'react';

import {SCREEN_TYPES} from '../../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';

import {ButtonContentIcon, ButtonText, ButtonWrapper} from './styles';

interface IIconButton {
    buttonText?: string | JSX.Element;
    isDisabled?: boolean;

    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onKeyPress?: (e: React.KeyboardEvent<HTMLImageElement> | any) => void;

    wrapperStyle?: CSSProperties;
    iconStyle?: CSSProperties;
    wrapperClassName?: string;

    tabIndex?: number;

    src?: string;

    screenType: SCREEN_TYPES
}

const IconButton = ({
  buttonText,
  isDisabled = false,
  onClick,
  onKeyPress,
  wrapperStyle,
  wrapperClassName,
  tabIndex,
  src,
  iconStyle,
  screenType
}: IIconButton) => {
  return (
    <ButtonWrapper onClick={onClick} onKeyPress={onKeyPress} disabled={isDisabled} style={wrapperStyle}
      className={wrapperClassName} tabIndex={tabIndex} screenType={screenType}>
      {screenType === SCREEN_TYPES.DESKTOP &&
                <ButtonText className="text-bold white-text-1">{buttonText}</ButtonText>}
      <ButtonContentIcon src={src} style={iconStyle} screenType={screenType}/>
    </ButtonWrapper>
  );
};

export default IconButton;