import React, {Fragment, useEffect, useState, Dispatch, SetStateAction} from 'react';

import LevelReward from '../LevelReward/LevelReward';
import ContainerLoader from '../../../../../common/Loader/ContainerLoader';

import {DEFAULT_REWARD_HIDE} from '../SnakeRewards';

import {IReward} from '../../../../../../types/api/Reward';
import {TooltipDirection} from '../../../../../common/Tooltip/Tooltip';

interface IRowRewards {
    rows: IReward[][];
    row: IReward[];
    rowIndex: number;
    count: number;
    levelRewardsValue: IReward[];
    getLevelRewards: () => Promise<void>;
    activeAnimation: boolean;
    setActiveAnimation: Dispatch<SetStateAction<boolean>>;
}

const RowRewards = ({
  rows,
  row,
  rowIndex,
  count,
  getLevelRewards,
  levelRewardsValue,
  activeAnimation,
  setActiveAnimation,
}: IRowRewards) => {
  const [parsedRow, setParsedRow] = useState<IReward[]>();
  const [loaded, setLoaded] = useState<boolean>(Boolean(levelRewardsValue));

  const parseRow = (row: IReward[], rowIndex: number, count: number) => {
    if (!row || !count) {
      return;
    }

    if (rowIndex % 2 === 0) {
      if (row.length === count) {
        setParsedRow(row);
      } else {
        let defaultArray: IReward[] = [];
        for (let i = 0; i < count - row.length; i++) {
          defaultArray.push(DEFAULT_REWARD_HIDE);
        }
        setParsedRow([...row, ...defaultArray]);
      }
    } else {
      if (row.length === count) {
        setParsedRow(row.slice().reverse());
      } else {
        let defaultArray: IReward[] = [];
        for (let i = 0; i < count - row.length; i++) {
          defaultArray.push(DEFAULT_REWARD_HIDE);
        }
        setParsedRow([...row, ...defaultArray].slice().reverse());
      }
    }

    setLoaded(true);
  };

  useEffect(() => {
    parseRow(row, rowIndex, count);
  }, [rows]);

  return (
    <Fragment key={rowIndex}>
      {loaded ? (
        parsedRow?.map((reward, rewardIndex) => (
          <LevelReward
            key={reward.id || (`${(rowIndex + 1) * rewardIndex}$`)}
            reward={reward}
            isDisabledTooltip={!reward.name}
            tooltipDirection={TooltipDirection.Bottom}
            getLevelRewards={getLevelRewards}
            tooltipStyle={{zIndex: 100 - rowIndex}}
            tooltipClassName={'reward-tooltip'}
            styleRowIndex={rowIndex % 2 === 0 ? 1 : 2}
            rowIndex={rowIndex}
            rewardIndex={rewardIndex}
            rows={rows}
            parsedRow={parsedRow}
            count={count}
            activeAnimation={activeAnimation}
            setActiveAnimation={setActiveAnimation}
          />
        ))
      ) : <ContainerLoader size={40}/>}
    </Fragment>
  );
};

export default RowRewards;