import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import * as localStorage from '../localStorage';

import {AUTH_SESSION_NAME} from '../config';
import {LOCAL_STORAGE_NAMES} from '../localStorage/types';

function RequireAuthWithQueryParameter({children, path}: { children: JSX.Element, path: string }) {
  const location = useLocation();
  if (location) {
    if (location.search) {
      localStorage.setItem(LOCAL_STORAGE_NAMES.UPDATE_EMAIL_PATH, `#${location.pathname}${location.search}`);
    } else {
      localStorage.setItem(LOCAL_STORAGE_NAMES.UPDATE_EMAIL_PATH, `#${location.pathname}`);
    }
  }

  const session =
        localStorage.getSessionVal() || sessionStorage.getItem(AUTH_SESSION_NAME);

  if (!session) {
    return <Navigate to={path} replace/>;
  }

  return children;
}

export default RequireAuthWithQueryParameter;
