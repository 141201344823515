import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import ISessionsSettings from '../../../types/api/Admin/GameSessions/SessionsSettings';

export async function sessionsSettings(): Promise<AxiosResponse<ISessionsSettings>> {
  return makeAdminRequest('GET', '/api/admin/game/sessions-settings');
}

export async function saveSessionsSettings(enabled: boolean, userIds: string[], allowAt: number | false, disallowAt: number | false): Promise<void> {
  await makeAdminRequest('POST', '/api/admin/game/sessions-settings', {
    enabled: enabled,
    userIds: userIds,
    allowAt,
    disallowAt,
  });
}