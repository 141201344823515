import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import * as localStorage from '../../../localStorage';
import Account from '../../../types/api/Game/Account';
import Statistic from '../../../types/api/Game/Statistic';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Inventory from '../../../types/api/Game/Inventory';
import {ILeaderboard} from '../../../types/api/Game/Leaderboard';

export async function get(): Promise<AxiosResponse<Account>> {
  return makeRequest('GET', '/api/accounts');
}

export async function changeAutoDlcState(
  autoDLC: boolean,
): Promise<AxiosResponse<Statistic[]>> {
  const accountId = await localStorage.getAccountId();
  return makeRequest('POST', `/api/accounts/${accountId}/auto-dlc`, {
    autoDLC: autoDLC,
  });
}

export async function changeUsername(
  username: string,
): Promise<AxiosResponse<Statistic[]>> {
  const accountId = await localStorage.getAccountId();
  return makeRequest('POST', `/api/accounts/${accountId}/username`, {
    username,
  });
}

export async function getInventories(
  dlc: boolean = true,
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<Inventory>>> {
  const accountId = await localStorage.getAccountId();
  return makeRequest(
    'GET',
    `/api/accounts/${accountId}/inventories?dlc=${dlc}&page=${page}&perPage=${perPage}`,
  );
}

export async function getAllTournamentLeaderboard(): Promise<AxiosResponse<ILeaderboard>> {
  const accountId = await localStorage.getAccountId();
  return makeRequest('GET', `/api/accounts/${accountId}/tournament-leaderboards`);
}