import {useEffect, useState} from 'react';
import {singletonHook} from 'react-singleton-hook';
import {FcmController} from './FcmController';

const init = new FcmController();

const useFcmControllerImpl = () => {
  const [fcmController] = useState<FcmController>(init);
  useEffect(() => {
    fcmController.init().catch(console.error);
  }, []);

  return fcmController;
};

export const useFcmController = singletonHook(init, useFcmControllerImpl);