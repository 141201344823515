import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import IEvent, {IEventTypes, INomination} from '../../../types/api/Admin/Events/Events';

export async function getEvents(page: number = 1,perPage: number = 20): Promise<AxiosResponse<IEvent[]>> {
  return makeAdminRequest('GET', `/api/admin/game/events?page=${page}&perPage=${perPage}`);
}

export async function createEvent(name: string, startAt: number, finishAt: number, rewardsDisabledAt: number) {
  return makeAdminRequest('POST', '/api/admin/game/events', {name, startAt, finishAt, rewardsDisabledAt});
}

export async function updateEvent(eventId: string, name: string, startAt: number, finishAt: number, rewardsDisabledAt: number) {
  return makeAdminRequest('POST', `/api/admin/game/events/${eventId}`, {name, startAt, finishAt, rewardsDisabledAt});
}

export async function deleteEvent(eventId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/events/${eventId}`);
}

export async function eventTypes(): Promise<AxiosResponse<IEventTypes>> {
  return makeAdminRequest('GET', '/api/admin/game/events/types');
}

export async function createEventNominations(eventId: string, nomination: INomination) {
  return makeAdminRequest('POST', `/api/admin/game/events/${eventId}/nominations`, nomination);
}

export async function updateEventNominations(eventId: string, eventNominationId: string, nomination: INomination) {
  return makeAdminRequest('POST', `/api/admin/game/events/${eventId}/nominations/${eventNominationId}`, nomination);
}

export async function deleteEventNominations(eventId: string, eventNominationId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/events/${eventId}/nominations/${eventNominationId}`);
}
