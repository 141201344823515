import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Modal from '../../../Modal/Modal';
import ActionConfirmationModalContent
  from '../../../Modal/common/ActionConfirmationModalContent/ActionConfirmationModalContent';

import AvatarProgressBar from './AvatarProgressBar/AvatarProgressBar';
import LevelRewardsModalContent from '../../../Modal/Client/LevelRewardsModalContent/LevelRewardsModalContent';

import useLogout from '../../../../hooks/useLogout/useLogout';
import {useOutsideClick} from '../../../../hooks/useOutsideClick/useOutsideClick';
import {connectOpenLoot} from '../../../../utils/data/openLoot/openLoot';

import CONTEXT from '../../../../context';

import API from '../../../../api';
import * as localStorage from '../../../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../../../localStorage/types';
import {FCM_TYPES} from '../../../../services/firebase/FcmController';
import {REFRESH_TYPES} from '../../../../services/refresh/RefreshController';

import DefaultIcon from '../../../../assets/images/common/header/DefaultIcon.webp';

import {
  AppHeaderDropdownMenu,
  AppHeaderDropdownMenuDashboard,
  AppHeaderDropdownMenuDigitalAssets,
  AppHeaderDropdownMenuItem,
  AppHeaderDropdownMenuItemLink,
  AppHeaderDropdownMenuItemLogout,
  AppHeaderDropdownMenuProfile,
  AppHeaderDropdownMenuReferralSystem,
  AppHeaderDropdownToggle,
  AppHeaderDropdownWrapper,
} from '../styles';

const AppHeaderDropdown = () => {
  const {
    currentAvatar,
    getUserInfo,
    userId,
    currentExperience,
    levelRewards,
    getLevelRewards,
    setLevelRewardsVisible,
    levelRewardsVisible,
    refreshOpenlootConnection,
    setRefreshOpenlootConnection,
  } = useContext(CONTEXT.GLOBAL.USER);
    // const {checkAlphaKey, dashboardAlphaKeyBlockProviderInit} = useContext(CONTEXT.COMPONENT.DASHBOARD.ALPHA_KEY);
    // const {setLoaded} = useContext(CONTEXT.COMPONENT.HEADER.FORM);

  const logout = useLogout();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [discordConnected, setDiscordConnected] = useState<boolean>(false);

  const [activeAnimation, setActiveAnimation] = useState<boolean>(true);

  const onClose = () => {
    setVisible(false);
  };

  useOutsideClick(dropdownRef, onClose, visible);

  const getUserDiscordStatus = async () => {
    if (!userId) {
      return;
    }
    const userInfo = await API.users.getUserDiscordStatus(userId);
    if (!userInfo) {
      return;
    }
    setDiscordConnected(userInfo.data.status);
  };

  const actionOpenlootConnect = async (userId: string) => {
    if (!userId) {
      return;
    }
    const action = localStorage.getItem(LOCAL_STORAGE_NAMES.CONNECT_OPENLOOT);
    if (action) {
      await connectOpenLoot(userId, 1);
    }
  };

  useEffect(() => {
    window.fcmController?.setEventListener(FCM_TYPES.SESSION_DESTROYED, async () => {
      await logout().catch(console.error);
    });

    window.fcmController?.setEventListener(FCM_TYPES.AVATAR_UPDATED, async () => {
      await getUserInfo().catch(console.error);
    });
    window.fcmController?.setEventListener(FCM_TYPES.EXP_GAINED, async () => {
      await getUserInfo().catch(console.error);
    });

    // window.fcmController?.setEventListener(FCM_TYPES.ALPHA_KEY_GRANTED, async () => {
    //   await checkAlphaKey().catch(console.error);
    // });

    window.refreshController.set(REFRESH_TYPES.APP_HEADER_DROPDOWN, async () => {
      await getUserInfo().catch(console.error);
      // await checkAlphaKey().catch(console.error);
    });

    return () => {
      window.fcmController?.removeEventListener(FCM_TYPES.SESSION_DESTROYED);
      window.fcmController?.removeEventListener(FCM_TYPES.AVATAR_UPDATED);
      window.fcmController?.removeEventListener(FCM_TYPES.EXP_GAINED);
      window.fcmController?.removeEventListener(FCM_TYPES.ALPHA_KEY_GRANTED);
      window.fcmController?.removeEventListener(FCM_TYPES.LEVEL_REWARDS_UPDATE);
      window.refreshController.clear(REFRESH_TYPES.APP_HEADER_DROPDOWN);
    };
  }, [userId]);

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_NAMES.REDIRECT_URL);
    // return () => {
    //   setLoaded(false);
    // };
  }, []);

  useEffect(() => {
    if (userId) {
      getUserDiscordStatus().catch(console.error);
      // dashboardAlphaKeyBlockProviderInit().catch(console.error);

      actionOpenlootConnect(userId).catch(console.error);
    }
  }, [userId]);

  useEffect(() => {
    if (discordConnected) {
      localStorage.removeItem(LOCAL_STORAGE_NAMES.DISCORD_CONNECTION_PAGE);
    }
  }, [discordConnected]);

  return (
    <>
      <AppHeaderDropdownWrapper ref={dropdownRef}>
        <AppHeaderDropdownToggle onClick={() => setVisible(!visible)}>
          <AvatarProgressBar avatarUrl={currentAvatar ? currentAvatar : DefaultIcon}
            percent={currentExperience}/>
        </AppHeaderDropdownToggle>
        <AppHeaderDropdownMenu visible={visible} style={{top: '69px'}}>
          <AppHeaderDropdownMenuItem margin="0 0 20px 0">
            <AppHeaderDropdownMenuItemLink to="/dashboard" onClick={onClose} className="text-light">
              <AppHeaderDropdownMenuDashboard/>
                            Dashboard
            </AppHeaderDropdownMenuItemLink>
          </AppHeaderDropdownMenuItem>
          <AppHeaderDropdownMenuItem margin="0 0 20px 0">
            <AppHeaderDropdownMenuItemLink to="/digital-assets" onClick={onClose} className="text-light">
              <AppHeaderDropdownMenuDigitalAssets/>
                            Your Digital assets
            </AppHeaderDropdownMenuItemLink>
          </AppHeaderDropdownMenuItem>
          <AppHeaderDropdownMenuItem margin="0 0 20px 0">
            <AppHeaderDropdownMenuItemLink to="/profile-settings" onClick={onClose} className="text-light">
              <AppHeaderDropdownMenuProfile/>
                            Profile settings
            </AppHeaderDropdownMenuItemLink>
          </AppHeaderDropdownMenuItem>
          <AppHeaderDropdownMenuItem margin="0 0 20px 0">
            <AppHeaderDropdownMenuItemLink to="/referral-system" onClick={onClose} className="text-light">
              <AppHeaderDropdownMenuReferralSystem/>
                            Referral system
            </AppHeaderDropdownMenuItemLink>
          </AppHeaderDropdownMenuItem>
          <AppHeaderDropdownMenuItem margin="0">
            <AppHeaderDropdownMenuItemLogout
              onClick={() => {
                logout(true).catch(console.error);
              }}>
                            Log out
            </AppHeaderDropdownMenuItemLogout>
          </AppHeaderDropdownMenuItem>
        </AppHeaderDropdownMenu>
      </AppHeaderDropdownWrapper>

      <Modal
        isOpen={levelRewardsVisible}
        onClose={() => setLevelRewardsVisible(false)}
        shouldCloseOnOverlayClick={true}
      >
        <LevelRewardsModalContent
          levelRewards={levelRewards}
          getLevelRewards={getLevelRewards}
          onClose={() => setLevelRewardsVisible(false)}
          activeAnimation={activeAnimation}
          setActiveAnimation={setActiveAnimation}
        ></LevelRewardsModalContent>
      </Modal>

      <Modal
        isOpen={refreshOpenlootConnection}
        onClose={() => {
          setRefreshOpenlootConnection(false);
          navigate('/dashboard');
        }}
        shouldCloseOnOverlayClick={true}
      >
        <ActionConfirmationModalContent
          onClose={() => {
            setRefreshOpenlootConnection(false);
            navigate('/dashboard');
          }}
          actionConfirmation={() => {
            connectOpenLoot(userId, 0).catch(console.error);
            setRefreshOpenlootConnection(false);
          }}
          actionCancel={() => navigate('/dashboard')}
          modalTitle={'Refresh Open Loot'}
          modalText={'Please refresh the Open Loot user credentials'}
          buttonTitle={'Refresh'}
          closeModalAfterConfirmation={false}
        />
      </Modal>
    </>
  );
};

export default AppHeaderDropdown;