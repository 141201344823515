import React, {ReactChildren, ReactNode, CSSProperties} from 'react';

import {
  TooltipArrow,
  TooltipContainer,
  TooltipContent,
  TooltipElement, TooltipSubtitleContent,
  TooltipTitleContent,
} from './styles';

export enum TooltipDirection {
    Top = 'Top',
    Left = 'Left',
    Bottom = 'Bottom',
    Right = 'Right',
}

interface ITooltip {
    children: ReactNode | ReactChildren,
    direction?: TooltipDirection,
    text?: string | undefined | null,
    title?: string | undefined | null,
    subtitle?: string | undefined | null,
    isDisabled?: boolean,
    isTransparent?: boolean,
    zIndex?: number,
    tabIndex?: number,
    tooltipClassName?: string,
    tooltipStyle?: CSSProperties,
    tooltipElementStyle?: CSSProperties,
    tooltipArrowStyle?: CSSProperties,
    tooltipTextStyle?: CSSProperties,
    tooltipChildren?: ReactNode | ReactChildren,
    hideOnContentHover?: boolean,
}

const Tooltip = ({
  children,
  direction = TooltipDirection.Bottom,
  text,
  title = '',
  subtitle = '',
  isDisabled = true,
  isTransparent = false,
  zIndex = 2,
  tabIndex = -1,
  tooltipClassName,
  tooltipStyle,
  tooltipElementStyle,
  tooltipArrowStyle,
  tooltipTextStyle,
  tooltipChildren,
  hideOnContentHover = false
}: ITooltip) => {
  return (
    <TooltipContainer {...{isDisabled, hideOnContentHover}} tabIndex={tabIndex} zIndex={zIndex}
      style={tooltipStyle}>
      {children}
      <TooltipElement transparent={isTransparent}
        className={`tooltip-element ${tooltipClassName || ''}`} {...{direction}}
        style={tooltipElementStyle}
      >
        <TooltipArrow {...{direction}} style={tooltipArrowStyle}/>
        {title && <TooltipTitleContent>{title}</TooltipTitleContent>}
        {subtitle && <TooltipSubtitleContent>{subtitle}</TooltipSubtitleContent>}
        <TooltipContent className="text-light white-text-1" style={tooltipTextStyle}>{text}</TooltipContent>
        {tooltipChildren}
      </TooltipElement>
    </TooltipContainer>
  );
};

export default Tooltip;
