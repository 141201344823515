import React, {useEffect, useState} from 'react';

interface TimerInterface {
    gameSessionsEnabled: boolean;
    nextPlaytime?: number;
}

let timerIntervalId: NodeJS.Timeout | null;

const Timer = ({gameSessionsEnabled, nextPlaytime}: TimerInterface) => {
  const [timer, setTimer] = useState<string>('');
  const [timerInFuture, setTimerInFuture] = useState<boolean>(Boolean(nextPlaytime && Date.now() < nextPlaytime));

  const clearTimerInterval = () => {
    if (timerIntervalId) {
      clearInterval(timerIntervalId);
      timerIntervalId = null;
    }
  };
  const setTimerInterval = () => {
    clearTimerInterval();
    timerIntervalId = setInterval(updateTimer, 1000);
  };

  const updateTimer = () => {
    if (nextPlaytime) {
      const now = Date.now();
      const difference = nextPlaytime - now;

      if (difference <= 0) {
        setTimerInFuture(false);
        clearTimerInterval();
        return;
      }
      setTimerInFuture(true);
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      setTimer(`${days ? `${days}d` : ''} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      setTimerInterval();
    } else {
      setTimer('');
      clearTimerInterval();
    }
  };

  useEffect(() => {
    updateTimer();
  }, [nextPlaytime]);

  useEffect(() => {
    setTimerInterval();
    return () => {
      clearTimerInterval();
    };
  }, []);

  return !gameSessionsEnabled && !nextPlaytime ? <></> :
    !gameSessionsEnabled && nextPlaytime && timerInFuture ? (
      <span>Next Playtime starts in: <span className="orange-text-1">{timer}</span></span>
    ) :
      !gameSessionsEnabled && nextPlaytime ? (
        <span className="orange-text-1">Game Servers are heating up!</span>
      ) :
        gameSessionsEnabled ? (
          <span className="orange-text-1">Playtime is Live!</span>
        ) : <></>;
};

export default Timer;