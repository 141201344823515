import React from 'react';
import {Navigate} from 'react-router-dom';

import * as localStorage from '../localStorage';

import {AUTH_SESSION_NAME} from '../config';

function RequireAuth({children}: { children: JSX.Element }) {
  const session =
        localStorage.getSessionVal() || sessionStorage.getItem(AUTH_SESSION_NAME);

  if (!session) {
    return <Navigate to="/login" replace/>;
  }

  return children;
}

export default RequireAuth;
