import makeRequest from '../../request';

export async function storeWallet(address: string, addressBytes: string[], sign: string[], authWay?: string) {
  return makeRequest('POST', '/api/wallets', {address, addressBytes, authWay, sign});
}

export async function storeWalletViaTransaction(address: string, transaction: string, authWay?: string) {
  return makeRequest('POST', '/api/wallets', {address, transaction, authWay, verificationType: 'transaction'});
}

export async function getWallets() {
  return makeRequest('GET', '/api/wallets');
}

export async function exchangeToOpenloot(walletId: string) {
  return makeRequest('POST', `/api/wallets/${walletId}/assets-to-openloot`);
}

export async function getRewardTokenMint() {
  return makeRequest('GET', '/api/wallets/reward-token-mint');
}

export async function getConnectionUrl() {
  return makeRequest('GET', '/api/wallets/connection-url');
}