import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../Buttons/Button';
import NotificationElement from './NotificationElement';
import ContainerLoader from '../../Loader/ContainerLoader';
import Loader from '../../Loader/Loader';

import CONTEXT from '../../../../context';

import {useScreenSizeHook} from '../../../../hooks/useScreenSizeHook/useScreenSizeHook';
import {useOutsideClick} from '../../../../hooks/useOutsideClick/useOutsideClick';

import API from '../../../../api';
import Notifications from '../../../../types/api/Auth/Notifications';
import {FCM, FCM_TYPES} from '../../../../services/firebase/FcmController';
import {TooltipDirection} from '../../Tooltip/Tooltip';
import {SCREEN_TYPES} from '../../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';
import {REFRESH_TYPES} from '../../../../services/refresh/RefreshController';

import bellSvg from '../../../../assets/images/icons/bell.svg';

import {
  AppHeaderDropdownMenu,
  AppHeaderDropdownMenuCheck,
  AppHeaderDropdownMenuItem,
  AppHeaderDropdownMenuItemButton,
  AppHeaderDropdownMenuItemTitle,
  AppHeaderDropdownWrapper,
  CircularWrapperImg,
  CircularWrapper,
} from '../styles';
import {NotificationElementTextBlock, RedCircular, RedCircularWrapper} from './styles';

let notificationUpdatedTimer: NodeJS.Timeout;
let sendTimeout: NodeJS.Timeout;
const SEND_TIMEOUT_VALUE: number = 7 * 100; // 0.7 sec

let isFetchingNow: boolean = false;
let perPage: number = 10;
let currentPage: number = 1;
let notificationsIds: string[] = [];
let updateInProgress: boolean = false;

const NotificationsDropdown = () => {
  const {
    userId,
    emailVerified,
    getUserInfo,
    userDataLoaded,
    currentEmail,
    passwordResetRequired,
    setUpdatePasswordModalVisible,
  } = useContext(CONTEXT.GLOBAL.USER);
  const {screenType, width = 320} = useScreenSizeHook();

  const dropdownRef = useRef<HTMLDivElement>(null);
  let visibleContainer = document.getElementById('visible-container');
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<Notifications[]>([]);

  const [visible, setVisible] = useState<boolean>(false);
  const [arrayContainsUnreadNotifications, setArrayContainsUnreadNotifications] = useState<boolean>(false);
  const [successfulEmailSending, setSuccessfulEmailSending] = useState<boolean>(false);

  const [clockTimestamp, setClockTimestamp] = useState<number>(Date.now());

  const [resendButtonDisabled, setResendButtonDisabled] = useState<boolean>(false);
  const [readButtonDisabled, setReadButtonDisabled] = useState<boolean>(false);

  const [lastPage, setLastPage] = useState<boolean>(false);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [isSending, setIsSending] = useState<boolean>(false);

  const onClose = () => {
    setVisible(false);
  };

  useOutsideClick(dropdownRef, onClose, visible);

  const getData = async (page: number) => {
    if (!userId || isFetchingNow) {
      return;
    }
    isFetchingNow = true;

    let response = await API.users.getNotifications(userId, page, perPage);
    if (!response) {
      return;
    }

    if (response.data.length === 0) {
      setLoaded(true);
      return setLastPage(true);
    }

    setNotifications((prevState) => {
      const newNotifications: Notifications[] = response.data.some((notification: Notifications) => {
        return !prevState.some((prevItem) => prevItem.id === notification.id);
      });
      if (!newNotifications) {
        return prevState;
      }

      return prevState.concat(response.data);
    });

    setLoaded(true);

    currentPage = currentPage + 1;
    isFetchingNow = false;
  };

  const scrollHandler = async () => {
    if (lastPage || !visibleContainer) {
      return;
    }

    if (visibleContainer.scrollTop + 700 >= visibleContainer.scrollHeight && !isFetchingNow && !lastPage) {
      await getData(currentPage).catch(console.error);
    }
  };

  const checkUnread = async (checkNew: boolean = true) => {
    if (!userId || updateInProgress) {
      return;
    }
    updateInProgress = true;

    try {
      const response = await API.users.checkUnread(userId);
      if (!response) {
        return;
      }
      setArrayContainsUnreadNotifications(response.data.unread);

      if (response.data.unread && checkNew) {
        await checkNewNotifications().catch(console.error);
      }
    } catch (e) {
      console.log(e);
    }

    updateInProgress = false;
  };

  const checkNewNotifications = async () => {
    if (!userId) {
      return;
    }
    let response = await API.users.getNotifications(userId, 1, perPage);
    if (!response) {
      return;
    }
    let newNotifications: Notifications[] = [];

    response.data.forEach((newNotification: Notifications) => {
      if (!notifications.some(prevNotification => prevNotification.id === newNotification.id)) {
        newNotifications.push(newNotification);
      }
    });

    if (newNotifications.length > 0) {
      returnValues();
      getData(currentPage).catch(console.error);
      checkUnread(false).catch(console.error);
    }
  };

  const readNotification = async (id: string) => {
    if (!id || isSending) {
      return;
    }

    if (!notificationsIds.includes(id)) {
      notificationsIds.push(id);
      setNotifications(prevState => (
        prevState.map((elem) => (id === elem.id ? {...elem, isRead: true} : elem))
      ));
    }

    if (sendTimeout) {
      clearTimeout(sendTimeout);
    }
    sendTimeout = setTimeout(() => {
      if (!userId || notificationsIds.length === 0) {
        return;
      }
      sendReadNotifications();
    }, SEND_TIMEOUT_VALUE);
  };

  const sendReadNotifications = async () => {
    setIsSending(true);
    try {
      await API.users.readNotifications(userId, notificationsIds);
      await checkUnread(false).catch(console.error);
      notificationsIds = [];
    } catch (e) {
      console.log(e);
    }
    setIsSending(false);
  };

  const readAllNotifications = async () => {
    setReadButtonDisabled(true);
    try {
      if (!userId) {
        return;
      }
      await API.users.readAllNotifications(userId);
      setNotifications(prevState => (
        prevState.map((elem) => ({...elem, isRead: true}))
      ));
      await checkUnread(false).catch(console.error);
    } catch (e) {
      console.log(e);
    }
    setReadButtonDisabled(false);
  };

  const resendVerifyEmail = async () => {
    setResendButtonDisabled(true);
    try {
      await API.users.resendVerifyEmail(userId);
      onClose();
      window.addToast('success', '', 'Please check your email', 1500, '#1FBF42');
      setSuccessfulEmailSending(true);
    } catch {
      onClose();
      setResendButtonDisabled(false);
      window.addToast('danger', '', 'Email has been sent already. Please wait or try again later', 0, '', false);
    }
  };

  const returnValues = () => {
    isFetchingNow = false;
    setLastPage(false);
    currentPage = 1;
    clearTimeout(sendTimeout);
    setNotifications([]);
    setLoaded(false);
  };

  const passwordRegenerate = () => {
    navigate('/profile-settings');
    setUpdatePasswordModalVisible(true);
    onClose();
  };

  useEffect(() => {
    if (visible) {
      returnValues();
      getData(currentPage).catch(console.error);
      checkUnread(false).catch(console.error);
    }
  }, [visible]);

  useEffect(() => {
    checkUnread(false).catch(console.error);
  }, [userId]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (visibleContainer) {
      visibleContainer.addEventListener('scroll', scrollHandler);
    }
    return () => {
      if (visibleContainer) {
        visibleContainer.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [visible, visibleContainer]);

  useEffect(() => {
    window.fcmController?.setEventListener(FCM_TYPES.NEW_NOTIFICATION, async () => {
      await checkUnread().catch(console.error);
    });
    window.fcmController?.setEventListener(FCM_TYPES.NOTIFICATION_UPDATED, async (fcm: FCM) => {
      let updatedNotification = (fcm.data as Notifications);
      if (updatedNotification) {
        if (notificationUpdatedTimer) {
          clearTimeout(notificationUpdatedTimer)
        }
        notificationUpdatedTimer = setTimeout(async () => {
          await checkUnread().catch(console.error);
        }, 5000)
      }
    });
    window.fcmController?.setEventListener(FCM_TYPES.EMAIL_VERIFIED, async () => {
      await getUserInfo().catch(console.error);
      await checkUnread().catch(console.error);
    });

    return () => {
      window.fcmController?.removeEventListener(FCM_TYPES.NEW_NOTIFICATION);
      window.fcmController?.removeEventListener(FCM_TYPES.NOTIFICATION_UPDATED);
      window.fcmController?.removeEventListener(FCM_TYPES.EMAIL_VERIFIED);
    };
  }, [userId]);

  useEffect(() => {
    window.refreshController.set(REFRESH_TYPES.CHECK_UNREAD_FUNC, async () => {
      await checkUnread().catch(console.error);
    });

    return () => {
      window.refreshController.clear(REFRESH_TYPES.CHECK_UNREAD_FUNC);
    };
  }, [userId, currentPage]);

  useEffect(() => {
    return () => {
      returnValues();
    };
  }, []);

  return (
    <>
      <AppHeaderDropdownWrapper
        ref={dropdownRef}
        style={{position: `${520 > width ? 'static' : 'relative'}`}}
      >
        <CircularWrapper onClick={() => setVisible(!visible)}
          style={{margin: `${520 > width ? '0 8px 0 0' : '0 18px 0 0'}`}}
        >
          <CircularWrapperImg src={bellSvg}
            style={{width: '22px', height: '22px', background: 'transparent'}}/>
          {((arrayContainsUnreadNotifications || !emailVerified) && userDataLoaded) &&
                        <RedCircularWrapper top={'11px'} right={'10px'}>
                          <RedCircular
                            style={{
                              margin: 0,
                              width: '6px',
                              height: '6px',
                            }}/>
                        </RedCircularWrapper>}
        </CircularWrapper>
        <AppHeaderDropdownMenu visible={visible} style={{
          minWidth: `${screenType === SCREEN_TYPES.MOBILE ? '250px' : '350px'}`,
          padding: '20px 0 4px 0',
          width: `${520 > width ? '100%' : 'auto'}`,
        }}>
          <AppHeaderDropdownMenuItem
            margin="0"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 18px 10px 18px',
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            <AppHeaderDropdownMenuItemTitle padding="0" style={{borderBottom: 'none'}}
              className="text-light">
                            Notifications
            </AppHeaderDropdownMenuItemTitle>
            <AppHeaderDropdownMenuItemButton
              className="text-light"
              onClick={() => ((notifications.length && !readButtonDisabled) ? readAllNotifications() : {})}
              style={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                width: 'auto',
                textTransform: 'none',
              }}
            >
              {readButtonDisabled ?
                <Loader size={18} borderWidth={3} style={{marginRight: '10px'}}/> : <>
                  <AppHeaderDropdownMenuCheck style={{marginRight: '-4px'}}/>
                  <AppHeaderDropdownMenuCheck style={{marginRight: '5px'}}/>
                </>}
                            Mark as read
            </AppHeaderDropdownMenuItemButton>
          </AppHeaderDropdownMenuItem>
          {userDataLoaded && loaded ?
            <>
              {passwordResetRequired ?
                <AppHeaderDropdownMenuItem
                  margin="0 0 20px 0"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 18px',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  }}
                >
                  <NotificationElementTextBlock
                    style={{
                      textAlign: 'start',
                      whiteSpace: 'normal',
                      padding: '0',
                    }}
                    className="text-light white-text-1"
                  >
                                        Please update your password
                  </NotificationElementTextBlock>
                  {!successfulEmailSending && <Button
                    buttonText={'Update'}
                    tabIndex={0}
                    buttonStyle={{
                      border: 'none',
                      width: '100%',
                      padding: '2px 4px',
                    }}
                    wrapperStyle={{marginLeft: '20px'}}
                    onClick={passwordRegenerate}
                  />}
                </AppHeaderDropdownMenuItem> :
                (!emailVerified && <AppHeaderDropdownMenuItem
                  margin="0 0 20px 0"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 18px',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  }}
                >
                  <NotificationElementTextBlock
                    style={{
                      textAlign: 'start',
                      whiteSpace: 'normal',
                      padding: '0',
                    }}
                    className="text-light white-text-1"
                  >
                    {successfulEmailSending ? 'Please check your email' : `Please verify ${currentEmail} is your email`}
                  </NotificationElementTextBlock>
                  {!successfulEmailSending && <Button
                    buttonText={'Resend'}
                    tabIndex={0}
                    buttonStyle={{
                      border: 'none',
                      width: '100%',
                      padding: '2px 4px',
                    }}
                    wrapperStyle={{marginLeft: '20px'}}
                    activeLoader={resendButtonDisabled}
                    onClick={() => resendVerifyEmail().catch(console.error)}
                    isDisabledTooltip={false}
                    tooltipText="Your account isn’t verified yet"
                    tooltipDirection={TooltipDirection.Right}
                  />}
                </AppHeaderDropdownMenuItem>)}


              <div
                style={{maxHeight: '490px', overflowY: 'auto'}}
                className="custom-scroll-bar"
                id="visible-container"
              >
                {notifications.length > 0 &&
                                    notifications.map((notification) => (
                                      <NotificationElement
                                        key={notification.id}
                                        notification={notification}
                                        onClose={onClose}
                                        readNotification={readNotification}
                                        clockTimestamp={clockTimestamp}
                                        setClockTimestamp={setClockTimestamp}
                                        visible={visible}
                                      />
                                    ))}
                {(lastPage || notifications.length === 0) &&
                                    <NotificationElementTextBlock className="text-light white-text-1">
                                        You've got all notifications
                                    </NotificationElementTextBlock>}
              </div>
            </> : <ContainerLoader style={{margin: '4px 0'}} size={40}/>}
        </AppHeaderDropdownMenu>
      </AppHeaderDropdownWrapper>
    </>
  );
};

export default NotificationsDropdown;