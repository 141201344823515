import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAnchorWallet} from '@solana/wallet-adapter-react';
import {CToaster} from '@coreui/react';

// import BalanceDropdown from '../BalanceDropdown';
import WalletDropdown from '../WalletDropdown';
import AdminDropdown from '../AdminDropdown';
import AppHeaderDropdown from '../AppHeaderDropdown/AppHeaderDropdown';
// import DashboardAlphaKeyBlockToggle from '../DashboardAlphaKeyBlockToggle';
// import AlphaKeyForm from '../AlphaKeyForm';
import NotificationsDropdown from '../NotificationsDropdown/NotificationsDropdown';
import TimeLeft from './TimeLeft/TimeLeft';
import {Button} from '../../Buttons/Button';
import IconButton from '../../Buttons/RewardButton/IconButton';

import {useScreenSizeHook} from '../../../../hooks/useScreenSizeHook/useScreenSizeHook';
import CONTEXT from '../../../../context';

import {ROLE_NAMES} from '../../../../api/constants';
import {SCREEN_TYPES} from '../../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';
import * as localStorage from '../../../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../../../localStorage/types';

import StakingOnlineIcon from '../../../../assets/images/icons/staking-status-enabled.svg';
import StakingOfflineIcon from '../../../../assets/images/icons/staking-status-disabled.svg';
import LogoImage from '../../../../assets/images/common/header/BfLogo.webp';
import SteamIcon from '../../../../assets/images/SteamIcon.svg';

import {
  Container,
  HeaderContainer,
  LogoContainer,
  LogoLink,
  MenuContainer, PlayDemoWrapper,
  StakingStatus,
  StakingStatusContainer,
  StakingStatusIcon,
  WalletContainer,
  WalletDropdownContainer,
} from '../styles';

const AppHeader = () => {
  const {isSigned} = useContext(CONTEXT.GLOBAL.WALLET);
  // const {actionsDisabled} = useContext(CONTEXT.GLOBAL.STAKING);
  // const {
  //   dashboardAlphaKeyBlockToggleVisible,
  //   dashboardAlphaKeyAccessType
  // } = useContext(CONTEXT.COMPONENT.DASHBOARD.ALPHA_KEY);
  // const {loaded} = useContext(CONTEXT.COMPONENT.HEADER.FORM);

  const wallet = useAnchorWallet();
  const {screenType, width = 320} = useScreenSizeHook();

  const [adminDropdownDisplay, setAdminDropdownDisplay] = useState<boolean>(false);
  const [isTournamentPage, setIsTournamentPage] = useState<boolean>(false);

  const checkDropDown = async () => {
    if (window.roleNames.includes(ROLE_NAMES.ADMIN) || window.roleNames.includes(ROLE_NAMES.SUPPORT)) {
      setAdminDropdownDisplay(true);
    } else {
      setAdminDropdownDisplay(false);
    }
  };

  useEffect(() => {
    checkDropDown().catch(console.error);
  }, [window.loggedIn, window.roleNames]);

  useEffect(() => {
    if (window.loggedIn) {
      return setIsTournamentPage(false);
    }

    if (window.location.href.includes('/tournament-leaderboards')) {
      setIsTournamentPage(true);
    } else {
      setIsTournamentPage(false);
    }
  }, [window.loggedIn, window.location.href]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(LOCAL_STORAGE_NAMES.LEVEL_REWARDS);
    };
  }, []);

  return (
    <HeaderContainer className="header">
      <div style={{
        maxWidth: '2560px',
        width: '100%',
        position: 'relative',
        top: '0',
        right: '0',
        padding: '0 12px',
      }}>
        <Container className="container-fluid" screenType={screenType}>
          <LogoContainer>
            <LogoLink url={LogoImage} to={`${window.loggedIn ? '/dashboard' : '/login'}`}
              className="header-logo"/>

            {width > 950 && <TimeLeft/>}
          </LogoContainer>
          {window.stakingOpened && wallet && isSigned && (
            <WalletContainer xs={9}>
              {/*<StakingStatusContainer>*/}
              {/*  <StakingStatus {...{actionsDisabled}}>*/}
              {/*    {actionsDisabled*/}
              {/*      ? 'ReferralRewards calculation in progress'*/}
              {/*      : 'Staking online'}*/}
              {/*  </StakingStatus>*/}
              {/*  <StakingStatusIcon*/}
              {/*    src={actionsDisabled ? StakingOfflineIcon : StakingOnlineIcon}*/}
              {/*  />*/}
              {/*</StakingStatusContainer>*/}

              {/*<BalanceDropdown wallet={wallet}/>*/}
              <WalletDropdownContainer>
                <WalletDropdown/>
              </WalletDropdownContainer>
            </WalletContainer>
          )}

          <MenuContainer>
            {!window.stakingOpened && window.loggedIn && (
              <>
                <PlayDemoWrapper>
                  <IconButton
                    onClick={() => window.open('https://store.steampowered.com/app/1784650/BOSS_FIGHTERS/', '_blank')}
                    src={SteamIcon}
                    buttonText={'Play demo'}
                    iconStyle={{bottom: 0}}
                    screenType={screenType}
                  />
                </PlayDemoWrapper>
                {adminDropdownDisplay && <AdminDropdown/>}
                {/*{loaded && <>*/}
                {/*  <AlphaKeyForm/>*/}
                {/*  {(dashboardAlphaKeyBlockToggleVisible || dashboardAlphaKeyAccessType) &&*/}
                {/*                        <DashboardAlphaKeyBlockToggle/>}*/}
                {/*</>}*/}
                <NotificationsDropdown/>
                <AppHeaderDropdown/>
              </>
            )}
            <CToaster push={window.toast} id="toast-content-id"
              style={{maxWidth: `${screenType === SCREEN_TYPES.DESKTOP ? '600px' : 'auto'}`}}
            />
            {isTournamentPage &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                              <Button
                                buttonText="Sign In"
                                onClick={() => window.location.href = '/#/login'}
                                buttonStyle={{padding: '4px 8px'}}
                                wrapperStyle={{marginRight: '8px'}}
                              />
                              <Button
                                buttonText="Sign Up"
                                onClick={() => window.location.href = '/#/register'}
                                buttonStyle={{padding: '4px 8px'}}
                              />
                            </div>}
          </MenuContainer>
        </Container>
        {width <= 950 && <TimeLeft/>}
      </div>
    </HeaderContainer>
  );
};

export default AppHeader;
