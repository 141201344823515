import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Pass, {PassRarityType, PassRewardType} from '../../../types/api/Admin/Passes/Passes';

export type ItemTypes = 0 | 1 | 2;
const SearchItems = {
  0: 'avatars',
  1: 'game/inventories',
  2: 'collections',
};

export async function getListPasses(
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<Pass>>> {
  return makeAdminRequest('GET', `/api/admin/game/battle-passes?page=${page}&perPage=${perPage}`);
}

export async function updateBattlePass(battlePassId: string, rarityType: number, style: string) {
  return makeAdminRequest('POST', `/api/admin/game/battle-passes/${battlePassId}`, {rarityType, style});
}

export async function getPassRewardTypes(): Promise<AxiosResponse<PassRewardType[]>> {
  return makeAdminRequest('GET', '/api/admin/game/battle-passes/rewards/types');
}

export async function getPassesRarityTypes(): Promise<AxiosResponse<PassRarityType[]>> {
  return makeAdminRequest('GET', '/api/admin/game/battle-passes/rarity-types');
}

export async function searchForItems(type: ItemTypes, name: string) {
  return makeAdminRequest('GET', `/api/admin/${SearchItems[type]}/dropdown-results?name=${name}`);
}

export async function getTypesByIds(type: ItemTypes, ids: string[]): Promise<AxiosResponse<{
    id: string,
    name: string
}[]>> {
  return makeAdminRequest('POST', `/api/admin/${SearchItems[type]}/get-by-ids`, {ids});
}

export async function createBattlePassReward(battlePassId: string, type: number, goal: number, items: {
    id: string,
    value?: number
}[]) {
  return makeAdminRequest('POST', `/api/admin/game/battle-passes/${battlePassId}/rewards`, {
    type,
    goal,
    items,
  });
}

export async function updateBattlePassReward(battlePassId: string, battlePassRewardId: string, type: number, goal: number, items: {
    id: string,
    value?: number
}[]) {
  return makeAdminRequest('POST', `/api/admin/game/battle-passes/${battlePassId}/rewards/${battlePassRewardId}`, {
    type,
    goal,
    items,
  });
}

export async function deleteBattlePassReward(battlePassId: string, battlePassRewardId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/battle-passes/${battlePassId}/rewards/${battlePassRewardId}`);
}