import React, {CSSProperties, ReactChildren, ReactNode} from 'react';

import Tooltip, {TooltipDirection} from '../../Tooltip/Tooltip';
import Loader from '../../Loader/Loader';

import {ButtonWrapper, ButtonContent, SteamButtonIcon, ButtonText} from './styles';

interface IButton {
    isDisabledTooltip?: boolean;
    tooltipText?: string;
    isDisabledButton?: boolean;
    onKeyPress?: (e: React.KeyboardEvent<HTMLImageElement> | any) => void;
    buttonText?: string | JSX.Element;
    tabIndex?: number;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    wrapperClassName?: string;
    className?: string;
    wrapperStyle?: CSSProperties | undefined;
    tooltipClassName?: string;
    buttonStyle?: CSSProperties | undefined;
    id?: string;
    hidden?: boolean;
    children?: ReactNode | ReactChildren;
    icon?: ReactNode | ReactChildren;
    isSteamButton?: boolean;
    tooltipDirection?: TooltipDirection;
    lightBackground?: boolean;
    activeLoader?: boolean;
    type?: 'reset' | 'submit' | 'button';
}

export const Button = ({
  isDisabledTooltip = true,
  tooltipText = '',
  isDisabledButton,
  buttonText,
  onClick,
  tabIndex = 0,
  onKeyPress,
  wrapperClassName,
  className,
  wrapperStyle,
  tooltipClassName,
  buttonStyle,
  id,
  children,
  hidden = false,
  isSteamButton = false,
  icon,
  tooltipDirection = TooltipDirection.Bottom,
  lightBackground = true,
  activeLoader = false,
  type,
}: IButton) => {
  return (
    <ButtonWrapper tabIndex={-1} style={wrapperStyle}
      className={`text-bold white-text-1 ${wrapperClassName}`}>
      <Tooltip
        tabIndex={-1}
        text={tooltipText}
        direction={tooltipDirection}
        isDisabled={isDisabledTooltip || !tooltipText}
        tooltipClassName={tooltipClassName}
      >
        <ButtonContent
          hidden={hidden}
          onClick={onClick}
          tabIndex={tabIndex}
          disabled={isDisabledButton || activeLoader}
          onKeyPress={onKeyPress}
          className={className}
          style={buttonStyle}
          id={id}
          lightBackground={lightBackground}
          type={type}
        >
          <ButtonText activeLoader={activeLoader}>{buttonText}
            {activeLoader &&
                            <Loader size={20} borderWidth={3} style={{
                              marginLeft: '10px',
                              borderColor: 'white',
                              borderBottomColor: 'transparent',
                              alignSelf: 'center',
                            }}/>}
          </ButtonText>

          {isSteamButton ? <SteamButtonIcon/> : icon || <></>}
          {children}
        </ButtonContent>
      </Tooltip>
    </ButtonWrapper>
  );
};
