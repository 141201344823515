import styled from 'styled-components';

export const Title = styled.div`
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  text-align: center;

  margin-bottom: 2px;

  @media (max-width: 950px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Rank = styled(Title)`

`;

export const Description = styled.div`
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  text-align: center;

  @media (max-width: 950px) {
    font-size: 14px;
    line-height: 18px;
  }
`;