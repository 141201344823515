import React, {ReactNode, CSSProperties} from 'react';

import {ModalColumn, ModalRow, ModalText} from '../styles';

interface IField {
    title: string;
    isColumn?: boolean;
    isTextWithMargin?: boolean;
    children: ReactNode;
    style?: CSSProperties;
    titleStyle?: CSSProperties;
}

const Field = ({title, isColumn = true, isTextWithMargin = true, children, style, titleStyle}: IField) => {
  return (
    <>
      {isColumn ? (
        <ModalColumn style={style}>
          <ModalText marginBottom={isTextWithMargin ? 8 : 0} style={titleStyle}>
            {title}
          </ModalText>
          {children}
        </ModalColumn>
      ) : (
        <ModalRow justifyContent={isTextWithMargin ? 'space-between' : 'flex-start'} style={style}>
          <ModalText marginBottom={isTextWithMargin ? 8 : 0} style={titleStyle}>
            {title}
          </ModalText>
          {children}
        </ModalRow>
      )}
    </>
  );
};

export default Field;