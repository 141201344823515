import styled, {css} from 'styled-components';
import {TooltipDirection} from './Tooltip';

const tooltipStylesByDirection = (direction: TooltipDirection) => {
  const styles = {
    [TooltipDirection.Top]: css`
            left: 50%;
            bottom: calc(100% + 8px);
            transform: translateX(-50%);
        `,
    [TooltipDirection.Right]: css`
            top: 50%;
            left: calc(100% + 8px);
            transform: translateY(-50%);
        `,
    [TooltipDirection.Bottom]: css`
            left: 50%;
            top: calc(100% + 8px);
            transform: translateX(-50%);
        `,
    [TooltipDirection.Left]: css`
            top: 50%;
            right: calc(100% + 8px);
            transform: translateY(-50%);
        `,
  };
    // @ts-ignore
  return styles[direction];
};

const arrowStylesByDirection = (direction: TooltipDirection) => {
  const styles = {
    [TooltipDirection.Top]: css`
            height: 0.4rem;
            width: 0.8rem;
            left: 50%;
            bottom: -6px;
            transform: translateX(-50%);
        `,
    [TooltipDirection.Right]: css`
            width: 0.4rem;
            height: 0.8rem;
            left: -6px;
            top: 50%;
            transform: translateY(-50%);
        `,
    [TooltipDirection.Bottom]: css`
            height: 0.4rem;
            width: 0.8rem;
            left: 50%;
            top: -6px;
            transform: translateX(-50%);
        `,
    [TooltipDirection.Left]: css`
            width: 0.4rem;
            height: 0.8rem;
            right: -6px;
            top: 50%;
            transform: translateY(-50%);
        `,

  };
    // @ts-ignore
  return styles[direction];
};

const arrowIconStylesByDirection = (direction: TooltipDirection) => {
  const styles = {
    [TooltipDirection.Top]: css`
            border-width: 0 0.4rem 0.4rem;
            transform: rotate(180deg);
            border-bottom-color: #000;
            top: 0;
            left: 0;
        `,
    [TooltipDirection.Right]: css`
            border-width: 0.4rem 0 0.4rem 0.4rem;
            transform: rotate(180deg);
            border-left-color: #000;
            left: 0;
            top: 0;
        `,
    [TooltipDirection.Bottom]: css`
            border-width: 0 0.4rem 0.4rem;
            border-bottom-color: #000;
            top: 0;
            left: 0;
        `,
    [TooltipDirection.Left]: css`
            border-width: 0.4rem 0 0.4rem 0.4rem;
            border-left-color: #000;
            right: 0;
            top: 0;
        `,
  };
    // @ts-ignore
  return styles[direction];
};

export const TooltipContainer = styled.div<{ zIndex?: number }>`
    position: relative;

    width: 100%;

    z-index: ${({zIndex = 2}) => zIndex};

    ${({isDisabled, hideOnContentHover}: any) => css`
        ${!isDisabled &&
        css`
            &:hover > .tooltip-element {
                visibility: visible;
                opacity: 1;
                transition: all 0.15s linear;
            }

            ${hideOnContentHover &&
            css`
                &:hover > .tooltip-element:hover {
                    visibility: hidden;
                    opacity: 0;
                }
            `}
        `}
    `}
`;

export const TooltipElement = styled.div<{
    transparent?: boolean;
    direction: TooltipDirection;
}>`
    position: absolute;

    max-width: 200px;
    width: fit-content;

    background-color: ${({transparent = false}) => transparent ? 'transparent' : '#000'};
    border-radius: 4px;

    visibility: hidden;
    opacity: 0;
    transition: all 0.15s linear;

    padding: 6px 12px;

    ${({direction}) =>
    tooltipStylesByDirection(direction)};

    @media (max-width: 520px) {
        padding: 4px 8px;
    }
`;

export const TooltipArrow = styled.div`
    position: absolute;

    ${({direction}: { direction: TooltipDirection }) =>
    arrowStylesByDirection(direction)}
    &:before {
        border-style: solid;
        border-color: transparent;
        position: absolute;
        content: "";
        z-index: 2;
        ${({direction}: { direction: TooltipDirection }) =>
    arrowIconStylesByDirection(direction)}
    }
`;

export const TooltipTitleContent = styled.div`
    font-family: 'AGP Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    color: #FFFFFF;

    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    word-wrap: break-word;
    text-align: center;

    @media (max-width: 950px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const TooltipSubtitleContent = styled.div`
    font-family: 'AGP Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    color: #F6C000;

    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    word-wrap: break-word;
    text-align: center;

    @media (max-width: 950px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const TooltipContent = styled.div`
    font-family: 'AGP Light', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;

    color: #FFFFFF;

    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    word-wrap: break-word;

    @media (max-width: 950px) {
        font-size: 14px;
        line-height: 18px;
    }
`;
