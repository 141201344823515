import axios, {AxiosError, AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import CurrencyPair, {
  CurrencyIcon,
  ICurrencyPrice,
  IEditCurrencyPair, IEditCurrencyPriceState,
  IGrantState
} from '../../../types/api/Admin/Economy/Economy';
import ItemTypes from '../../../types/api/Admin/ItemTypes';
import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';
import error from '../../errors';

export async function getPairs(): Promise<AxiosResponse<CurrencyPair[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/currencies/pairs');
}

export async function getCurrencies(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/currencies');
}

export async function getIcons(): Promise<AxiosResponse<CurrencyIcon[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/currencies/icons');
}

export async function createPair({currencyFrom, currencyTo, amountFrom, amountTo}: IEditCurrencyPair) {
  return makeAdminRequest('POST', '/api/banking/admin/currencies/pairs', {currencyFrom, currencyTo, amountFrom, amountTo});
}

export async function updatePair({currencyFrom, currencyTo, amountFrom, amountTo}: IEditCurrencyPair) {
  return makeAdminRequest('POST', '/api/banking/admin/currencies/pairs/update', {currencyFrom, currencyTo, amountFrom, amountTo});
}

export async function deletePair(currencyFrom: number, currencyTo: number) {
  await makeAdminRequest('DELETE', '/api/banking/admin/currencies/pairs', {currencyFrom, currencyTo});
}

export async function setIcon(
  currency: number,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/currencies/${currency}/icons`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function grantCurrency({currency, amount, user}: IGrantState) {
  return makeAdminRequest('POST', '/api/banking/admin/currencies/grant', {currency, amount, userId: user?.value ?? ''});
}

export async function getCurrencyPrices(): Promise<AxiosResponse<ICurrencyPrice[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/currencies/prices');
}

export async function getAutoupdatedCurrencyPrices(): Promise<AxiosResponse<number[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/currencies/prices/autoupdated');
}

export async function setCurrencyPrices({
  currency, price, priceDecimals, priceChangeThreshold, priceChangeThresholdDecimals, disableAutoUpdate, force
}: IEditCurrencyPriceState) {
  return makeAdminRequest('POST', '/api/banking/admin/currencies/prices', {
    currency, price, priceDecimals, priceChangeThreshold, priceChangeThresholdDecimals, disableAutoUpdate, force
  });
}
