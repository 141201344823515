import React from 'react';

import {TableTd} from '../../pages/Admin/styles';
import CIcon from '@coreui/icons-react';
import {cilCheck, cilX} from '@coreui/icons';

export const timestamp = (timestamp: number) => {
  return (
    <TableTd>
      {new Date(timestamp).toLocaleString()}
    </TableTd>
  );
};


export const bool = (bool: boolean) => {
  return (
    <TableTd>
      {bool ? <CIcon icon={cilCheck} style={{color: 'green'}}/> : <CIcon icon={cilX} style={{color: 'red'}}/>}
    </TableTd>
  );
};
