import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import App from './App';
import reportWebVitals from './reportWebVitals';

Modal.setAppElement('#root');

ReactDOM.render(
  <StrictMode>
    <App/>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
