import React, {CSSProperties, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';

import LevelRewardTooltip from '../../../../../common/Tooltip/CustomeTypes/LevelRewardTooltip';
import Tooltip, {TooltipDirection} from '../../../../../common/Tooltip/Tooltip';
import Loader from '../../../../../common/Loader/Loader';

import {useScreenSizeHook} from '../../../../../../hooks/useScreenSizeHook/useScreenSizeHook';
import CONTEXT from '../../../../../../context';

import API from '../../../../../../api';
import {IReward} from '../../../../../../types/api/Reward';

import vector from '../../../../../../assets/images/icons/vector.svg';
import lock from '../../../../../../assets/images/icons/lock.svg';
import PreloadPicture from '../../../../../../assets/images/PreloadPicture.webp';

import {
  RewardContainer,
  RewardImage,
  RewardButton,
  RewardLevel,
  RewardBackground,
  DefaultRewardsContent,
  RewardProgress,
  BackgroundAnimation,
} from './styles';

interface ILevelReward {
    reward: IReward;
    tooltipDirection: TooltipDirection;
    isDisabledTooltip: boolean;
    tooltipClassName?: string;
    tooltipStyle?: CSSProperties;
    getLevelRewards: () => Promise<void>;
    styleRowIndex: number;
    rowIndex: number;
    rewardIndex: number;
    rows: IReward[][];
    parsedRow: IReward[];
    count: number;
    activeAnimation: boolean;
    setActiveAnimation: Dispatch<SetStateAction<boolean>>;
}

const LevelReward = ({
  reward,
  tooltipDirection = TooltipDirection.Bottom,
  isDisabledTooltip = true,
  tooltipClassName,
  tooltipStyle,
  getLevelRewards,
  styleRowIndex,
  rowIndex,
  rewardIndex,
  rows,
  parsedRow,
  count,
  activeAnimation,
  setActiveAnimation,
}: ILevelReward) => {
  const {
    userId,
    maxExperience,
    currentExperience,
    currentLevel,
  } = useContext(CONTEXT.GLOBAL.USER);
  const {width = 320} = useScreenSizeHook();

  const [claimButtonDisabled, setClaimButtonDisabled] = useState<boolean>(false);

  const isLastRow = rows.length === rowIndex + 1;
  const isFirstTypeFourthReward = rewardIndex % (count - 1) === 0 && rewardIndex !== 0;
  const isSecondTypeFourthReward = rewardIndex % (count - 1) === 0 && rewardIndex !== (count - 1);

  const claimReward = async (rewardId: string) => {
    if (!userId) {
      return;
    }

    const currentReward = document.getElementById(rewardId) as HTMLDivElement;
    if (!currentReward) {
      return;
    }

    setClaimButtonDisabled(true);
    try {
      await API.users.claimLevelReward(userId, rewardId);
      await getLevelRewards().catch(console.error);
      currentReward.style.transition = 'box-shadow 0.6s ease-in-out';
      requestAnimationFrame(() => {
        currentReward.style.boxShadow = 'none';
      });
      window.addToast('success', '', 'Reward claimed', 1500, '#1FBF42');
    } catch (e) {
      console.log(e);
      window.addToast('danger', 'Error', 'Reward claimed error');
    }
    setClaimButtonDisabled(false);
  };

  function getRewardExp(rewards: IReward[][], arrayIndex: number, rewardIndex: number) {
    const currentReward: IReward = rewards[arrayIndex][rewardIndex];

    if (!currentReward) {
      return;
    }

    if (currentReward.amount > currentLevel) {
      return currentReward.progress = 0;
    }

    let nextReward: IReward | undefined;
    nextReward = rewards[arrayIndex]?.find((reward, index) => {
      return index > rewardIndex && reward.amount > currentReward.amount;
    });
    if (nextReward && nextReward.amount <= currentLevel) {
      return currentReward.progress = 100;
    }

    if (!nextReward) {
      nextReward = rewards[arrayIndex + 1]?.find((reward) => {
        return reward.amount > currentReward.amount;
      });
      if (!nextReward) {
        return currentReward.progress = 0;
      }
    }

    const progress = Math.ceil(((((Math.abs(currentReward.amount - currentLevel)) * maxExperience) + currentExperience) / ((Math.abs(currentReward.amount - nextReward.amount)) * maxExperience) * 100));

    return currentReward.progress = progress;
  }

  useEffect(() => {
    getRewardExp(rows, rowIndex, rewardIndex);
  }, [rows]);

  return (
    reward.status !== 'FAKE' ?
      <RewardContainer
        key={reward.id}
        id={reward.id}
        count={count}
        type={reward.status}
        background={PreloadPicture}
        isAvailable={reward.status === 'READY_TO_CLAIM' || reward.status === 'CLAIMED'}
        onClick={() => {
          if (reward.status === 'READY_TO_CLAIM') {
            claimReward(reward.id).catch(console.error);
          }
        }}
      >
        <Tooltip
          tabIndex={-1}
          direction={tooltipDirection}
          isDisabled={isDisabledTooltip || !reward.amount}
          tooltipClassName={tooltipClassName}
          tooltipStyle={tooltipStyle}
          tooltipChildren={<LevelRewardTooltip
            title={reward.name}
            text={`Reach lvl ${reward.amount}`}
            type={reward.type}
          />}
          tooltipElementStyle={{
            width: '130%',
            top: '100%',
            left: '50%',
            transform: 'translate(-50%, 9px)',
          }}
          tooltipArrowStyle={{
            top: '-5px',
          }}
          tooltipTextStyle={{
            textAlign: 'center',
          }}
        >
          <BackgroundAnimation active={activeAnimation}>
            <RewardBackground
              src={reward.imageUrl}
              alt={reward.imageUrl}
              onLoad={() => setTimeout(() => setActiveAnimation(false), 1000)}
              scale={reward.type === 0 && reward?.inventoryType === 0 ? 1.3 : 1}
            />
            {reward.imageUrl && <>
              <RewardLevel className="text-bold white-text-1">{`Lvl ${reward.amount}`}</RewardLevel>
              {reward.status !== 'READY_TO_CLAIM' &&
                                <RewardImage type={reward.status}
                                  src={reward.status === 'CLAIMED' ? vector : lock}/>}
              {reward.status === 'READY_TO_CLAIM' &&
                                <RewardButton className="text-light white-text-1">
                                    Claim {claimButtonDisabled && <Loader size={18} borderWidth={3} style={{
                                    borderColor: 'white',
                                    borderBottomColor: 'transparent',
                                    marginLeft: '5px',
                                  }} />}
                                </RewardButton>
              }
            </>}
          </BackgroundAnimation>
        </Tooltip>
        {styleRowIndex === 1 ? (
          (isLastRow && isFirstTypeFourthReward) ? null :
            (!reward.isLastReward &&
                                <RewardProgress count={count} rewardIndex={rewardIndex} styleRowIndex={styleRowIndex}
                                  value={reward.progress || 0}
                                  max={maxExperience}/>)) :
          ((isLastRow && isSecondTypeFourthReward) ? null :
            (!reward.isLastReward &&
                            <RewardProgress count={count} rewardIndex={rewardIndex} styleRowIndex={styleRowIndex}
                              value={reward.progress || 0}
                              max={maxExperience}/>))}
      </RewardContainer> : <DefaultRewardsContent count={count} visible={reward.name !== 'FAKE-HIDE'}/>
  );
};

export default LevelReward;