import styled from 'styled-components';

export const ModalTitle = styled.h2<{ marginBottom?: number }>`
  position: relative;
  top: 0;
  left: 0;

  text-transform: uppercase;
  text-align: center;

  color: #FFA500;

  margin-bottom: ${({marginBottom = 20}) => marginBottom}px;

  @media (max-width: 520px) {
    margin-bottom: ${({marginBottom = 20}) => marginBottom / 2}px;
  };
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalRow = styled.div<{ marginBottom?: number, justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({justifyContent = 'space-between'}) => justifyContent};

  width: 100%;

  margin-bottom: ${({marginBottom = 16}) => marginBottom}px;

  @media (max-width: 520px) {
    margin-bottom: ${({marginBottom = 16}) => marginBottom / 2}px;
  };
`;

export const ModalRowEnd = styled(ModalRow)<{ marginBottom?: number }>`
  justify-content: flex-end;
`;

export const ModalColumn = styled(ModalRow)<{ marginBottom?: number }>`
  flex-direction: column;
`;

export const ModalText = styled.span<{ marginBottom?: number }>`
  align-self: start;

  color: #FFFFFF;

  margin-bottom: ${({marginBottom = 8}) => marginBottom}px;

  @media (max-width: 520px) {
    margin-bottom: ${({marginBottom = 8}) => marginBottom / 2}px;
  };
`;

export const UploadedImage = styled.img<{ margin?: string }>`
  width: 100px;
  height: auto;

  margin: ${({margin = '12px 0'}) => margin};
`;