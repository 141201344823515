import {useContext} from 'react';
import {useWallet} from '@solana/wallet-adapter-react';

import {deleteFCMToken} from '../../services/firebase/firebase';

import CONTEXT from '../../context';

import API from '../../api/index';
import * as localStorage from '../../localStorage';
import {cleanItems} from '../../localStorage';

import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../config';
import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';

const useLogout = () => {

  const wallet = useWallet();
  const {
    setCurrentAvatar,
    setUserId,
    setCurrentExperience,
    setCurrentLevel,
    setCurrentUsername,
  } = useContext(CONTEXT.GLOBAL.USER);
    // const {setDashboardAlphaKeyBlockToggleVisible} = useContext(CONTEXT.COMPONENT.DASHBOARD.ALPHA_KEY);

  const logout = async (userAction: boolean = false) => {
    await wallet.disconnect();
    await deleteFCMToken().catch(console.error);
    sessionStorage.removeItem(AUTH_SESSION_NAME);
    sessionStorage.removeItem(BANK_SESSION_NAME);
    cleanItems();
    if (userAction) {
      localStorage.removeItem(LOCAL_STORAGE_NAMES.SESSION_VAL);
      await API.users.logout();
    }
    setCurrentAvatar('');
    setCurrentExperience(0);
    setCurrentLevel(1);
    setCurrentUsername('');
    // setDashboardAlphaKeyBlockToggleVisible(false);
    setUserId('');
    window.refreshController.clearAll();
    window.setLoggedIn(false);
    window.location.href = '/login';
  };

  return logout;
};

export default useLogout;