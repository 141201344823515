import makeAdminRequest from '../../adminRequest';
import {AxiosResponse} from 'axios';
import {IRewardType} from '../../../types/api/Admin/ReferralRewards/RewardType';
import {IRewardAdmin} from '../../../types/api/Reward';
import PaginatedResponse from '../../../types/api/PaginatedResponse';

export async function getRewardTypes(): Promise<AxiosResponse<IRewardType[]>> {
  return makeAdminRequest('GET', '/api/admin/referral-rewards/types');
}

export async function list(page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<IRewardAdmin>>> {
  return makeAdminRequest('GET', `/api/admin/referral-rewards?page=${page}&perPage=${perPage}`);
}

export async function destroy(id: string) {
  return makeAdminRequest('DELETE', `/api/admin/referral-rewards/${id}`);
}

export async function create(itemType: number, amount: number, itemId: string) {
  return makeAdminRequest('POST', '/api/admin/referral-rewards', {itemType, amount, itemId});
}

export async function update(id: string, itemType: number, amount: number, itemId: string) {
  return makeAdminRequest('POST', `/api/admin/referral-rewards/${id}`, {itemType, amount, itemId});
}
