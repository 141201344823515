import React, {Dispatch, SetStateAction, useEffect} from 'react';

import SnakeRewards from './SnakeRewards/SnakeRewards';

import {useScreenSizeHook} from '../../../../hooks/useScreenSizeHook/useScreenSizeHook';

import {SCREEN_TYPES} from '../../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';
import {IReward} from '../../../../types/api/Reward';

import {MainTitle} from '../../../../pages/styles';
import {ModalBody} from './styles';

interface ILevelRewardsModalContent {
    onClose: () => void;

    levelRewards: IReward[];
    getLevelRewards: () => Promise<void>;
    activeAnimation: boolean;
    setActiveAnimation: Dispatch<SetStateAction<boolean>>;
}

const LevelRewardsModalContent = ({
  levelRewards,
  getLevelRewards,
  activeAnimation,
  setActiveAnimation,
}: ILevelRewardsModalContent) => {
  const {screenType, width = 320, height = 600} = useScreenSizeHook();

  useEffect(() => {
    getLevelRewards().catch(console.error);
  }, []);

  return (
    <>
      <MainTitle
        marginBottom={44}
        className="title-bold orange-text-1 text-align-center"
      >
                Level rewards
      </MainTitle>
      <ModalBody>
        <SnakeRewards levelRewards={levelRewards}
          count={screenType === SCREEN_TYPES.DESKTOP ? ((width < 950 && height < 595) ?
            Math.min(levelRewards.length, 3) : Math.min(levelRewards.length, 5)) : Math.min(levelRewards.length, 2)}
          getLevelRewards={getLevelRewards}
          activeAnimation={activeAnimation}
          setActiveAnimation={setActiveAnimation}
        />
      </ModalBody>
    </>
  );
};

export default LevelRewardsModalContent;
