import React, {ReactNode} from 'react';
import Modal from 'react-modal';

import Close from '../../assets/images/icons/close.svg';

import {CloseIcon} from './styles';
import './index.css';

interface IModalProps {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    id?: string;
    children: ReactNode;
    shouldCloseOnOverlayClick?: boolean
    withCloseIcon?: boolean
}

const MyModal = ({
  children,
  isOpen,
  onClose,
  className = '',
  id,
  shouldCloseOnOverlayClick = false,
  withCloseIcon = true,
}: IModalProps) => {
  return (
    <Modal
      id={id || 'my-modal-body'}
      className={`my-modal ${className}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={true}
      onRequestClose={onClose}
      isOpen={isOpen}
      closeTimeoutMS={300}
      bodyOpenClassName={'my-modal-body-open'}
    >
      {withCloseIcon && <CloseIcon onClick={onClose} src={Close}/>}
      {children}
    </Modal>
  );
};

export default MyModal;
