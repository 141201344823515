import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';

import CONTEXT from '../../../../context';
import {useScreenSizeHook} from '../../../../hooks/useScreenSizeHook/useScreenSizeHook';

import {SCREEN_TYPES} from '../../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';
import Notifications from '../../../../types/api/Auth/Notifications';

import PresentOrange from '../../../../assets/images/icons/presentOrange.svg';
import DigitalAssetsOrange from '../../../../assets/images/common/header/DigitalAssetsOrange.svg';
import bellSvg from '../../../../assets/images/icons/bell.svg';

import {
  NotificationElementData,
  NotificationElementLink,
  NotificationElementPicture,
  NotificationElementTextBlock,
  NotificationElementWrapper,
  RedCircular,
  RedCircularWrapper,
} from './styles';

export enum NOTIFICATION_TYPES {
    REFERRAL_REWARD_AVAILABLE,
    INVENTORY_ADDED,
    INVENTORY_REMOVED,
    ACHIEVEMENT_UNLOCKED,
    LEVEL_REWARD_AVAILABLE,
}

interface INotificationElement {
    notification: Notifications;

    onClose: () => void;
    readNotification: (notificationId: string) => void;
    clockTimestamp: number;
    setClockTimestamp: Dispatch<SetStateAction<number>>;
    visible: boolean;
}

let refreshTimeout: NodeJS.Timeout;
const REFRESH_TIMEOUT_VALUE = 60 * 1000; // 60 sec

const NotificationElement = ({
  notification,
  onClose,
  readNotification,
  clockTimestamp,
  setClockTimestamp,
  visible,
}: INotificationElement) => {
  const {setLevelRewardsVisible} = useContext(CONTEXT.GLOBAL.USER);
  const {screenType, width = 320, height = 600} = useScreenSizeHook();

  const [currentTimeValue, setCurrentTimeValue] = useState<string | number>();
  const [link, setLink] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [isIcon, setIsIcon] = useState<boolean>(false);

  const timestamp = (notificationTime: number) => {
    const diffS = Math.floor((clockTimestamp - notificationTime) / 1000);
    if (diffS <= 3 * 60) {
      setCurrentTimeValue('Now');
    } else if (diffS < 60 * 60) {
      setCurrentTimeValue(`${Math.floor(diffS / 60)}m ago`);
    } else if (diffS < 60 * 60 * 24) {
      setCurrentTimeValue(`${Math.floor(diffS / (60 * 60))}h ago`);
    } else {
      const value = new Date(notificationTime).toLocaleString();
      setCurrentTimeValue(value);
    }
    setRefresh();
  };

  const notificationsLink = (notificationsType: number, notificationsImage: string) => {
    if (notificationsType === NOTIFICATION_TYPES.REFERRAL_REWARD_AVAILABLE) {
      setLink('/referral-system');
      if (notificationsImage) {
        setImage(notificationsImage);
        setIsIcon(false);
      } else {
        setImage(PresentOrange);
        setIsIcon(true);
      }
    } else if (notificationsType === NOTIFICATION_TYPES.INVENTORY_ADDED || notificationsType === NOTIFICATION_TYPES.INVENTORY_REMOVED) {
      setLink('/digital-assets');
      if (notificationsImage) {
        setImage(notificationsImage);
        setIsIcon(false);
      } else {
        setImage(DigitalAssetsOrange);
        setIsIcon(true);
      }
    } else if (notificationsType === NOTIFICATION_TYPES.LEVEL_REWARD_AVAILABLE) {
      setLink('#');
      if (notificationsImage) {
        setImage(notificationsImage);
        setIsIcon(false);
      } else {
        setImage(PresentOrange);
        setIsIcon(true);
      }
    } else {
      setLink('/dashboard');
      if (notificationsImage) {
        setImage(notificationsImage);
        setIsIcon(false);
      } else {
        setImage(bellSvg);
        setIsIcon(true);
      }
    }
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && isVisible(entry.target)) {
        if (!notification.isRead) {
          readNotification(notification.id);
        }
        observer.unobserve(entry.target);
      }
    });
  });

  const isVisible = (element: Element) => {
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

    return !(rect.bottom < 0 || rect.top - viewHeight >= 0 || rect.right < 0 || rect.left >= window.innerWidth);
  };

  const setRefresh = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
    refreshTimeout = setTimeout(() => {
      setClockTimestamp(Date.now());
    }, REFRESH_TIMEOUT_VALUE);
  };

  useEffect(() => {
    if (screenType === SCREEN_TYPES.MOBILE || (height < 595 && width < 950)) {
      let observeId = document.getElementById(`${notification.id}-date`);
      if (observeId && visible) {
        observer.observe(observeId);
      }
    }
  }, [visible, screenType, width]);


  useEffect(() => {
    notificationsLink(notification.type, notification.image);
    return () => {
      clearTimeout(refreshTimeout);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      setClockTimestamp(Date.now());
    } else {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      timestamp(notification.createdAt);
    }
  }, [clockTimestamp]);

  return (
    <>
      <NotificationElementWrapper key={notification.id}>
        <NotificationElementLink
          to={link}
          onClick={notification.type === NOTIFICATION_TYPES.LEVEL_REWARD_AVAILABLE ? () => {
            setLevelRewardsVisible(true);
            onClose();
          } : onClose}
          onMouseOver={(e) => {
            if (screenType === SCREEN_TYPES.DESKTOP) {
              if (!notification.isRead) {
                e.preventDefault();
                readNotification(notification.id);
              }
            }
          }}
        >
          <NotificationElementTextBlock>
            <div style={{display: 'flex', position: 'relative'}}>
              {!notification.isRead &&
                                <RedCircularWrapper
                                  top={'50%'}
                                  left={'-10px'}
                                  style={{transform: 'translate(-50%,-50%)'}}>
                                  <RedCircular id={`${notification.id}-circle`}/>
                                </RedCircularWrapper>}
              <NotificationElementData className="text-bold white-text-1">
                {notification.text}
              </NotificationElementData>
            </div>
            <NotificationElementData
              style={{
                marginTop: '10px',
                textTransform: 'lowercase',
              }}
              id={`${notification.id}-date`}
              className="text-light white-text-3"
            >
              {currentTimeValue}
            </NotificationElementData>
          </NotificationElementTextBlock>
          <NotificationElementPicture
            type={notification.type}
            url={image}
            isIcon={isIcon}
          />
        </NotificationElementLink>
      </NotificationElementWrapper>
    </>
  );
};

export default NotificationElement;