import styled from 'styled-components';

export const MainTitleWithLine = styled.h2`
  position: relative;
  top: 0;
  left: 0;

  text-align: start;

  padding-bottom: 13px;
  margin-bottom: 24px;

  :before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background: #FFA500;
    bottom: 0;
    left: 0;
  }
`;

export const MainTitle = styled.h2<{ marginBottom: number }>`
  position: relative;

  text-transform: uppercase;

  margin-bottom: ${({marginBottom}) => marginBottom}px;

  @media (max-width: 520px) {
    margin-bottom: ${({marginBottom}) => marginBottom / 2}px;
  };
`;

export const Wrapper = styled.div`
  height: calc(100vh - 80px);

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 12px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 450px;
`;

export const WrapperForm = styled.form`
  all: inherit;

  margin: 0;
`;
