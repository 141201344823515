import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import ITransactions, {IFilterValues} from '../../../types/api/Admin/Transactions/Transactions';
import ItemTypes from '../../../types/api/Admin/ItemTypes';

export async function filterTransactions(
  page: number = 1, perPage: number = 20, {user, currency, transactionTypes}: IFilterValues
): Promise<AxiosResponse<PaginatedResponse<ITransactions>>> {
  return makeAdminRequest('POST', `/api/banking/admin/transactions/filter?page=${page}&perPage=${perPage}`, {
    userId: user?.value ?? '', currency, transactionTypes,
  });
}

export async function getTransactionTypes(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/transactions/types');
}