import makeRequest from '../../request';

export async function getFreeCapsuleId(walletId: string, collectionId: string) {
  return makeRequest(
    'GET',
    `/api/wallets/${walletId}/collections/${collectionId}/capsule-id`
  );
}

export async function getCapsules(
  walletId: string,
  page: number = 1,
  perPage: number = 20
) {
  return makeRequest(
    'GET',
    `/api/wallets/${walletId}/capsules?page=${page}&perPage=${perPage}`
  );
}

// capsuleId - UUID (00000000-0000-0000-0000-000000000000)
export async function executeCloseCapsule(
  walletId: string,
  capsuleId: string,
  transaction: string
) {
  return makeRequest(
    'DELETE',
    `/api/wallets/${walletId}/capsules/${capsuleId}`,
    {transaction},
    undefined,
    true
  );
}