import {AxiosResponse} from 'axios';

import makeRequest from '../../request';
import {ERROR_RESPONSES} from '../../errors';

import * as localStorage from '../../../localStorage';
import {cleanItems, getItem, setItem, setItems} from '../../../localStorage';
import {LOCAL_STORAGE_NAMES, LocalStorageTypes} from '../../../localStorage/types';

import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';

import SessionResponse from '../../../types/api/SessionResponse';
import MessageResponse from '../../../types/api/MessageResponse';
import SingIn from '../../../types/api/Auth/SingIn';
import LoginBySessionValue from '../../../types/api/Auth/LoginBySessionValue';
import SessionUserId from '../../../types/api/Auth/SessionUserId';
import ResponseRefreshLSData from '../../../types/api/Auth/ResponseRefreshLSData';
import ClaimAlphaKey from '../../../types/api/Auth/ClaimAlphaKey';
import DiscordStatus from '../../../types/api/Auth/DiscordStatus';
import AlphaKeyRequest from '../../../types/api/Auth/AlphaKeyRequest';
import SessionErrorResponse from '../../../types/api/SessionErrorResponse';
import {IReferral} from '../../../types/api/Referral';
import {IReward} from '../../../types/api/Reward';
import News from '../../../types/api/Auth/News';

export async function register(
  email: string,
  password: string,
  recaptcha: string,
  triggerId?: LocalStorageTypes | undefined,
  invite?: LocalStorageTypes | undefined,
): Promise<AxiosResponse<SessionResponse> | SessionErrorResponse> {
  const registerResponse: AxiosResponse<SessionResponse> | SessionErrorResponse = await makeRequest<SessionErrorResponse>(
    'POST',
    '/api/users',
    {
      email,
      password,
      triggerId,
      invite,
      recaptcha,
    },
    null,
    false,
    true,
  );
  parseLoginResponse(registerResponse, false);
  return registerResponse;
}

export async function visitInvite(invite: string): Promise<void> {
  makeRequest('POST', `/api/invite-codes/${invite}/visit`).catch(console.error);
}

function parseLoginResponse(
  response: AxiosResponse<SessionResponse | LoginBySessionValue> | SessionErrorResponse,
  storeSession: boolean,
): void {
  if ((response as SessionErrorResponse).code) {
    response = response as SessionErrorResponse;
    if (response.code && response.code === 'BF-9') {
      localStorage.removeItem(LOCAL_STORAGE_NAMES.SESSION_VAL);
      window.setRoleNames([]);
      sessionStorage.removeItem(AUTH_SESSION_NAME);
      sessionStorage.removeItem(BANK_SESSION_NAME);
      cleanItems();
      window.refreshController.clearAll();
      window.setLoggedIn(false);
      window.addToast(
        'danger',
        '',
        `${ERROR_RESPONSES[response.code]}`,
        0, '', false,
      );
    } else if (response.code && ERROR_RESPONSES[response.code]) {
      window.addToast(
        'danger',
        '',
        `${ERROR_RESPONSES[response.code]}`,
        0, '', false,
      );
    } else {
      window.addToast(
        'danger',
        '',
        `${response.message ? response.message : 'Login failed'}`,
        0, '', false,
      );
    }
    return;
  }
  response = response as AxiosResponse;

  sessionStorage.setItem(AUTH_SESSION_NAME, response.headers['authorization'] || '');
  setItem(LOCAL_STORAGE_NAMES.SESSION, response.headers['authorization'] || '');
  if (storeSession) {
    setItem(LOCAL_STORAGE_NAMES.SESSION_VAL, response.data.sessionValue || '');
  }
}

export async function login(
  email: string,
  password: string,
  storeSession: boolean = false,
  triggerId?: LocalStorageTypes | undefined,
): Promise<AxiosResponse<SessionResponse> | SessionErrorResponse> {
  const loginResponse: AxiosResponse<SessionResponse> | SessionErrorResponse = await makeRequest<SessionErrorResponse>(
    'POST',
    '/api/sessions',
    {
      email,
      password,
      triggerId,
      platform: 0,
    },
    null,
    false,
    true,
  );
  parseLoginResponse(loginResponse, storeSession);
  return loginResponse;
}

export async function loginBySessionValue(
  sessionValue: string,
): Promise<AxiosResponse<LoginBySessionValue> | SessionErrorResponse> {
  const loginResponse: AxiosResponse<LoginBySessionValue> | SessionErrorResponse = await makeRequest<SessionErrorResponse>(
    'POST',
    '/api/session-value',
    {platform: 0},
    {
      Authorization: sessionValue,
    },
    false,
    true,
  );
  parseLoginResponse(loginResponse, true);
  return loginResponse;
}

export async function autoLoginToken(
  autoLoginToken: string,
): Promise<AxiosResponse<SessionResponse> | SessionErrorResponse> {
  const autoLoginResponse: AxiosResponse<SessionResponse> | SessionErrorResponse = await makeRequest<SessionErrorResponse>(
    'POST',
    '/api/sessions/auto-login-token',
    {autoLoginToken: autoLoginToken, platform: 0},
    null,
    false,
    true,
  );
  parseLoginResponse(autoLoginResponse, false);
  return autoLoginResponse;
}

export async function logout(): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('DELETE', '/api/sessions', {platform: 0});
}

export async function get(id: string): Promise<AxiosResponse<SingIn>> {
  return makeRequest('GET', `/api/users/${id}`);
}

export async function getThisSessionUserId(ignoreAxiosError: boolean = false): Promise<string | undefined> {
  const response: AxiosResponse<SessionUserId> = await makeRequest(
    'GET',
    '/api/sessions/this',
    null,
    null,
    false,
    ignoreAxiosError,
  );
  if (response.status === 200) {
    return response.data.userId;
  }
}

export async function refreshLSData(
  returnItem: LOCAL_STORAGE_NAMES,
): Promise<LocalStorageTypes | undefined> {
  const response: AxiosResponse<ResponseRefreshLSData> = await makeRequest(
    'GET',
    `/api/users/${await getThisSessionUserId()}`,
  );
  if (response.status === 200) {
    setItems(response.data.account?.id, response.data?.roleNames);
    return getItem(returnItem);
  } else {
    window.location.href = '/';
  }
}

export async function verify(
  emailVerificationRequestId: string,
): Promise<void> {
  await makeRequest(
    'POST',
    `/api/users/email-verification-requests/${emailVerificationRequestId}/complete`,
  );
}

export async function forgotPasswordRequest(email: string): Promise<void> {
  await makeRequest('POST', '/api/users/forgot-password', {email});
}

export async function getForgotPasswordRequestExpiration(
  forgotPasswordRequestId: string,
): Promise<void> {
  const response: AxiosResponse<MessageResponse> = await makeRequest(
    'GET',
    `/api/users/forgot-password/${forgotPasswordRequestId}/expiration`,
  );
  if (response.status === 200) {
    return;
  }
}

export async function forgotPasswordChange(
  forgotPasswordRequestId: string,
  password: string,
): Promise<void> {
  await makeRequest(
    'POST',
    `/api/users/forgot-password/${forgotPasswordRequestId}/update`,
    {password},
  );
}

export async function changePassword(
  userId: string,
  currentPassword: string,
  password: string,
): Promise<void> {
  await makeRequest('POST', `/api/users/${userId}/change-password`, {
    currentPassword,
    password,
  });
}

export async function claimAlphaKey(
  userId: string,
): Promise<ClaimAlphaKey | undefined> {
  const response: AxiosResponse<ClaimAlphaKey> = await makeRequest(
    'GET',
    `/api/users/${userId}/alpha-key`,
  );
  if (response.status === 200) {
    return response.data;
  }
}

export async function getLinkTwitch(userId: string) {
  const response = await makeRequest('GET', `/api/users/${userId}/link-twitch`);

  if (response.status === 200) {
    return response.data.url;
  }
}

export async function deleteUnlinkTwitch(userId: string) {
  return await makeRequest('DELETE', `/api/users/${userId}/twitch`);
}

export async function getLinkDiscord(userId: string, connectionPage: string) {
  const response = await makeRequest('GET', `/api/users/${userId}/link-discord?component=${connectionPage}`);

  if (response.status === 200) {
    return response.data.url;
  }
}

export async function deleteUnlinkDiscord(userId: string) {
  return await makeRequest('DELETE', `/api/users/${userId}/discord`);
}

export async function getUserDiscordStatus(userId: string): Promise<AxiosResponse<DiscordStatus>> {
  return await makeRequest(
    'GET',
    `/api/users/${userId}/discord-status`,
  );
}

export async function getLinkOpenLoot(userId: string, connectionPage: string) {
  const response = await makeRequest('GET', `/api/users/${userId}/link-openloot?component=${connectionPage}`);

  if (response.status === 200) {
    return response.data.url;
  }
}

export async function deleteUnlinkOpenLoot(openlootUserId: string) {
  return await makeRequest('DELETE', `/api/openloot/${openlootUserId}`);
}

export async function getUserOpenLootStatus(): Promise<AxiosResponse<{ status: boolean, openlootUserId: string }>> {
  return await makeRequest(
    'GET',
    '/api/openloot/status',
  );
}

export async function changeEmail(newEmail: string, userId: string) {
  return await makeRequest('POST', `/api/users/${userId}/update-email-requests`, {
    newEmail: newEmail,
    userId: userId,
  });
}

export async function updateEmailRequestsStep1(updateEmailRequestId: string, userId: string) {
  return makeRequest('POST', `/api/users/${userId}/update-email-requests/${updateEmailRequestId}/step-1`);
}

export async function updateEmailRequestsStep2(updateEmailRequestId: string, verificationId: string, userId: string) {
  return await makeRequest('POST', `/api/users/${userId}/update-email-requests/${updateEmailRequestId}/step-2`, {
    verificationId: verificationId,
  });
}

export async function getAvatars(userId: string) {
  const response = await makeRequest('GET', `/api/users/${userId}/avatars/all`);
  if (response.status === 200) {
    return response.data;
  }
}

export async function updateAvatar(avatarId: string, userId: string) {
  return await makeRequest('POST', `/api/users/${userId}/avatars`, {avatarId: avatarId});
}

export async function updateNftAvatar(avatarId: string, userId: string) {
  return await makeRequest('POST', `/api/users/${userId}/nft-avatars/${avatarId}`);
}

export async function fcmToken(fcmToken: string, userId: string) {
  return await makeRequest('POST', `/api/users/${userId}/fcm-tokens`, {
    type: 'WEB',
    token: fcmToken,
  });
}

export async function resendVerifyEmail(userId: string): Promise<void> {
  await makeRequest(
    'POST',
    `/api/users/${userId}/email-verification-requests/resend`,
  );
}

export async function proceedAuthorizationTrigger(triggerId: LocalStorageTypes, userId: string) {
  return await makeRequest('POST', `/api/users/${userId}/proceed-authorization-trigger`, {
    triggerId: triggerId,
  });
}

export async function getAlphaKeyRequest(userId: string): Promise<AxiosResponse<AlphaKeyRequest>> {
  return await makeRequest(
    'GET',
    `/api/users/${userId}/alpha-key-request`,
  );
}

export async function submitForm(
  userId: string,
  contentCreator: string,
  link: string,
  device: string,
  age: string,
  gender: string,
  games: string[],
  country: string,
  foundUs: string,
): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/users/${userId}/alpha-key-request`, {
    contentCreator,
    link,
    device,
    age,
    gender,
    games,
    country,
    foundUs,
  });
}

export async function getReferralLink(userId: string): Promise<AxiosResponse<{ url: string }>> {
  return makeRequest('GET', `/api/users/${userId}/referral-system/link`);
}

export async function getReferrals(userId: string): Promise<AxiosResponse<IReferral[]>> {
  return makeRequest('GET', `/api/users/${userId}/referral-system/referrals`);
}

export async function getReferralRewards(userId: string): Promise<AxiosResponse<IReward[]>> {
  return makeRequest('GET', `/api/users/${userId}/referral-system/rewards`);
}

export async function claimReward(userId: string, referralRewardId: string): Promise<AxiosResponse<IReward[]>> {
  return makeRequest('POST', `/api/users/${userId}/referral-system/claim`, {referralRewardId});
}

export async function getNotifications(
  userId: string,
  page: number = 1,
  perPage: number = 20,
) {
  const response = await makeRequest('GET', `/api/users/${userId}/notifications?page=${page}&perPage=${perPage}`);
  if (response.status === 200) {
    return response;
  }
}

export async function readNotifications(userId: string, id: string[]) {
  await makeRequest('POST', `/api/users/${userId}/notifications/read`, {id});
}

export async function readAllNotifications(userId: string) {
  await makeRequest('POST', `/api/users/${userId}/notifications/read-all`);
}

export async function checkUnread(userId: string): Promise<AxiosResponse<{ unread: boolean }>> {
  return makeRequest('GET', `/api/users/${userId}/notifications/check-unread`);
}

export async function getAllAchievements(userId: string) {
  const response = await makeRequest('GET', `/api/users/${userId}/achievements`);
  if (response.status === 200) {
    return response.data;
  }
}

export async function getMissions(userId: string, type: number) {
  const response = await makeRequest('GET', `/api/users/${userId}/missions?type=${type}`);
  if (response.status === 200) {
    return response.data;
  }
}

export async function claimMission(userId: string, missionId: string) {
  return makeRequest('POST', `/api/users/${userId}/missions/${missionId}/claim`);
}

export async function getLevelRewards(userId: string): Promise<AxiosResponse<IReward[]>> {
  return makeRequest('GET', `/api/users/${userId}/level-rewards`);
}

export async function claimLevelReward(userId: string, levelRewardId: string): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/users/${userId}/level-rewards/${levelRewardId}/claim`);
}

export async function getNews(userId: string, page: number = 1, perPage: number = 20): Promise<AxiosResponse<News[]>> {
  return makeRequest('GET', `/api/users/${userId}/news?page=${page}&perPage=${perPage}`);
}

export async function reportLink(link: string): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `${link}`);
}

export async function regeneratePassword(regeneratePasswordRequestId: string, password: string) {
  return makeRequest('POST', `/api/regenerate-password-request/${regeneratePasswordRequestId}`, {password});
}

export async function signInWithTwitch() {
  const response = await makeRequest('POST', '/api/sessions/twitch');

  if (response.status === 200) {
    return response.data.url;
  }
}

