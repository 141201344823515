import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;

  align-self: center;
  justify-self: start;

  margin-left: 30px;

  @media (max-width: 1280px) {
    margin: 12px 0 0 0;
  }

  @media (max-width: 950px) {
    margin: 8px 0 4px 0;
  }
`;