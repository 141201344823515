import {ROLE_NAMES} from '../api/constants';
import Mission from '../types/api/Auth/Mission';
import Achievement from '../types/api/Auth/Achievement';
import {IReward} from '../types/api/Reward';

export type ConnectionUrl = string;

export type AccountId = string;

export type roleNames = ROLE_NAMES[];

export type Wallet = {
    id: string;
    userId: string;
    address: string;
    description: string;
    createdAt: number;
    updatedAt: number;
    lastAuthWay: string;
};
export type Wallets = Wallet[];

export type PeriodSetting = {
    collectionId: string;
    pollingPath: string;
};

export type PeriodSettings = PeriodSetting[];

export type SessionVal = string;
export type Session = string;
export type FcmToken = string;
export type SendToClaimPath = boolean;
export type UpdateEmailPath = string;
export type triggerId = string;
export type invite = string;
export type dashboardAlphaKeyBlock = {
    visible: boolean;
    userId: string;
};
export type discordConnectionPage = string;
export type openLootConnectionPage = string;
export type openLootUserId = string;
export type redirectUrl = string;
export type commonMissions = Mission[];
export type specialMissions = Mission[];
export type achievements = Achievement[];
export type levelRewards = IReward[];
export type sessionDestroyed = boolean;
export type connectOpenloot = boolean;
export type openBanner = boolean;
export type twoFAToken = string;

export type LocalStorageTypes =
    | AccountId
    | roleNames
    | Wallets
    | PeriodSettings
    | ConnectionUrl
    | SessionVal
    | Session
    | UpdateEmailPath
    | FcmToken
    | SendToClaimPath
    | triggerId
    | invite
    | dashboardAlphaKeyBlock
    | discordConnectionPage
    | openLootConnectionPage
    | openLootUserId
    | redirectUrl
    | commonMissions
    | specialMissions
    | achievements
    | levelRewards
    | sessionDestroyed
    | connectOpenloot
    | twoFAToken;

export enum LOCAL_STORAGE_NAMES {
    ACCOUNT_ID = 'accountId',
    ROLE_NAMES = 'roleNames',
    WALLETS = 'wallets',
    PERIOD_SETTINGS = 'periodSettings',
    CONNECTION_URL = 'connectionUrl',
    SESSION_VAL = 'sessionVal',
    SESSION = 'session',
    UPDATE_EMAIL_PATH = 'updateEmailPath',
    FCM_TOKEN = 'fcm_token',
    SEND_TO_CLAIM_PATH = 'sendToClaimPath',
    TRIGGER_ID = 'triggerId',
    INVITE = 'invite',
    DASHBOARD_ALPHA_KEY_BLOCK = 'dashboardAlphaKeyBlock',
    DISCORD_CONNECTION_PAGE = 'discordConnectionPage',
    OPEN_LOOT_CONNECTION_PAGE = 'openLootConnectionPage',
    OPEN_LOOT_USER_ID = 'openLootUserId',
    REDIRECT_URL = 'redirectUrl',
    COMMON_MISSIONS = 'commonMissions',
    SPECIAL_MISSIONS = 'specialMissions',
    ACHIEVEMENTS = 'achievements',
    LEVEL_REWARDS = 'levelRewards',
    SESSION_DESTROYED = 'sessionDestroyed',
    CONNECT_OPENLOOT = 'CONNECT_OPENLOOT',
    TWO_F_A_TOKEN = 'TWO_F_A_TOKEN',
}
