import React, {CSSProperties, ReactChildren, ReactNode} from 'react';
import {CContainer} from '@coreui/react';

import {Button} from '../../../common/Buttons/Button';

import {ModalFooter} from '../../styles';
import {MainTitle} from '../../../../pages/styles';

interface IActionConfirmationModalContent {
    onClose: () => void;
    actionConfirmation: () => Promise<void> | void;
    actionCancel?: () => Promise<void> | void;
    buttonsDisabled?: boolean;

    modalTitle: string;
    modalText: string;
    buttonTitle?: string;

    buttonStyle?: CSSProperties;

    children?: ReactNode | ReactChildren,
    closeModalAfterConfirmation?: boolean
    cancelButtonStyle?: CSSProperties
    withCancelButton?: boolean
}

const ActionConfirmationModalContent = ({
  onClose,
  actionConfirmation,
  actionCancel,
  buttonsDisabled,
  modalTitle,
  modalText,
  buttonTitle,
  buttonStyle,
  children,
  closeModalAfterConfirmation = true,
  cancelButtonStyle,
  withCancelButton = true,
}: IActionConfirmationModalContent) => {

  return (
    <CContainer fluid className="p-0">
      {modalTitle && <MainTitle
        marginBottom={44}
        className="title-bold orange-text-1 text-align-center"
      >
        {modalTitle}
      </MainTitle>}
      {modalText && <MainTitle
        marginBottom={44}
        className="text-light white-text-1 text-align-center"
      >
        {modalText}
      </MainTitle>}
      {children}
      <ModalFooter>
        {withCancelButton && <Button
          buttonText="Cancel"
          onClick={() => {
            try {
              if (actionCancel) {
                actionCancel();
              }
            } finally {
              onClose();
            }
          }}
          wrapperStyle={cancelButtonStyle ? cancelButtonStyle : {marginRight: '20px'}}
          buttonStyle={buttonStyle}
          isDisabledButton={buttonsDisabled}
        />}
        {buttonTitle && <Button
          buttonText={buttonTitle}
          buttonStyle={buttonStyle}
          onClick={() => {
            try {
              actionConfirmation();
            } finally {
              if (closeModalAfterConfirmation) {
                onClose();
              }
            }
          }}
          activeLoader={buttonsDisabled}
        />}
      </ModalFooter>
    </CContainer>
  );
};

export default ActionConfirmationModalContent;
