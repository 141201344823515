import React from 'react';
import {Navigate} from 'react-router-dom';

import {ROLE_NAMES} from '../api/constants';

import * as localStorage from '../localStorage';

function RequireRole({children, rolesRequired,}: { children: JSX.Element, rolesRequired: ROLE_NAMES[] | ROLE_NAMES }) {
  const userRoles = localStorage.getRolesSync();

  if (!userRoles) {
    return <Navigate to="/dashboard" replace/>;
  }

  rolesRequired = Array.isArray(rolesRequired) ? rolesRequired : [rolesRequired];

  for (const uRole of userRoles) {
    if (rolesRequired.includes(uRole)) {
      return children;
    }
  }

  return <Navigate to="/dashboard" replace/>;
}

export default RequireRole;
