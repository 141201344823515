import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';

// routes config
import {publicRoutes} from '../routes/routes';

import Loader from './common/Loader/Loader';

const PublicAppContent = () => {
  const loader = (
    <div className="loader-container">
      <Loader size={64} borderWidth={6}/>
    </div>
  );

  return (
    <Suspense fallback={loader}>
      <Routes>
        {publicRoutes.map(
          (route, idx) =>
            route.element && (
              <Route key={idx} path={route.path} element={<route.element/>}/>
            ),
        )}
      </Routes>
    </Suspense>
  );
};

export default React.memo(PublicAppContent);
