import axios, {AxiosError, AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';
import error from '../../errors';

import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';

import {IAttributes} from '../../../types/api/Admin/Game/Inventories';
import IInventoryTypes, {
  ImageFormatEdit,
  InventoryTypeValues,
  KindBulkAction
} from '../../../types/api/Admin/Game/InventoryTypes';
import IInventorySections from '../../../types/api/Admin/Game/InventorySections';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';

export async function getFilterInventoryTypes(
  {vr, visible, section, category}: InventoryTypeValues
): Promise<AxiosResponse<IInventoryTypes[]>> {
  return makeAdminRequest('POST', '/api/admin/game/inventory-types/filter', {
    vr: (vr && vr !== 'all') ? JSON.parse(vr) : undefined ?? undefined,
    visible: (visible && visible !== 'all') ? JSON.parse(visible) : undefined ?? undefined,
    section: section?.id,
    category: category?.id,
  });
}

export async function getInventoryTypes(): Promise<AxiosResponse<IInventoryTypes[]>> {
  return makeAdminRequest('GET', '/api/admin/game/inventory-types');
}

export async function getInventorySections(): Promise<AxiosResponse<IInventorySections[]>> {
  return makeAdminRequest('GET', '/api/admin/game/inventory-sections');
}

export async function getInventoryCategories(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/inventory-categories');
}

export async function deleteInventoryType(inventoryId: string) {
  await makeAdminRequest('DELETE', `/api/admin/game/inventory-types/${inventoryId}`);
}

export async function createInventoryType(
  name: string,
  section: number | null,
  category: number | null,
  internalName: string,
  filterName: string,
  isVr: boolean,
  visible: boolean,
  displayOrder?: number,
) {
  return await makeAdminRequest('POST', '/api/admin/game/inventory-types', {
    name: name,
    section: section,
    category: category,
    internalName: internalName,
    filterName: filterName,
    isVr: isVr,
    visible: visible,
    displayOrder: displayOrder,
  });
}

export async function updateInventoryType(
  inventoryTypeId: string,
  name: string,
  section: number | null,
  category: number | null,
  internalName: string,
  filterName: string,
  isVr: boolean,
  visible: boolean,
  displayOrder?: number,
) {
  return await makeAdminRequest('POST', `/api/admin/game/inventory-types/${inventoryTypeId}`, {
    name: name,
    section: section,
    category: category,
    internalName: internalName,
    filterName: filterName,
    isVr: isVr,
    visible: visible,
    displayOrder: displayOrder,
  });
}

export async function createInventory(
  isDefault: boolean,
  typeId: string,
  kindId: string | null,
  name: string,
  description: string,
  rarity: number,
  attributes: IAttributes[] | string,
): Promise<AxiosResponse<{ id: string }>> {
  return await makeAdminRequest('POST', '/api/admin/game/inventories', {
    isDefault: isDefault,
    typeId: typeId,
    kindId: kindId,
    name: name,
    description: description,
    rarity: rarity,
    attributes: attributes,
  });
}

export async function updateInventoryImage(
  inventoryId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/game/inventories/${inventoryId}/image`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function updateInventoryFullScaleImage(
  inventoryId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/game/inventories/${inventoryId}/full-scale-image`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function updateInventory(
  inventoryId: string,
  isDefault: boolean,
  typeId: string,
  kindId: string | null,
  name: string,
  description: string,
  rarity: number,
  attributes: IAttributes[] | string,
): Promise<AxiosResponse<{ id: string }>> {
  return await makeAdminRequest('POST', `/api/admin/game/inventories/${inventoryId}`, {
    isDefault: isDefault,
    typeId: typeId,
    kindId: kindId,
    name: name,
    description: description,
    rarity: rarity,
    attributes: attributes,
  });
}

export async function updateInventoryTypeImage(
  inventoryId: string,
  isImage: boolean,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/game/inventory-types/${inventoryId}/${isImage ? 'image' : 'icon'}`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function saveInventoryKind(id: string, name: string) {
  await makeAdminRequest('POST', `/api/admin/game/inventory-types/${id}/kinds`, {name});
}

export async function updateInventoryKind(id: string, kindId: string, name: string) {
  await makeAdminRequest('POST', `/api/admin/game/inventory-types/${id}/kinds/${kindId}`, {name});
}

export async function deleteInventoryKind(id: string, kindId: string,) {
  await makeAdminRequest('DELETE', `/api/admin/game/inventory-types/${id}/kinds/${kindId}`);
}

export async function getCompBulkActions(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/items-classes/bulk-actions');
}

export async function saveBulkAction({id, action, selectedItemId, kindId, replaceClassId}: KindBulkAction) {
  return makeAdminRequest('POST', '/api/admin/game/items-classes/inventory-type-bulk-actions', {
    classId: id, action, inventoryTypeId: selectedItemId, kindId, replaceClassId
  });
}

export async function setImagesFormat({id, imageFormatId, fullScaleImageFormatId}: ImageFormatEdit) {
  await makeAdminRequest('POST', `/api/admin/game/inventory-types/${id}/images-format`, {
    imageFormatId, fullScaleImageFormatId: fullScaleImageFormatId !== '-' ? fullScaleImageFormatId : undefined,
  });
}