import {Dispatch, SetStateAction} from 'react';

import {FcmController} from '../../services/firebase/FcmController';
import {setCookie} from '../../cookie';

import API from '../../api/index';

import * as localStorage from '../../localStorage';

const useLogin = () => {

  const login = async (response: any, setUserId: Dispatch<SetStateAction<string>>, setCurrentAvatar: Dispatch<SetStateAction<string>>) => {

    try {
      if (response.status === 200) {
        localStorage.setItems(response.data?.accountId, response.data?.roleNames);

        setUserId(response.data?.userId);
        setCurrentAvatar(response.data?.avatar);

        if (response.data.roleNames) {
          window.setRoleNames(response.data.roleNames);
        }
        const userResponse = await API.users.get(response.data.userId);
        if (userResponse.status === 200) {
          localStorage.setItems(undefined, undefined, userResponse.data?.wallets);
        }

        setCookie('acceptCookies', 'true');
        
        let fcmToken = localStorage.getFcmToken();
        if (!fcmToken && window.fcmController instanceof FcmController) {
          await window.fcmController.init();
          fcmToken = localStorage.getFcmToken();
        }

        if (fcmToken) {
          await API.users.fcmToken(fcmToken, response.data?.userId);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return login;
};

export default useLogin;