import {useEffect, useState} from 'react';

const useDetectKeyboardOpen = (minKeyboardHeight: number = 300, defaultValue: boolean = false) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(defaultValue);

  useEffect(() => {
    const listener = () => {
      if (window.visualViewport && window.visualViewport.height) {
        const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height;
        if (isKeyboardOpen !== newState) {
          setIsKeyboardOpen(newState);
        }
      }
    };

    if (window.visualViewport && typeof visualViewport !== 'undefined') {
      window.visualViewport.addEventListener('resize', listener);
    }

    return () => {
      if (window.visualViewport && typeof visualViewport !== 'undefined') {
        window.visualViewport.removeEventListener('resize', listener);
      }
    };
  }, [window.loggedIn, location.pathname]);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
