import React, {Suspense} from 'react';
import {useEffect, useMemo, useState} from 'react';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import {CToast, CToastClose, CToastHeader} from '@coreui/react';

import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {clusterApiUrl} from '@solana/web3.js';
import * as PhantomAdapter from '@solana/wallet-adapter-phantom';
import * as SolflareAdapter from '@solana/wallet-adapter-solflare';

import 'react-toastify/dist/ReactToastify.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import '@coreui/chartjs/dist/css/coreui-chartjs.min.css';

import Loader from './components/common/Loader/Loader';

import UserLayout from './components/layout/UserLayout';
import {UserProvider} from './context/userContext/userProvider';
import {TwoFAProvider} from './context/TwoFAProvider';
import useInitialLoad from './hooks/useInitialLoad/useInitialLoad';
import {UnsavedChangesProvider} from './context/unsavedChangesContext/UnsavedChangesProvider';
// import AlphaKeyFromProvider from './context/alphaKeyFromContext/AlphaKeyFromProvider';
// import DashboardAlphaKeyBlockProvider from './context/dashboardAlphaKeyBlockContext/DashboardAlphaKeyBlockProvider';
import {useFcmController} from './services/firebase/useFcmController';
import {refreshController} from './services/refresh/RefreshController';

import * as localStorage from './localStorage';
import {ROLE_NAMES} from './api/constants';
import {AUTH_SESSION_NAME} from './config';

import {CToastBodyContent} from './styles';
import 'rsuite/dist/rsuite.css';
import './scss/style.scss';
import './index.css';

const loading = (
  <div className="loader-container">
    <Loader size={64} borderWidth={6}/>
  </div>
);

export default function App() {
  const [toast, addToast] = useState<any>(null);
  const [roleNames, setRoleNames] = useState<ROLE_NAMES[]>(
    localStorage.getRolesSync() || [],
  );
  const session = localStorage.getSession();
  if (session) {
    sessionStorage.setItem(AUTH_SESSION_NAME, session);
  }
  const [loggedIn, setLoggedIn] = useState<boolean>(!!session || !!sessionStorage.getItem(AUTH_SESSION_NAME));
  const [stakingOpened, setStakingOpened] = useState<boolean>(false);
  const [twoFAModalVisible, setTwoFAModalVisible] = useState<boolean>(false);

  window.fcmController = useFcmController();
  window.refreshController = refreshController;
  window.loggedIn = loggedIn;
  window.setLoggedIn = setLoggedIn;
  window.twoFAModalVisible = twoFAModalVisible;
  window.setTwoFAModalVisible = setTwoFAModalVisible;
  window.stakingOpened = stakingOpened;
  window.setStakingOpened = setStakingOpened;
  window.roleNames = roleNames;
  window.setRoleNames = setRoleNames;
  window.toast = toast;
  window.addToast = (defaultColor: string, toastTitle: string, toastText: string, delay?: number, specialcolor?: string, autoHide?: boolean, id?: string) => {
    addToast((toastText || toastTitle) &&
            <CToast color={defaultColor} delay={delay || 2000} animation={true} visible={true} autohide={autoHide}
              id={id}>
              {toastTitle && <CToastHeader>{toastTitle}</CToastHeader>}
              <CToastClose/>
              {toastText && <CToastBodyContent specialcolor={specialcolor}>{toastText}</CToastBodyContent>}
            </CToast>);
  };
  const initialLoad = useInitialLoad();

  const network = WalletAdapterNetwork.Devnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(() => [new PhantomAdapter.PhantomWalletAdapter(), new SolflareAdapter.SolflareWalletAdapter()], [network]);

  useEffect(() => {
    initialLoad().catch(console.error);
  }, []);

  return (
    <UserProvider>
      <TwoFAProvider>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider autoConnect wallets={wallets}>
            {/*<AlphaKeyFromProvider>*/}
            {/*<DashboardAlphaKeyBlockProvider>*/}
            <UnsavedChangesProvider>
              <Router>
                <Suspense fallback={loading}>
                  <Routes>
                    <Route path="*" element={<UserLayout/>}/>
                  </Routes>
                </Suspense>
              </Router>
            </UnsavedChangesProvider>
            {/*</DashboardAlphaKeyBlockProvider>*/}
            {/*</AlphaKeyFromProvider>*/}
          </WalletProvider>
        </ConnectionProvider>
      </TwoFAProvider>
    </UserProvider>
  );
}
