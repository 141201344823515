import {createContext, Dispatch, SetStateAction} from 'react';


export const SignedWalletContext = createContext<{
    isSigned: boolean, setIsSigned: Dispatch<SetStateAction<boolean>>
}>({
  isSigned: false,
  setIsSigned: () => {
  },
});
