export enum TASK_STATUSES {
    CREATED = 'CREATED',
    STARTED = 'STARTED',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
};

export enum ROLE_NAMES {
    ADMIN = 'ADMIN',
    PLAYER = 'PLAYER',
    SUPPORT = 'SUPPORT',
};
