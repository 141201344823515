import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import IDisposableBadges, {IEditDisposableBadges} from '../../../types/api/Admin/DisposableBadges/DisposableBadges';

export async function getDisposableBadges(): Promise<AxiosResponse<IDisposableBadges[]>> {
  return makeAdminRequest('GET', '/api/admin/game/disposable-badges');
}

export async function createDisposableBadges({
  rarity, lifetimeMinutes, matchMinutesPerBadge, rewardMultiplier, rewardMultiplierDecimals
}: IEditDisposableBadges) {
  return makeAdminRequest('POST', '/api/admin/game/disposable-badges', {
    rarity, lifetimeMinutes, matchMinutesPerBadge, rewardMultiplier, rewardMultiplierDecimals
  });
}

export async function updateDisposableBadges({
  rarity, lifetimeMinutes, matchMinutesPerBadge, rewardMultiplier, rewardMultiplierDecimals
}: IEditDisposableBadges) {
  return makeAdminRequest('POST', '/api/admin/game/disposable-badges/update', {
    rarity, lifetimeMinutes, matchMinutesPerBadge, rewardMultiplier, rewardMultiplierDecimals
  });
}

export async function deleteDisposableBadges(rarity: number) {
  return makeAdminRequest('DELETE', `/api/admin/game/disposable-badges/${rarity}`);
}
