import styled, {css, keyframes} from 'styled-components';
import {ToastType} from './toastTypes';

const titleStyles = {
  [ToastType.TransactionInProgress]: css`
      color: #FFA500;
    `,
  [ToastType.TransactionFailed]: css`
      color: #E01C1C;
    `,
  [ToastType.TransactionCompleted]: css`
      color: #1FBF42;
    `,
  [ToastType.TransactionConfirmFailed]: css`
      color: #E01C1C;
    `,
  [ToastType.TransactionConfirmed]: css`
      color: #1FBF42;
    `,
  [ToastType.UndefinedText]: css`
      color: #FFA500;
    `,
};

const containerStyles = {
  [ToastType.TransactionInProgress]: undefined,
  [ToastType.TransactionFailed]: undefined,
  [ToastType.TransactionCompleted]: undefined,
  [ToastType.TransactionConfirmFailed]: css`
      border: 2px solid #E01C1C;
    `,
  [ToastType.TransactionConfirmed]: css`
      border: 2px solid #1FBF42;
    `,
  [ToastType.UndefinedText]: undefined,
};

const slide = keyframes`
  from {
    transform: translateX(-50px);
  }

  to {
    transform: translateX(100%);
  }
`;

const flexVerticalCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ToastContainer = styled.div`
  background: #141D29;
  cursor: default;
  padding: 24px;
  display: flex;
  border-radius: 5px;
  ${({toastType}: { toastType: ToastType }) => containerStyles[toastType]}
`;

export const ToastIconContainer = styled.div`
  ${flexVerticalCenter};
  margin-right: 23px;
`;

export const ToastIcon = styled.img`
`;

export const ToastInfoContainer = styled.div`
  ${flexVerticalCenter};
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h5`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.02em;
  font-family: "AGP Bold", sans-serif;
  margin: 0;
  ${({toastType}: { toastType: ToastType }) => titleStyles[toastType]}
`;

export const Subtitle = styled.span`
  font-size: 15px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
`;

export const LoaderContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 12px;
  margin-top: 16px;
  border-radius: 40px;
  background: rgb(217 217 217 / 5%);
`;

export const LoaderSliderContainer = styled.div`
  width: 100%;
  height: 12px;
  animation: ${slide} 1.6s linear infinite;
`;

export const LoaderSlider = styled.div`
  background: #FFA500;
  border-radius: 40px;
  width: 49px;
  height: 100%;
`;
