import * as users from './Client/Auth/users';
import * as wallets from './Client/Banking/wallets';
import * as collections from './Client/Banking/collections';
import * as assets from './Client/Banking/assets';
import * as capsules from './Client/Banking/capsules';
import * as accounts from './Client/Game/accounts';
import * as inventories from './Client/Game/inventories';
import * as tournaments from './Client/Game/tournaments';

import admin from './Admin/index';

import {getTask} from './pollTask';

const API = {
  accounts,
  admin,
  users,
  assets,
  collections,
  capsules,
  wallets,
  inventories,
  getTask,
  tournaments,
};

export default API;
