import React from 'react';

import {REWARD_TYPES} from '../../../../types/api/Admin/ReferralRewards/RewardType';

import {Rank, Title} from './styles';

interface ILevelRewardTooltip {
    title?: string;
    text?: string;

    type: REWARD_TYPES;
}

const rewardType = {
  [REWARD_TYPES.INVENTORY]: 'Skin',
  [REWARD_TYPES.AVATAR]: 'Avatar',
  [REWARD_TYPES.DISCORD_ROLE]: 'Discord-role',
  [REWARD_TYPES.FAKE]: 'Fake',
};

const LevelRewardTooltip = ({title, text, type}: ILevelRewardTooltip) => {
  return (
    <>
      {title && <Title className="text-bold orange-text-1">
        {`${title} ${rewardType[type] || ''}`}
      </Title>}
      {text && <Rank className="text-light white-text-1">{text}</Rank>}
    </>
  );
};

export default LevelRewardTooltip;