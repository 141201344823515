import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {NOTIFICATION_TYPES} from './NotificationElement';

export const NotificationElementWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  top: 0;
  left: 0;

  list-style: none;

  margin: 0 0 2px 0;

  transition: all 200ms ease-in-out;

  min-height: 96px;

  :hover {
    background: rgba(255, 255, 255, 0.1);

    transition: all 200ms ease-in-out;
  }
`;

export const RedCircularWrapper = styled.div<{ top: string, right?: string, left?: string }>`
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
`;

export const RedCircular = styled.div`
  width: 7px;
  height: 7px;

  background: red;

  border-radius: 50%;

  align-self: start;
  justify-self: start;
`;

export const NotificationElementTextBlock = styled.div`
  justify-self: start;
  width: 100%;
  min-width: 150px;
  white-space: break-spaces;
  word-wrap: break-word;

  color: rgb(255, 255, 255);

  margin: 0;
  padding: 0 18px;
`;

export const NotificationElementLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  text-decoration: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 5px 0;

  color: inherit;
`;

export const NotificationElementData = styled.span`
  display: block;

  text-decoration: none;
  background-color: transparent;
  border: 0;
  margin: 0;

  transition: all 200ms ease-in-out;

  ${NotificationElementWrapper}:hover & {
    color: #FFA500;

    transition: all 200ms ease-in-out;
  }

  ${NotificationElementWrapper}:active & {
    color: rgb(255, 255, 255);

    transition: all 200ms ease-in-out;
  }
`;

const notificationStylesType = (type: number) => {
  const styles = {
    [NOTIFICATION_TYPES.REFERRAL_REWARD_AVAILABLE]: css`

        `,
    [NOTIFICATION_TYPES.INVENTORY_ADDED]: css`
        `,
    [NOTIFICATION_TYPES.INVENTORY_REMOVED]: css`
        `,
  };
    // @ts-ignore
  return styles[type];
};

export const NotificationElementPicture = styled.div<{ type: number, url: string, isIcon: boolean }>`
  width: 57px;
  height: 45px;

  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;

  align-self: center;
  justify-self: end;

  border-radius: ${props => props.isIcon ? 'none' : '50%'};
  border: none;

  margin: 0 18px 0 0;

  ${({type}) =>
    notificationStylesType(type)}
`;