import styled from 'styled-components';

export const RewardsContainer = styled.div<{ count: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: 36px;

  width: ${({count}) => `calc(${160 * count}px + ${(count - 1) * 36}px)`};

  @media (max-width: 950px) {
    gap: 25px;

    width: ${({count}) => `calc(${160 * count}px + ${(count - 1) * 25}px)`};
  };

  @media (max-width: 520px) {
    width: ${({count}) => `calc(${110 * count}px + ${(count - 1) * 25}px)`};

    margin: 0 auto;
  };
`;