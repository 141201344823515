import styled from 'styled-components';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';

export const OverflowContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    max-width: 100%;

    border: none;

    padding: 0;
    margin: 0;

    overflow-x: auto;
    overflow-y: hidden;
`;

export const WrapperTable = styled.table`
    width: 100%;
    max-width: 100%;

    border: none;
`;

export const TableTh = styled.th`
    vertical-align: middle;
    text-align: center;

    border: none;

    background: rgba(255, 174, 0, .8);

    padding: 5px 10px;
`;

export const TableTr = styled.tr<{ active?: boolean, index?: number, maxIndex?: number }>`
    position: relative;
    z-index: ${({index = 1, maxIndex = 100}) => maxIndex - index};

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    :hover {
        background: ${({active = false}) => active ? 'rgba(255, 174, 0, .3)' : 'none'};
    }
`;

export const TableTd = styled.td<{ fieldType?: number }>`
    vertical-align: middle;
    text-align: center;

    border: none;

    padding: 4px;

    background-color: ${({fieldType}) => {
    if (fieldType === 2) {
      return '#c5b383';
    } else if (fieldType === 1) {
      return '#d5b2b0';
    } else {
      return 'none';
    }
  }};
`;

export const TableHead = styled.thead`
    padding: 10px 0;
`;

export const TableBody = styled.tbody`

`;

export const SearchElementsWrapper = styled.div<{ screenType: SCREEN_TYPES }>`
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 16px;

    margin-top: 8px;
    padding: 0;

    flex-direction: ${(props) =>
    props.screenType === SCREEN_TYPES.DESKTOP ? 'row' : 'column'};

    @media (max-height: 600px) {
        @media (max-width: 950px) {
            flex-direction: column;
        }
    }
`;

export const ImageUrl = styled.img`
    width: 64px;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
`;
