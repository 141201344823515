import styled from 'styled-components';
import {ReactComponent as SteamIcon} from '../../../../assets/images/common/Button/SteamIcon.svg';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
`;

export const ButtonContent = styled.button<{ lightBackground?: boolean }>`
  background: ${({lightBackground = true}) => lightBackground ? '#FFA500' : 'rgba(255, 255, 255, 0.05)'};

  backdrop-filter: blur(25px);
  color: #FFFFFF;

  border-radius: 3px;
  border: none;

  padding: 14px 32px;

  transition-duration: 0.6s;

  text-transform: uppercase;

  :hover {
    background: ${({lightBackground = true}) => lightBackground ? '#D29002' : '#FFA500'};

    opacity: ${({lightBackground = true}) => lightBackground ? 0.8 : 1};

    transition-duration: 0.6s;
  }

  :disabled {
    background-color: #D29002;

    opacity: 0.5;
  }
`;

export const SteamButtonIcon = styled(SteamIcon)`
  width: 25px;
  height: 25px;

  padding: 0;
  margin: 0 0 1px 10px;

  fill: #FFFFFF;

  :disabled {
    opacity: 0.5;
  }
`;

export const ButtonText = styled.span<{ activeLoader: boolean }>`
  display: ${({activeLoader = false}) => activeLoader ? 'flex' : 'inline'};
`;