import styled, {css} from 'styled-components';

export const CloseIcon = styled.img`
    ${({isLoading}: any) => css`
        position: absolute;
        right: 33px;
        top: 33px;
        z-index: 3;
        cursor: ${isLoading ? 'default' : 'pointer'};
        opacity: 0.75;

        :hover {
            opacity: ${isLoading ? '0.75' : '1'};
        }

        @media (max-width: 520px) {
            right: 20px;
            top: 20px;
        }
    `}
`;

export const ModalHeader = styled.div`
    ${({isStaked}: any) => css`
        margin-bottom: ${isStaked ? '35px' : '57px'};
    `}
`;

export const Subtitle = styled.p`
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.8;
`;

export const Container = styled.div`
    width: 410px;

    @media (max-width: 700px) {
        width: 310px;
    }

    @media (max-width: 450px) {
        width: 100%;
    }
`;

export const WrapperTable = styled.table<{ marginBottom?: number }>`
    width: 100%;
    max-width: 100%;

    border: none;

    margin-bottom: ${({marginBottom = 20}) => marginBottom}px;

    @media (max-width: 520px) {
        margin-bottom: ${({marginBottom = 20}) => marginBottom / 2}px;
    };
`;

export const TableTr = styled.tr`
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    @media (max-width: 500px) {
        overflow-wrap: anywhere;
    }
`;

export const TableTd = styled.td`
    vertical-align: middle;
    text-align: center;

    padding: 10px 0;
`;

export const TableBody = styled.tbody`

`;

export const ModalBody = styled.div`
    position: relative;
    top: 0;
    left: 0;
  
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;