import {Dispatch, SetStateAction, useContext} from 'react';

import CONTEXT from '../../context';

import API from '../../api/index';

const useLoadUserId = () => {
  const {saveUserId} = useContext(CONTEXT.GLOBAL.USER);

  const loadUserId = async (setUserIdLoaded: Dispatch<SetStateAction<boolean>>, ignoreAxiosError: boolean = false) => {
    try {
      const userId = await API.users.getThisSessionUserId(ignoreAxiosError);
      if (userId) {
        await saveUserId(userId);
        setUserIdLoaded(true);
      } else {
        setUserIdLoaded(false);
      }
    } catch (err) {
      console.log(err);
      setUserIdLoaded(false);
    }
  };

  return loadUserId;
};

export default useLoadUserId;