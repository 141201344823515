/* eslint-disable @typescript-eslint/no-this-alias */

import firebase from 'firebase/compat';
import {requestFCMToken} from './firebase';

import {getFcmToken, setItem} from '../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';
import * as localStorage from '../../localStorage';

import API from '../../api/index';

import MessagePayload = firebase.messaging.MessagePayload;

export enum FCM_TYPES {
    ASSET_LIST_CHANGED = 'ASSET_LIST_CHANGED',
    INVENTORY_LIST_CHANGED = 'INVENTORY_LIST_CHANGED',
    SESSION_DESTROYED = 'SESSION_DESTROYED',
    AVATAR_UPDATED = 'AVATAR_UPDATED',
    ALPHA_KEY_GRANTED = 'ALPHA_KEY_GRANTED',
    AUTO_DLC_CHANGED = 'AUTO_DLC_CHANGED',
    NEW_NOTIFICATION = 'NEW_NOTIFICATION',
    NOTIFICATION_UPDATED = 'NOTIFICATION_UPDATED',
    REFERRAL_SYSTEM_UPDATE = 'REFERRAL_SYSTEM_UPDATE',
    EMAIL_VERIFIED = 'EMAIL_VERIFIED',
    ACHIEVEMENT_UNLOCKED = 'ACHIEVEMENT_UNLOCKED',
    MISSION_STATUS_UPDATE = 'MISSION_STATUS_UPDATE',
    MISSIONS_LIST_UPDATED = 'MISSIONS_LIST_UPDATED',
    LEVEL_REWARDS_UPDATE = 'LEVEL_REWARDS_UPDATE',
    EXP_GAINED = 'EXP_GAINED',
    GROUP_FCM = 'GROUP_FCM',
}

export type FCM = {
    type: FCM_TYPES,
    data: any
}

type ListenerFunc = (fcm: FCM) => Promise<void>;

type EventListeners = {
    [key: string]: ListenerFunc;
}

let listeners: EventListeners = {};
let fcmController: FcmController;

export class FcmController {
  ready: boolean = false;
  permission: boolean = false;

  constructor() {
    if (fcmController) {
      return fcmController;
    }

    fcmController = this;
  }

  async init() {
    await this.requestPermission();
    if (!this.permission) {
      return;
    }
    const localToken = getFcmToken();
    const token = await requestFCMToken(this.onMessage);
    const session = localStorage.getSession();

    if (!localToken && !token) {
      return;
    }
    if (localToken !== token) {
      setItem(LOCAL_STORAGE_NAMES.FCM_TOKEN, token);
      if (session) {
        const userId = await API.users.getThisSessionUserId();
        if (!userId) {
          return;
        }
        await API.users.fcmToken(token, userId);
      }
    }
    this.ready = true;
  }

  async requestPermission() {
    if (!('Notification' in window)) {
      this.permission = false;
      return;
      // This browser does not support notification
    }

    await Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.permission = true;
      } else {
        this.permission = false;
      }
    });
  }

  setEventListener(type: FCM_TYPES, listener: ListenerFunc) {
    if (!Object.values(FCM_TYPES).includes(type)) {
      throw new Error('Unknown type');
    }

    listeners[type] = listener;
  }

  removeEventListener(type: FCM_TYPES) {
    if (!Object.values(FCM_TYPES).includes(type)) {
      throw new Error('Unknown type');
    }

    if (!listeners[type]) {
      return;
    }

    delete listeners[type];
  }

  async proceed(fcm: FCM): Promise<void> {
    if (!fcm.type) {
      return;
    }
    if (listeners && Object.keys(listeners).includes(fcm.type)) {
      const copyFcm = JSON.parse(JSON.stringify(fcm));
      listeners[fcm.type](copyFcm).catch(console.error);
    }
  }

  onMessage = async (message: MessagePayload): Promise<void> => {
    if (!message.data) {
      return;
    }
    if (!message.data.type) {
      return;
    }

    const fcm: FCM = {
      type: message.data.type as FCM_TYPES,
      data: JSON.parse(message.data.data),
    };

    console.log(fcm.type, ' - FCM_TYPES');
    console.log(fcm.data, ' - FCM_DATA');

    if (fcm.type === FCM_TYPES.GROUP_FCM) {
      const promises = [];

      for (const currentFcm of fcm.data as FCM[]) {
        promises.push(this.proceed(currentFcm));
      }

      try {
        await Promise.allSettled(promises);
      } catch (e) {
        console.log(e);
      }

      return;
    }

    if (fcm.type !== FCM_TYPES.NOTIFICATION_UPDATED) {
      window.addToast('success', fcm.data?.title, fcm.data?.text, 2000, '#1FBF42');
    }

    this.proceed(fcm).catch(console.error);
  };
}

fcmController = new FcmController();