import React, {useState} from 'react';
import {useWallet} from '@solana/wallet-adapter-react';

import LogoutIcon from '../../../assets/images/icons/logout.png';

import {
  DropdownContainer,
  DropdownContent,
  DropdownContentItem,
  DropdownItemIcon,
  WalletAddress,
  WalletDropdownButton,
  WalletSymbol,
} from './styles';

const WalletDropdown = () => {
  const {disconnect, publicKey, wallet} = useWallet();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {icon} = wallet?.adapter || {};

  const walletLogout = async () => {
    await disconnect();
  };

  return (
    <DropdownContainer>
      <WalletDropdownButton
        onClick={() => setIsOpen(!isOpen)}
        className="button button__wallet-dropdown"
      >
        <WalletSymbol src={icon}/>
        <WalletAddress className="text-white-1 fz-3">
          {publicKey?.toBase58()}
        </WalletAddress>
      </WalletDropdownButton>
      {isOpen && (
        <DropdownContent id="wallet-dropdown-content">
          <DropdownContentItem onClick={walletLogout}>
            <DropdownItemIcon src={LogoutIcon}/>
            <span className="text-white-1 fz-3">Logout</span>
          </DropdownContentItem>
        </DropdownContent>
      )}
    </DropdownContainer>
  );
};

export default WalletDropdown;
