import React, {useState, useEffect, Dispatch, SetStateAction} from 'react';

import RowRewards from './RowRewards/RowRewards';
import ContainerLoader from '../../../../common/Loader/ContainerLoader';

import {useScreenSizeHook} from '../../../../../hooks/useScreenSizeHook/useScreenSizeHook';

import {IReward} from '../../../../../types/api/Reward';
import {REWARD_TYPES} from '../../../../../types/api/Admin/ReferralRewards/RewardType';
import * as localStorage from '../../../../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../../../../localStorage/types';

import {RewardsContainer} from './styles';
import './index.css';

export const DEFAULT_REWARD_HIDE: IReward = {
  id: '',
  amount: 1,
  type: REWARD_TYPES.AVATAR,
  imageUrl: '',
  status: 'FAKE',
  name: 'FAKE-HIDE',
};

interface ISnakeRewards {
    levelRewards: IReward[];
    getLevelRewards: () => Promise<void>;
    count: number;
    activeAnimation: boolean;
    setActiveAnimation: Dispatch<SetStateAction<boolean>>;
}

const SnakeRewards = ({
  levelRewards,
  getLevelRewards,
  count,
  activeAnimation,
  setActiveAnimation,
}: ISnakeRewards) => {
  const {width = 320} = useScreenSizeHook();

  const levelRewardsValue = localStorage.getItem(LOCAL_STORAGE_NAMES.LEVEL_REWARDS) as IReward[];

  const [rows, setRows] = useState<IReward[][]>();
  const [loaded, setLoaded] = useState<boolean>(Boolean(levelRewardsValue));

  useEffect(() => {
    const numberOfRows = Math.ceil(levelRewards.length / count);
    const currentRows = Array.from({length: numberOfRows}, (_, index) =>
      levelRewards.slice(index * count, (index + 1) * count),
    );

    const lastSubArray = currentRows[currentRows.length - 1];
    const lastReward = lastSubArray[lastSubArray.length - 1];
    lastReward.isLastReward = true;

    setRows(currentRows);

    setLoaded(true);
  }, [levelRewards, width]);

  return (
    <RewardsContainer count={count}>
      {loaded ?
        rows?.map((row, rowIndex) => (
          <RowRewards
            key={rowIndex}
            rows={rows}
            row={row}
            rowIndex={rowIndex}
            count={count}
            getLevelRewards={getLevelRewards}
            levelRewardsValue={levelRewardsValue}
            activeAnimation={activeAnimation}
            setActiveAnimation={setActiveAnimation}
          />)) : <ContainerLoader size={40}/>}
    </RewardsContainer>
  );
};

export default SnakeRewards;


