import React, {useEffect, useState} from 'react';

import Loader from '../../../../../common/Loader/ContainerLoader'

import API from '../../../../../../api';
import UserInfoById from '../../../../../../types/api/Admin/Users/UserInfoById';
import IUserTournament, {IUserTournamentNomination} from '../../../../../../types/api/Admin/Users/UserTournament';

import {
  OverflowContainer,
  TableBody,
  TableHead,
  TableTd,
  TableTh,
  TableTr,
  WrapperTable,
} from '../../../../../../pages/Admin/styles';
import {ModalWrapper, ModalTitle, ModalRow, ModalText} from '../../../../ModalUtils/styles';

interface IUserTournaments {
    userId: string;
    userInfo: UserInfoById
    userTournaments: IUserTournament
}

const UserTournaments = ({userInfo, userId, userTournaments}: IUserTournaments) => {
  const [userNominations, setUserNominations] = useState<IUserTournamentNomination[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const getData = async () => {
    const response = await API.admin.tournaments.getUserTournamentNominations(userId, userTournaments.id);
    if (!response) {
      return
    }
    setUserNominations(response.data);
    setLoaded(true)
  }

  useEffect(() => {
    getData().catch(console.error);
  }, []);

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold" marginBottom={8}>{userInfo?.username || 'Username'}</ModalTitle>
      <ModalText marginBottom={20} className="title-bold"
        style={{width: '100%', textAlign: 'center'}}>
        {userTournaments.name || 'Tournament Name'}
      </ModalText>

      {loaded ? <ModalRow marginBottom={0} style={{alignItems: 'flex-start'}}>
        <OverflowContainer>
          <WrapperTable>
            <TableHead>
              <TableTr>
                <TableTh>Nomination Name</TableTh>
                <TableTh>Score</TableTh>
                <TableTh>Place</TableTh>
              </TableTr>
            </TableHead>
            <TableBody>
              {userNominations.map((nomination, index) => (
                <TableTr key={nomination.name || index.toString()} active={true}>
                  <TableTd>{nomination.name || '-'}</TableTd>
                  <TableTd>{nomination.score || 0}</TableTd>
                  <TableTd>{nomination.place > nomination.participants ? 'N/P' : `${nomination.place || 0} / ${nomination.participants || 0}`}</TableTd>
                </TableTr>
              ))}
            </TableBody>
          </WrapperTable>
        </OverflowContainer>
      </ModalRow> : <Loader/>}
    </ModalWrapper>
  );
};

export default UserTournaments;
