import {AxiosError} from 'axios';
import {toast} from 'react-toastify';

import {cleanItems} from '../../localStorage';
import {
  BadRequest,
  Forbidden,
  ManyRequests,
  RequestProceedError,
  ServerInternalError,
  Unauthorized,
  UnknownError,
  OpenlootAccessExpiredError,
  TwoFAError,
} from './errors';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../config';

const STATUSES = {
  INTERNAL_SERVER_ERROR: 500,
  MANY_REQUESTS: 429,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
};

export const ERROR_RESPONSES: { [key: string]: string } = {
  'BF-1': 'User with such email already exists',
  'BF-2': 'Email validation failed',
  'BF-3': 'Password is too weak',
  'BF-4': 'User with such email not found',
  'BF-5': 'Wrong credentials',
  'BF-9': 'Your auto login data has expired. Please log in again',
  'BF-12': 'Open Loot request failed. Please try again in few seconds',
  'code': 'TWO_F_A_REQUIRED',
};

const EXCEPTIONS_FOR_ERROR = ['Sign verification failed'];

function getError(status: number, message?: string, code?: string) {
  switch (status) {
  case STATUSES.INTERNAL_SERVER_ERROR:
    if (status === 500 && code === 'OPENLOOT_ACCESS_EXPIRED') {
      return new OpenlootAccessExpiredError(status, message, code);
    }
    return new ServerInternalError(status, message, code);
  case STATUSES.MANY_REQUESTS:
    window.addToast(
      'danger',
      '',
      `${message}`,
      1500,
    );
    return new ManyRequests(status, message);
  case STATUSES.UNAUTHORIZED:
    toast.dismiss();
    window.setRoleNames([]);
    sessionStorage.removeItem(AUTH_SESSION_NAME);
    sessionStorage.removeItem(BANK_SESSION_NAME);
    cleanItems();
    window.refreshController.clearAll();
    window.setLoggedIn(false);
    window.location.href = '/#/login';
    return new Unauthorized(status, message, code);
  case STATUSES.FORBIDDEN:
    return new Forbidden(status, message);
  case STATUSES.BAD_REQUEST:
    if (code === 'TWO_F_A_REQUIRED') {
      window.setTwoFAModalVisible(true);
      return new TwoFAError(status, message, code);
    }
    if (message && !EXCEPTIONS_FOR_ERROR.includes(message)) {
      window.addToast('danger', 'Error', message);
    }
    return new BadRequest(status, message, code);
  default:
    return new UnknownError(status, message);
  }
}

export default function (error: AxiosError) {
  if (error.response) {
    throw getError(error.response.status, error.response.data.message, error.response.data?.code);
  } else if (error.request) {
    throw new RequestProceedError();
  } else {
    console.log('Strange AXIOS error', error.message);
    console.log(error.config);
    throw new RequestProceedError();
  }
}