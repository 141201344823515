const API = {
  SERVER_URL: process.env.REACT_APP_SERVER_URL || 'dev-space.bossfighters.game',
  SERVER_PROTO: process.env.REACT_APP_SERVER_PROTO || 'https',

  SOLANA_NETWORK: process.env.SOLANA_NETWORK || 'devnet',
  SOLANA_RPC_ENDPOINT: process.env.SOLANA_RPC_ENDPOINT || 'https://api.devnet.solana.com',
  WALLET_SECRET_FILE_PATH: process.env.WALLET_SECRET_FILE_PATH || '/Users/admin/workspace/metaplex_hidden-settings/solanaTests/my-solana-devnet-keypair.json',
};

export default API;
