import React, {CSSProperties} from 'react';

import Loader from './Loader';

import {Wrapper} from './styles';

interface IContainerLoader {
    size?: number;
    borderWidth?: number;
    containerStyle?: CSSProperties;
    style?: CSSProperties;
}

const ContainerLoader = ({size, borderWidth, containerStyle, style}: IContainerLoader) => {
  return (
    <Wrapper style={containerStyle}>
      <Loader size={size} borderWidth={borderWidth} style={style}/>
    </Wrapper>
  );
};

export default ContainerLoader;