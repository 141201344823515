import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {CCol} from '@coreui/react';

import {SCREEN_TYPES} from '../../../hooks/useScreenSizeHook/WindowScreenType/WindowScreenType';

import {ReactComponent as DashboardSVG} from '../../../assets/images/common/header/DashboardSVG.svg';
import {ReactComponent as ProfileSettingsSVG} from '../../../assets/images/common/header/ProfileSettingsSVG.svg';
import {ReactComponent as DigitalAssetsSVG} from '../../../assets/images/icons/currentIcon/DigitalAssets.svg';
import {ReactComponent as CheckSVG} from '../../../assets/images/icons/checkIcon.svg';
import {ReactComponent as ReferralSystemSVG} from '../../../assets/images/common/header/ReferralSystem.svg';

const commonButtonStyles = css`
  height: 51px;
  width: 217px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
`;

export const LogoLink = styled(Link)<{ url: string }>`
  width: 148px;
  height: 56px;

  background: url(${({url}) => url}) no-repeat center;
  background-size: 100%;

  @media screen and (max-width: 768px) {
    width: 105px;
    height: 40px;
  }
`;

export const WalletDropdownContainer = styled.div`
  @media screen and (min-width: 769px) {
    margin-left: 43px;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const WalletDropdownButton = styled.button`
  ${commonButtonStyles};
  cursor: pointer;
  backdrop-filter: blur(20px);

  @media screen and (max-width: 768px) {
    width: auto;
  }
  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

export const WalletSymbol = styled.img`
  margin-right: 10px;
  width: 14px;
  height: 14px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const WalletAddress = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const DropdownContent = styled.div`
  width: 217px;
  display: flex;
  position: absolute;
  right: 0;
  top: 66px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;
  z-index: 10;
  filter: drop-shadow(3px 9px 18px rgba(0, 0, 0, 0.15));
`;

export const DropdownContentItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #1f2531;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;

  &:hover {
    background-color: #29303d;
  }
`;

export const DropdownItemIcon = styled.img`
  margin-right: 16px;
  width: 12px;
  height: 12px;
`;

export const StakingStatusContainer = styled.div`
  padding-right: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    height: 18px;
    width: 1px;
    background-color: #353b46;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) {
    padding-right: 15px;
    margin-right: 0;
  }

  @media (max-width: 480px) {
    padding-right: 10px;
  }
`;

export const WalletContainer = styled(CCol)`
  display: flex;
  justify-content: end;
`;

export const StakingStatus = styled.span`
  ${({actionsDisabled}: any) => css`
    color: ${actionsDisabled ? '#D7C847' : '#2EB153'};
    font-size: 12px;
    line-height: 15px;
    margin-right: 5px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  `}
`;

export const StakingStatusIcon = styled.img``;

export const HeaderContainer = styled.header`

`;

export const Container = styled.div<{ screenType?: SCREEN_TYPES }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: ${(props) =>
    props.screenType === SCREEN_TYPES.DESKTOP ? '0 15%' : '0'};

  @media (max-height: 600px) {
    @media (max-width: 950px) {
      padding: 0;
    }
  }
`;

export const AppHeaderDropdownWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;

  @media (max-width: 520px) {
    position: static;
  }
`;

export const AppHeaderDropdownToggle = styled.div`
  position: relative;
  top: 0;
  left: 0;

  width: 59px;
  height: 59px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  vertical-align: middle;
  border-radius: 50%;

  cursor: pointer;
`;

export const AppHeaderDropdownMenu = styled.ul<{ visible: boolean }>`
  position: absolute;
  top: 59px;
  right: 0;
  z-index: 2000;

  visibility: ${props => props.visible ? 'revert' : 'hidden'};
  opacity: ${props => props.visible ? 1 : 0};

  transition: all 200ms ease-in-out;

  list-style: none;

  background: #353B46;
  box-shadow: 0 4px 35px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;

  padding: 20px 0 12px 0;
  margin: 0;

  @media (max-width: 520px) {
    top: 69px;
  }
`;

export const AppHeaderDropdownMenuItem = styled.li<{ margin: string }>`
  list-style: none;

  margin: ${props => props.margin};
`;

export const AppHeaderDropdownMenuItemLink = styled(Link)`
  display: flex;
  align-items: center;

  width: 100%;
  text-align: start;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.5) !important;

  margin: 0;
  padding: 0 18px;

  transition: all 200ms ease-in-out;

  :hover {
    color: #FFA500 !important;

    transition: all 200ms ease-in-out;
  }

  :active {
    color: rgb(255, 255, 255) !important;

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuItemButton = styled.button`
  display: block;
  width: 100%;
  text-align: start;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.5) !important;

  margin: 0;
  padding: 0 18px;

  transition: all 200ms ease-in-out;

  :hover {
    color: #FFA500 !important;

    transition: all 200ms ease-in-out;
  }

  :active {
    color: rgb(255, 255, 255) !important;

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuItemLogout = styled.button`
  text-transform: uppercase;

  background-color: transparent;
  color: rgba(255, 255, 255, 0.5) !important;

  width: 100%;
  margin: 0 auto;
  padding: 14px 18px 0 18px;
  outline: 0;
  border: 0;

  border-top: 1px solid rgba(255, 255, 255, 0.1);

  transition: all 200ms ease-in-out;

  :hover {
    color: #FFA500 !important;

    transition: all 200ms ease-in-out;
  }

  :active {
    color: rgb(255, 255, 255) !important;

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuItemTitle = styled.h3<{ padding: string }>`
  display: block;
  width: 100%;
  text-align: start;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  text-transform: uppercase;

  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  margin: 0;
  padding: ${props => props.padding};
`;

export const AppHeaderDropdownMenuDashboard = styled(DashboardSVG)`
  width: 14px;
  height: 14px;

  padding: 0;
  margin: 0 10px 0 0;

  fill: rgba(255, 255, 255, 0.5);

  transition: all 200ms ease-in-out;

  ${AppHeaderDropdownMenuItemLink}:hover & {
    fill: #FFA500;

    transition: all 200ms ease-in-out;
  }

  ${AppHeaderDropdownMenuItemLink}:active & {
    fill: rgb(255, 255, 255);

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuProfile = styled(ProfileSettingsSVG)`
  width: 14px;
  height: 14px;

  padding: 0;
  margin: 0 10px 0 0;

  fill: rgba(255, 255, 255, 0.5);

  transition: all 200ms ease-in-out;

  ${AppHeaderDropdownMenuItemLink}:hover & {
    fill: #FFA500;

    transition: all 200ms ease-in-out;
  }

  ${AppHeaderDropdownMenuItemLink}:active & {
    fill: rgb(255, 255, 255);

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuReferralSystem = styled(ReferralSystemSVG)`
  width: 14px;
  height: 14px;

  padding: 0;
  margin: 0 10px 0 0;

  fill: rgba(255, 255, 255, 0.5);

  transition: all 200ms ease-in-out;

  ${AppHeaderDropdownMenuItemLink}:hover & {
    fill: #FFA500;

    transition: all 200ms ease-in-out;
  }

  ${AppHeaderDropdownMenuItemLink}:active & {
    fill: rgb(255, 255, 255);

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuDigitalAssets = styled(DigitalAssetsSVG)`
  width: 14px;
  height: 14px;

  padding: 0;
  margin: 0 10px 0 0;

  fill: rgba(255, 255, 255, 0.5);

  transition: all 200ms ease-in-out;

  ${AppHeaderDropdownMenuItemLink}:hover & {
    fill: #FFA500;

    transition: all 200ms ease-in-out;
  }

  ${AppHeaderDropdownMenuItemLink}:active & {
    fill: rgb(255, 255, 255);

    transition: all 200ms ease-in-out;
  }
`;

export const AppHeaderDropdownMenuCheck = styled(CheckSVG)`
  width: 14px;
  height: 14px;

  padding: 0;

  fill: rgba(255, 255, 255, 0.5);

  transition: all 200ms ease-in-out;

  ${AppHeaderDropdownMenuItemButton}:hover & {
    fill: #FFA500;

    transition: all 200ms ease-in-out;
  }

  ${AppHeaderDropdownMenuItemButton}:active & {
    fill: rgb(255, 255, 255);

    transition: all 200ms ease-in-out;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CircularWrapper = styled.button`
  position: relative;
  top: 0;
  left: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);

  margin: 0 20px 0 0;
  padding: 0;

  outline: none;
  border: none;

  @media (max-width: 520px) {
    margin: 0 10px 0 0;
  }
`;

export const CircularWrapperImg = styled.img`
  width: 23px;
  height: 23px;
`;

export const PlayDemoWrapper = styled.div`
  margin: 0 20px 0 0;

  @media (max-width: 520px) {
    margin: 0 10px 0 0;
  }
`