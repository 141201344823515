import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import config from '../../config';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import {IOpenLootItem, IOpenlootStatus, IOpenlootTransfer} from '../../../types/api/OpenlootTransfer';

export async function executeStake(
  walletId: string,
  transaction: string,
  collectionId: string,
  capsuleId?: number,
) {
  return makeRequest(
    'POST',
    `/api/wallets/${walletId}/assets/stake`,
    {transaction, collectionId, capsuleId},
    undefined,
    true,
  );
}

export async function executeUnstake(
  walletId: string,
  transaction: string,
  collectionId: string,
  capsuleId?: string,
) {
  return makeRequest(
    'POST',
    `/api/wallets/${walletId}/assets/unstake`,
    {transaction, collectionId, capsuleId},
    undefined,
    true,
  );
}

export async function list(
  walletId: string,
  collectionId?: string,
  staked?: boolean,
  withCapsule?: boolean,
  page: number = 1,
  perPage: number = 20,
  withInventory?: boolean,
) {
  let withInventoryQuery = '';
  if (withInventory === true || withInventory === false) {
    withInventoryQuery = `&withInventory=${withInventory}`;
  }
  let stakedQuery = '';
  if (staked === true || staked === false) {
    stakedQuery = `&staked=${staked}`;
  }
  let withCapsuleQuery = '';
  if (withCapsule === true || withCapsule === false) {
    withCapsuleQuery = `&withCapsule=${withCapsule}`;
  }
  const collectionIdQuery = collectionId ? `&collectionId=${collectionId}` : '';
  return makeRequest(
    'GET',
    `/api/wallets/${walletId}/assets?page=${page}&perPage=${perPage}${stakedQuery}${withCapsuleQuery}${collectionIdQuery}${withInventoryQuery}`,
  );
}

export async function count(walletId: string, staked?: boolean) {
  const stakedQuery =
        staked === false || staked === true ? `?staked=${staked}` : '';
  return makeRequest(
    'GET',
    `/api/wallets/${walletId}/assets/count${stakedQuery}`,
  );
}

export async function executeClaim(walletId: string, transaction: string) {
  return makeRequest(
    'POST',
    `/api/wallets/${walletId}/claim`,
    {transaction},
    undefined,
    true,
  );
}

export function getDownloadOriginalUrl(assetId: string) {
  return `${config.SERVER_PROTO}://${config.SERVER_URL}/api/assets/${assetId}/original`;
}

export async function deleteWallet(walletId: string) {
  return makeRequest(
    'DELETE',
    `/api/wallets/${walletId}`,
  );
}

export async function prepareBurnTransaction(walletId: string, assetIds: string[]): Promise<AxiosResponse<{
    transaction: string
}>> {
  return makeRequest(
    'POST',
    `/api/wallets/${walletId}/assets/prepare-burn`,
    {assetIds},
  );
}

export async function executeBurnTransaction(walletId: string, assetIds: string[], transaction: string): Promise<AxiosResponse<{
    transaction: string
}>> {
  return makeRequest(
    'POST',
    `/api/wallets/${walletId}/assets/burn`,
    {assetIds, transaction},
  );
}

export async function openLootTransfer(): Promise<AxiosResponse<IOpenlootTransfer[]>> {
  return makeRequest('GET', '/api/wallets/openloot-transfer');
}

export async function openLootItems(page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<IOpenLootItem>>> {
  return makeRequest('GET', `/api/openloot/items?page=${page}&perPage=${perPage}`);
}

export async function openLootAvatarItems(page: number = 1, perPage: number = 20) {
  return makeRequest('GET', `/api/openloot/items/for-avatar?page=${page}&perPage=${perPage}`);
}

export async function openLootStatus(): Promise<AxiosResponse<IOpenlootStatus>> {
  return makeRequest('GET', '/api/openloot/status');
}

export async function getOpenlootAssetsAmount(): Promise<AxiosResponse<{ amount: number }>> {
  return makeRequest('GET', '/api/assets/amount');
}

export async function getOpenlootEnabled(): Promise<AxiosResponse<{ enabled: boolean }>> {
  return makeRequest('GET', '/api/openloot/is-enabled');
}

export async function getOpenlootRowdies(): Promise<AxiosResponse<{ amount: number }>> {
  return makeRequest('GET', '/api/openloot/rowdies/check');
}

export async function upgradeRowdiesRarities() {
  return makeRequest('POST', '/api/assets/rowdies/split');
}