import styled from 'styled-components';

export const Wrapper = styled.div<{ marginBottom?: number, zIndex?: number }>`
    position: relative;
    top: 0;
    left: 0;
    z-index: ${({zIndex = 1}) => zIndex};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: ${({marginBottom = 0}) => marginBottom}px;
`;