export const enum QUESTIONS_FILTERS {
    CONTENT_CREATOR = 'CONTENT_CREATOR',
    DEVICE = 'DEVICE',
    AGE = 'AGE',
    GAMES = 'GAMES',
    EMAIL = 'EMAIL',
    LINK = 'LINK',
}

export const QUESTIONS_FILTERS_NAMES = {
  [QUESTIONS_FILTERS.CONTENT_CREATOR]: 'contentCreator',
  [QUESTIONS_FILTERS.DEVICE]: 'device',
  [QUESTIONS_FILTERS.AGE]: 'age',
  [QUESTIONS_FILTERS.GAMES]: 'games',
  [QUESTIONS_FILTERS.EMAIL]: 'email',
  [QUESTIONS_FILTERS.LINK]: 'link',
};

export const enum USER_FILTERS {
    EMAIL = 'EMAIL',
    STATUS = 'STATUS',
    ROLE = 'ROLE'
}

export const USER_FILTERS_NAMES = {
  [USER_FILTERS.EMAIL]: 'email',
  [USER_FILTERS.STATUS]: 'status',
  [USER_FILTERS.ROLE]: 'role',
};
