import React, {useRef, useEffect, FocusEventHandler, CSSProperties} from 'react';
import AsyncSelect from 'react-select/async';
import {SingleValue} from 'react-select';

import {ModalRow} from '../styles';
import '../../../../pages/Admin/index.css';

interface ICustomAsyncSelect {
    loadOptions: (inputValue: string, callback: (values: { value: string, label: string }[]) => void) => void;
    value: { value: string, label: string | number } | SingleValue<{ value: string, label: string }> | undefined;
    onChange: (value: SingleValue<{ value: string, label: string }> | any, action?: any) => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    isMulti?: boolean;
    activeFocus?: boolean;
    defaultOptions?: { value: string, label: string }[];
    placeholder?: string;
    marginBottom?: number;
    isClearable?: boolean;
}

const CustomAsyncSelect = ({
  loadOptions,
  value,
  onChange,
  onFocus,
  isMulti = false,
  activeFocus = false,
  defaultOptions,
  placeholder,
  marginBottom = 16,
  isClearable,
}: ICustomAsyncSelect) => {
  const selectRef = useRef<HTMLSelectElement | any>(null);

  useEffect(() => {
    if (selectRef.current && activeFocus) {
      selectRef.current?.focus();
    }
  }, [activeFocus]);

  return (
    <ModalRow marginBottom={marginBottom}>
      <AsyncSelect
        loadOptions={loadOptions}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        isMulti={isMulti}
        ref={selectRef}
        defaultOptions={defaultOptions}
        placeholder={placeholder}
        isClearable={isClearable}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 5,
          }),
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
        }}
      />
    </ModalRow>
  );
};

export default CustomAsyncSelect;