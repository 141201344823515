import React, {createContext, ReactNode, useState} from 'react';

import API from '../api';
import * as localStorage from '../localStorage';
import {LOCAL_STORAGE_NAMES} from '../localStorage/types';

interface TwoFAContextType {
  check2FASession: (token: string) => Promise<string>;
}

export const TwoFAContext = createContext<TwoFAContextType>({
  check2FASession: async () => '',
});

export const TwoFAProvider: React.FC<{ children: ReactNode }> = ({children}) => {
  const [twoFAToken, setTwoFAToken] = useState<string>(localStorage.getItem(LOCAL_STORAGE_NAMES.TWO_F_A_TOKEN) as string || '');
  const [twoFAExpiresAt, setTwoFAExpiresAt] = useState<number>(0);

  const check2FASession = async (token: string): Promise<string> => {
    if (twoFAExpiresAt <= Date.now() || !twoFAToken) {
      let response = await API.admin.users.create2FASession(token);
      if (response) {
        setTwoFAToken(response.headers['x-admin-session']);
        setTwoFAExpiresAt(response.data.expiresAt);
        localStorage.setItem(LOCAL_STORAGE_NAMES.TWO_F_A_TOKEN, response.headers['x-admin-session']);
        return response.headers['x-admin-session'];
      }
      return '';
    } else {
      return twoFAToken;
    }
  }

  return (
    <TwoFAContext.Provider value={{check2FASession}}>
      {children}
    </TwoFAContext.Provider>
  );
};
