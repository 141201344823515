import React, {useState, useEffect} from 'react';
import CIcon from '@coreui/icons-react';
import {cilArrowTop} from '@coreui/icons';

import {ButtonWrapper} from './styles';

interface IFloatingButton {
    activeAnimation: boolean;
}

const FloatingButton = ({activeAnimation}: IFloatingButton) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000)
  }, [])

  return (
    <ButtonWrapper
      onClick={() => window.scrollTo({top: 0})}
      activeAnimation={activeAnimation}
      style={{visibility: loaded ? 'visible' : 'hidden'}}
    >
      <CIcon icon={cilArrowTop} style={{height: '20px', width: 'auto'}}/>
    </ButtonWrapper>
  );
};

export default FloatingButton;