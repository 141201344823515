import {Dispatch, SetStateAction} from 'react';

import API from '../../../api/index';

import * as localStorage from '../../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../../localStorage/types';

export enum OpenLootConnectionPage {
    'digital-assets?link=open-loot',
    'profile-settings?link=open-loot',
    'dashboard?link=open-loot',
}

export const connectOpenLoot = async (userId: string, connectionPage: number) => {
  try {
    const url = await API.users.getLinkOpenLoot(userId, OpenLootConnectionPage[connectionPage]);
    if (!url) {
      return;
    }
    localStorage.setItem(LOCAL_STORAGE_NAMES.OPEN_LOOT_CONNECTION_PAGE, `/${OpenLootConnectionPage[connectionPage]}`);
    window.location.replace(url);
  } catch (e) {
    console.log(e);
  }
  localStorage.removeItem(LOCAL_STORAGE_NAMES.CONNECT_OPENLOOT);
};

export const disconnectOpenLoot = async (setOpenLootConnected: Dispatch<SetStateAction<boolean>>, setOpenLootDisabled: Dispatch<SetStateAction<boolean>>, getData?: (page?: number) => Promise<void>) => {
  setOpenLootDisabled(true)
  try {
    let openlootUserId = String(localStorage.getItem(LOCAL_STORAGE_NAMES.OPEN_LOOT_USER_ID));
    if (!openlootUserId) {
      return;
    }
    await API.users.deleteUnlinkOpenLoot(openlootUserId);
    if (getData) {
      await getData().catch(console.error);
    }
    setOpenLootConnected(false);
    localStorage.removeItem(LOCAL_STORAGE_NAMES.OPEN_LOOT_USER_ID);
  } catch (err: any) {
    console.log(err);
  } finally {
    setOpenLootDisabled(false)
  }
};

export const getUserOpenLootStatus = async (setOpenLootConnected: Dispatch<SetStateAction<boolean>>) => {
  const userInfo = await API.users.getUserOpenLootStatus();
  if (!userInfo) {
    return;
  }

  setOpenLootConnected(userInfo.data.status);
  localStorage.setItem(LOCAL_STORAGE_NAMES.OPEN_LOOT_USER_ID, userInfo.data.openlootUserId);
};

export const getOpenlootEnabled = async (setState: Dispatch<SetStateAction<boolean>>) => {
  const response = await API.assets.getOpenlootEnabled().catch(console.error);
  if (!response) {
    return;
  }

  setState(response.data.enabled);
};