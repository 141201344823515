import React from 'react';

import {hasLoader, iconSrc, subtitleText, titleText} from './toastConfig';

import {ToastType} from './toastTypes';

import {
  LoaderContainer,
  LoaderSlider,
  LoaderSliderContainer,
  Subtitle,
  Title,
  ToastContainer,
  ToastIcon,
  ToastIconContainer,
  ToastInfoContainer,
} from './styles';

const Toast = ({customTitle, toastType}: { toastType: ToastType, customTitle?: string }) => {
  const icon = iconSrc[toastType];
  const title = customTitle || titleText[toastType];
  const subtitle = subtitleText[toastType];
  const isLoader = hasLoader(toastType);

  return (
    <ToastContainer {...{toastType}}>
      {icon && (
        <ToastIconContainer>
          <ToastIcon src={icon}/>
        </ToastIconContainer>
      )}
      <ToastInfoContainer>
        {title && <Title {...{toastType}}>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {isLoader && (
          <LoaderContainer>
            <LoaderSliderContainer>
              <LoaderSlider/>
            </LoaderSliderContainer>
          </LoaderContainer>
        )}
      </ToastInfoContainer>
    </ToastContainer>
  );
};

export default Toast;
